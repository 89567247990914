import { downloadData } from 'aws-amplify/storage'

import { API } from 'api/api'
import { LoggerService } from 'services/LogService/LogService'
import { openNotificationPopup } from 'services/Utils/openNotificationPopup'
import isEqual from 'utils/isEqual'

import type { Elements, FlowElement, Node } from 'react-flow-renderer'

import type {
  CurrentUsedAggregation,
  SegmentBuilderData,
  PayloadData,
  Item,
  CreateActionFields,
} from 'models/motion/motionBuilder.model'
import { ActionTypeEnum, ActionEnum, RelativeDateInterval } from 'models/motion/motionBuilder.model'
import type { NodePayload, SendEmailPayload, SendTestMessagePayload } from 'models/motion.model'
import { NodeTypeEnum } from 'models/motion.model'

export const defaultPanelSizes = { small: '432px', medium: '736px', large: '1080px' }

export const getDrawerSize = (data: SegmentBuilderData) => {
  if (data.action === ActionEnum.Create) {
    return true
  } else {
    // Elements with small drawer
    if (
      [NodeTypeEnum.TimeDelay, ActionTypeEnum.UpdateAccount, NodeTypeEnum.Merge].includes(data.actionType || data.type)
    ) {
      return true
    } else {
      return false
    }
  }
}

export const handleSpecialCase = (panelType: ActionTypeEnum, type: ActionEnum | undefined) => {
  if (panelType === ActionTypeEnum.UpdateAccount && type === ActionEnum.Update) {
    return ActionTypeEnum.Create
  }

  if (panelType === ActionTypeEnum.TriggerEvent) {
    return ActionTypeEnum.TriggerEvent
  }
}

const isValidValue = (value: any) => {
  return value !== undefined && value !== null && value !== ''
}

export const doesCriteriaContainEmptyInputs = (payload: PayloadData): boolean => {
  if (!payload?.groups) {
    return false
  }

  return payload.groups.some((criteria) => {
    return criteria.groups.some((group) => {
      if (typeof group.value === 'boolean') {
        return false
      }

      const isWhitespace = typeof group.value === 'string' ? group.value.trim().length < 1 : false
      const isDynamicInputWhitespace =
        typeof group.value === 'object' && group.value !== null && 'value' in group.value && group.value?.value
          ? (group.value?.value as string).trim().length < 1
          : false

      return (
        (group.value as string)?.length < 1 || isWhitespace || isDynamicInputWhitespace || !isValidValue(group.value)
      )
    })
  })
}

export const getListOfAggregationsUsedAcrossMotion = ({
  aggregationUuid,
  elements,
  elementPayload,
  payload,
}: {
  aggregationUuid: string
  elements: Elements
  elementPayload: PayloadData
  payload: PayloadData
}) => {
  const usedAggregationsList: CurrentUsedAggregation[] = []
  const elementsWithValidPayloads = elements.filter(
    (element: FlowElement<{ payload: NodePayload; type?: NodeTypeEnum }>) => {
      const payload = element.data?.payload?.groups
      const exitConditionPayload = element.data?.payload?.exitCondition?.groups
      return (
        (payload?.length ?? exitConditionPayload?.length ?? 0) > 0 &&
        new Set([NodeTypeEnum.Segment, NodeTypeEnum.Branch, NodeTypeEnum.Loop, NodeTypeEnum.WaitForTrigger]).has(
          element.data?.type as NodeTypeEnum,
        )
      )
    },
  )

  elementsWithValidPayloads.forEach(
    (element: FlowElement<{ payload: NodePayload; nodeId?: string; name?: string; description?: string }>) => {
      const currentElement = element.data?.payload?.exitCondition || element.data?.payload
      currentElement?.groups?.forEach((group, groupIndex) => {
        group.groups.forEach((item, itemIndex) => {
          if ((item as Item).aggregationId === aggregationUuid && (item as Item).isAggregationCriteria) {
            usedAggregationsList.push({
              nodeId: element.data?.nodeId,
              nodeName: element.data?.name,
              nodeDescription: element.data?.description,
              groupIndex,
              itemIndex,
            })
          }
        })
      })
    },
  )

  payload?.groups?.forEach((group, groupIndex) => {
    group.groups.forEach((item, itemIndex) => {
      if (item.aggregationId === aggregationUuid && item.isAggregationCriteria) {
        usedAggregationsList.push({
          nodeName: 'Current node',
          hasSamePayload: isEqual(payload, elementPayload),
          nodeDescription: 'Unsaved payload',
          groupIndex,
          itemIndex,
        })
      }
    })
  })

  return usedAggregationsList
}

export const checkRequiredFields = (updatedNode: Node<SegmentBuilderData>): string[] => {
  const errors: string[] = []
  for (const field of updatedNode.data?.payload?.fields || []) {
    if (field.required && (field.value === undefined || field.value === '')) {
      field.error = true
      errors.push(field.name || '')
    } else {
      field.error = false
    }
  }
  return errors
}

export const showRequiredFieldsError = (requiredFieldsErrors: string[]) => {
  const plural = requiredFieldsErrors.length > 1 ? 'These fields are required:' : 'This field is required:'
  openNotificationPopup(
    `Missing Required Field${requiredFieldsErrors.length > 1 ? 's' : ''}`,
    `${plural}\n ${requiredFieldsErrors.join(', ')}`,
    'error',
  )
}

export const showRelativeDateError = (relativeDateErrors: string[]) => {
  openNotificationPopup(
    `Relative Date Error${relativeDateErrors.length > 1 ? 's' : ''}`,
    `${relativeDateErrors.join('\n')}`,
    'error',
  )
}

/** The maximum number of years allowed for a relative date calculation. */
export const MAX_RELATIVE_DATE_YEARS = 20
/** The maximum number of weeks allowed for a relative date calculation. */
export const MAX_RELATIVE_DATE_WEEKS = MAX_RELATIVE_DATE_YEARS * 52
/** The maximum number of days allowed for a relative date calculation. */
export const MAX_RELATIVE_DATE_DAYS = MAX_RELATIVE_DATE_YEARS * 365
/**
 * Validate the relative date calculation is within the allowed range.
 * @param {CreateActionFields[]} fields The fields to validate
 * @returns {string[]} The errors or an empty array if no errors
 */
export const validateRelativeDateCalculation = (fields: CreateActionFields[]) => {
  const errors: string[] = []
  fields.forEach((field) => {
    if (!field.calculation) {
      return errors
    }
    if (field.calculation?.amount <= 0) {
      errors.push('The relative date calculation amount must be a positive integer')
    }
    switch (field.calculation.interval) {
      case RelativeDateInterval.YEAR:
        if (field.calculation.amount >= MAX_RELATIVE_DATE_YEARS) {
          errors.push(
            `The relative date final value must be within ${MAX_RELATIVE_DATE_YEARS} years of the specified date`,
          )
        }
        break
      case RelativeDateInterval.WEEK:
        if (field.calculation.amount >= MAX_RELATIVE_DATE_WEEKS) {
          errors.push(
            `The relative date final value must be within ${MAX_RELATIVE_DATE_YEARS} years (${MAX_RELATIVE_DATE_WEEKS} weeks) of the specified date`,
          )
        }
        break
      case RelativeDateInterval.DAY:
        if (field.calculation.amount >= MAX_RELATIVE_DATE_DAYS) {
          errors.push(
            `The relative date final value must be within ${MAX_RELATIVE_DATE_YEARS} years (${MAX_RELATIVE_DATE_DAYS} days) of the specified date`,
          )
        }
        break
      default:
        errors.push('Unknown relative date interval')
    }
  })
  return errors
}

/**
 * Check if the email fields are valid
 * @param {Node<SegmentBuilderData>} updatedNode The updated node
 * @param {string[]} emailFieldKeys The keys of the email fields to check
 * @returns {string[]} The errors or an empty array if no errors
 */
export const checkEmailFields = (
  updatedNode: Node<SegmentBuilderData>,
  emailFieldKeys: string[] = ['senderEmail', 'recipientEmail', 'replyToEmail'],
): string[] => {
  const errors: string[] = []
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  for (const field of updatedNode.data?.payload?.fields || []) {
    // Skip validation if field is using dynamic input
    if (field.isDynamicInput) {
      continue
    }
    // Check for email fields
    if (emailFieldKeys.includes(field.key) && field.value) {
      if (!emailRegex.test(field.value as string)) {
        field.error = true
        errors.push(field.name || field.key)
      } else {
        field.error = false
      }
    }
  }
  return errors
}

export const showEmailFieldsError = (emailFieldsErrors: string[]) => {
  const plural =
    emailFieldsErrors.length > 1
      ? 'These fields need valid email addresses:'
      : 'This field needs a valid email address:'
  openNotificationPopup(
    `Invalid Email Address${emailFieldsErrors.length > 1 ? 'es' : ''}`,
    `${plural}\n ${emailFieldsErrors.join(', ')}`,
    'error',
  )
}

export const replaceDynamicInputText = (inputValue: string | { value: string; field: string }) => {
  if (typeof inputValue === 'string') return inputValue

  const regex = /\${dynamicInput}/g
  return inputValue.value.replace(regex, `{${inputValue.field.toUpperCase().replace(/\s+/g, '_')}}`)
}

export const TEST_EMAIL_FROM_ADDRESS = 'noreply@noreply.magnify.io'

export const triggerSendTestEmail = async (to: string, subject: string, htmlContent: string) => {
  if (!htmlContent) {
    openNotificationPopup('Failed to send test email', 'Template must have content to send test email.', 'error')
    return
  }
  try {
    const emailPayload: SendEmailPayload = {
      from: TEST_EMAIL_FROM_ADDRESS,
      to: replaceDynamicInputText(to),
      subject: replaceDynamicInputText(subject),
      text: '',
      html: htmlContent,
    }

    const response = await API.motions.sendTestEmail(emailPayload)
    if (response && response.status !== 200) {
      throw new Error(response.message)
    }

    openNotificationPopup(
      'Test email sent',
      `You have sent a test email from ${TEST_EMAIL_FROM_ADDRESS} to ${to}`,
      'success',
    )
  } catch (error: unknown) {
    if (error instanceof Error && error.message) {
      openNotificationPopup('Failed to send test email', error.message, 'error')
    } else {
      openNotificationPopup('Failed to send test email', 'Something went wrong sending the test email', 'error')
    }
  }
}

/**
 * Trigger the send test message API call.
 * @param {string | {value: string; field: string; }} to The "to" ID
 * @param {string} from The "from" ID
 * @param {string | {value: string; field: string; }} message The message to send
 * @param {boolean} unfurlLinks Whether to unfurl links
 * @param {string} platformConnectionId The platform connection ID
 */
export const triggerSendTestMessage = async (
  to:
    | string
    | {
        value: string
        field: string
      },
  from: string,
  message:
    | string
    | {
        value: string
        field: string
      },
  unfurlLinks: boolean,
  platformConnectionId: string,
) => {
  if (!platformConnectionId) {
    openNotificationPopup(
      'Failed to send test message',
      'No active Slack connection found. Please connect to Slack to send a test message.',
      'error',
    )
    return
  }

  // Format the message for Slack like actions-service/functions/actions/slack/sendMessage.ts
  const parsedMessage = replaceDynamicInputText(message ?? '')
    .replaceAll(/<p>&nbsp;<\/p>/g, '<br />')
    .replaceAll('•', '-')
  if (!to || !from || !parsedMessage) {
    openNotificationPopup('Failed to send test message', 'Message must have a "to", "from", and "message"', 'error')
    return
  }

  try {
    const messagePayload: SendTestMessagePayload = {
      from,
      to: replaceDynamicInputText(to),
      message: parsedMessage,
      unfurlLinks,
      platformConnectionId,
    }

    const response = await API.motions.sendTestMessage(messagePayload)
    if (response && response.status !== 200) {
      throw new Error(response.message)
    }

    openNotificationPopup('Test message sent', 'Your test message has been sent successfully', 'success')
  } catch (error: unknown) {
    if (error instanceof Error && error.message) {
      openNotificationPopup('Failed to send test message', error.message, 'error')
    } else {
      openNotificationPopup('Failed to send test message', 'Something went wrong sending the test message', 'error')
    }
  }
}

export const parseFilename = (filename: string) => {
  const filenameArray = filename.split('/')
  let userFilename: string

  if (filenameArray.includes('executing')) {
    userFilename = 'Executing Motion Latest Execution Segment'
  } else if (filenameArray.includes('draft')) {
    userFilename = 'Saved Motion Generated Segment'
  } else {
    userFilename = 'Unsaved Motion Generated Segment'
  }

  const dateWithExtension = filenameArray[filenameArray.length - 1]
  const date = dateWithExtension.split('.csv')[0]

  return `${userFilename} - ${date}`
}

/**
 * Generate a download anchor that self clicks from a AWS Amplify Storage blob.
 * Taken verbatim from the example docs.
 * @param {Blob} blob The AWS Amplify Storage.get response that will be downloaded.
 * @param {string} [filename='download'] The filename that be used for the downloaded file.
 * @returns {HTMLAnchorElement} The download link as an anchor element.
 * @see {@link https://docs.amplify.aws/lib/storage/download/q/platform/js/#file-download-option}
 */
export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
  return a
}

export const downloadSegment = async (filename: string) => {
  try {
    const parsedFilename = parseFilename(filename)
    const storageResult = await downloadData({
      key: filename,
      options: {
        accessLevel: 'guest',
      },
    }).result
    // Convert the response to a blob.
    const blob = await storageResult.body.blob()
    downloadBlob(blob, `${parsedFilename}.csv`)
  } catch (error: unknown) {
    if (error instanceof Error) {
      openNotificationPopup('Something went wrong trying to download the segment CSV', error.message, 'error')
    }
    LoggerService.error({
      message: 'Something went wrong trying to download the segment CSV. Please try again',
      error,
    })
  }
}
