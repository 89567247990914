import { Select } from 'antd'
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Tooltip, CategoryScale } from 'chart.js'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'

import { IconChartIcon } from 'components/common/Icons/Icons'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import {
  getEvenlySpacedItems,
  convertRangeToDates,
} from 'components/MotionDetails/PerformanceMetrics/PerformanceMetricChart/utils'
import { LogoMark } from 'components/Navigation/NavigationIcons'
import useStore from 'store/useStore'

import type { TooltipItem } from 'chart.js'

import { DataFormatEnum } from 'models/insights'
import type { MotionMetricsPerformance } from 'models/motionMetrics'

ChartJS.register(LineElement, PointElement, LinearScale, Tooltip, CategoryScale)

interface PerformanceMetricChartProps {
  motionId: string
  metric: MotionMetricsPerformance
}

const PerformanceMetricChart = ({ motionId, metric }: PerformanceMetricChartProps) => {
  const [selectedMonthRange, setselectedMonthRange] = useState<number | string>(1)
  const [chartDataLabels, setChartDataLabels] = useState<string[]>([])
  const [chartDataValues, setChartDataValues] = useState<(number | null)[]>([])

  const { reportingStore } = useStore()

  const title = metric.displayName
  const targetGoal = metric.goal
  const isMagnifyMetric = metric.platformKey === 'magnify'
  // Pull the chart data from the store to stay up to date with the latest data
  const chartData = reportingStore.data.motionMetricsPerformance[metric.id]?.data ?? []

  useEffect(() => {
    const fetchMetricData = async () => {
      const { formattedStartDate, formattedEndDate } = convertRangeToDates(selectedMonthRange)
      await reportingStore.getMotionMetricPerformance({
        motionId,
        metricId: metric.id,
        operator: metric.operator,
        interval: 'day',
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        participating: true,
      })

      // Check if all values in chartData are null
      const allValuesNull = chartData.every((item) => item.value === null)

      // If all values are null, use an empty array, otherwise use the original chartData
      const validChartData = allValuesNull ? [] : chartData
      const filteredChartData = getEvenlySpacedItems(validChartData, selectedMonthRange)

      const labels = filteredChartData.map(({ date }) => date)
      const values = filteredChartData.map(({ value }) => value)

      setChartDataLabels(labels)
      setChartDataValues(values)
    }

    void fetchMetricData()
  }, [selectedMonthRange])

  const data = {
    labels: chartDataLabels,
    datasets: [
      {
        data: chartDataValues,
        borderColor: '#312EE0',
        backgroundColor: '#312EE0',
        pointBackgroundColor: '#312EE0',
        pointBorderColor: '#312EE0',
        pointHoverBackgroundColor: '#312EE0',
        pointHoverBorderColor: '#312EE0',
        tension: 0.4,
        pointStyle: 'circle',
        pointRadius: 2,
        pointHoverRadius: 4,
      },
      {
        label: 'Target Goal',
        data: targetGoal && new Array(180).fill(targetGoal),
        borderColor: 'green',
        pointStyle: 'line',
      },
    ],
  }

  /* c8 ignore start */
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // This is used to fill in the gaps in the data
    spanGaps: true,
    plugins: {
      tooltip: {
        padding: {
          x: 8,
          y: 6,
        },
        title: {
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        displayColors: false,
        callbacks: {
          title: (tooltipItem: TooltipItem<'line'>[]) => {
            const currentIndex = tooltipItem[0].dataIndex
            const isAggregated = selectedMonthRange === 'ytd' || selectedMonthRange === 'all'

            if (!isAggregated) {
              const date = new Date(tooltipItem[0].label)
              return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
            }

            // For aggregated views, show the date range
            const startDate = new Date(chartDataLabels[currentIndex])
            const nextIndex = Math.min(currentIndex + 1, chartDataLabels.length - 1)
            const endDate = new Date(chartDataLabels[nextIndex])

            return `${startDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })} - ${endDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}`
          },
          label: (tooltipItem: TooltipItem<'line'>) => {
            const currentValue = tooltipItem.raw as number
            const currentIndex = tooltipItem.dataIndex
            const isAggregated = selectedMonthRange === 'ytd' || selectedMonthRange === 'all'

            if (!isAggregated) {
              return currentValue.toLocaleString()
            }

            // Calculate percentage difference from previous point
            const previousValue = currentIndex > 0 ? chartDataValues[currentIndex - 1] : null
            if (previousValue === null || previousValue === 0) {
              return currentValue.toLocaleString()
            }

            const percentChange = ((currentValue - previousValue) / previousValue) * 100
            const changeSymbol = percentChange >= 0 ? '+' : '-'

            return [`${currentValue.toLocaleString()} ${changeSymbol} ${Math.abs(percentChange).toFixed(1)}%`]
          },
        },
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 8,
          callback: (index: string | number) => {
            const date = chartDataLabels[Number(index)]
            const [_, month, day] = date.split('-')
            return `${month}/${day}`
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        // Adds 10% padding at the top so the values are not cut off
        suggestedMax: function (context: { chart: { data: { datasets: { data: number[] }[] } } }) {
          const max = [...context.chart.data.datasets[0].data, targetGoal].reduce(
            (a: number, b: number) => Math.max(a, b),
            0,
          )
          return max * 1.1
        },
        // Adds 10% padding at the bottom so the values are not cut off
        suggestedMin: function (context: { chart: { data: { datasets: { data: number[] }[] } } }) {
          const min = [...context.chart.data.datasets[0].data, targetGoal].reduce(
            (a: number, b: number) => Math.min(a, b),
            0,
          )
          return min || 1 * 0.9
        },
        ticks: {
          callback: function (value: string | number) {
            return `${formatNumber({ number: value, format: DataFormatEnum.Kmb, decimal: 1 })}`
          },
        },
      },
    },
  }
  /* c8 ignore end */

  const handleChange = (value: number) => {
    setselectedMonthRange(value)
  }

  const getChartDateRangeOptions = () => {
    const chartDataLength = chartData?.length

    if (chartDataLength <= 31) {
      return [
        { value: 1, label: 'Last month' },
        { value: 'ytd', label: 'Year to Date' },
        { value: 'all', label: 'All Time' },
      ]
    } else if (chartDataLength <= 60) {
      return [
        { value: 1, label: 'Last month' },
        { value: 3, label: 'Last 3 months' },
        { value: 'ytd', label: 'Year to Date' },
        { value: 'all', label: 'All Time' },
      ]
    } else if (chartDataLength <= 120) {
      return [
        { value: 1, label: 'Last month' },
        { value: 3, label: 'Last 3 months' },
        { value: 6, label: 'Last 6 months' },
        { value: 'ytd', label: 'Year to Date' },
        { value: 'all', label: 'All Time' },
      ]
    }
    return [
      { value: 1, label: 'Last month' },
      { value: 3, label: 'Last 3 months' },
      { value: 6, label: 'Last 6 months' },
      { value: 12, label: 'Last year' },
      { value: 'ytd', label: 'Year to Date' },
      { value: 'all', label: 'All Time' },
    ]
  }

  return (
    <div className='performance-metric-chart' data-testid={`performance-metric-chart-${metric?.id || ''}`}>
      <div className='performance-metric-chart__header'>
        <div
          className='performance-metric-chart__header__title-container'
          data-testid={`performance-metric-chart-title-container-${metric?.id || ''}`}>
          {isMagnifyMetric && <LogoMark />}
          <h3
            className={classNames('performance-metric-chart__header__title', {
              'performance-metric-chart__header__title--magnify': isMagnifyMetric,
            })}
            data-testid={`performance-metric-chart-title-${metric?.id || ''}`}>
            {title}
          </h3>
        </div>
        <Select defaultValue={1} onChange={handleChange} options={getChartDateRangeOptions()} />
      </div>
      <div className='performance-metric-chart__chart-container' data-testid='empty-chart'>
        {chartDataValues.length > 0 ? (
          <Line data={data} options={options} />
        ) : (
          <div className='performance-metric-chart__chart-container__empty-chart'>
            <IconChartIcon />
            <p>This chart will update once we have data to report - stay tuned!</p>
          </div>
        )}
      </div>
    </div>
  )
}
PerformanceMetricChart.displayName = 'PerformanceMetricChart'
export default PerformanceMetricChart
