import { CloseOutlined } from '@ant-design/icons'
import { Button as CloseButton, Spin } from 'antd'
import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

import { Button, IconDynamicInput, IconMailGray } from 'components/common'
import SaveAsNewTemplateModal from 'components/EmailTemplates/modals/SaveAsNewTemplateModal'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { EmailTemplate } from 'models/email-templates.model'

interface PresentationViewProps {
  isInActionFlow?: boolean
  onEdit: (template: EmailTemplate) => void
  onClose: () => void
  changeActionConfigPanelFieldValue?: (value: string | number, key: string) => void
  closeTemplateListModal?: () => void
}

const PresentationView = observer(
  ({
    isInActionFlow,
    onEdit,
    onClose,
    changeActionConfigPanelFieldValue,
    closeTemplateListModal,
  }: PresentationViewProps) => {
    const { emailTemplatesStore } = useStore()
    const {
      data: { template },
      loading: { isTemplateLoading },
      getTemplate,
      cloneTemplate,
    } = emailTemplatesStore
    const htmlRef = useRef<HTMLDivElement>(null)
    const [existingTemplate, setExistingTemplate] = useState<EmailTemplate | undefined>(undefined)
    const [isSaveAsNewTempateModalOpen, setIsSaveAsNewTempateModalOpen] = useState(false)

    useDisplayErrorNotification(emailTemplatesStore)

    useEffect(() => {
      if (!htmlRef.current) return

      const fetchTemplate = async () => {
        const result = await getTemplate({
          magnifyTemplateId: template.magnifyTemplateId,
          magnifyVersion: template.magnifyVersion,
        })

        setExistingTemplate(result)
        const sanitizedHtml = result?.htmlContent ? DOMPurify.sanitize(result.htmlContent) : ''
        htmlRef.current!.innerHTML = sanitizedHtml
      }

      if (template.magnifyTemplateId && template.magnifyVersion) {
        fetchTemplate().catch(console.error)
      }
    }, [htmlRef])

    const selectTemplate = () => {
      if (isInActionFlow && changeActionConfigPanelFieldValue && closeTemplateListModal) {
        changeActionConfigPanelFieldValue(existingTemplate!.magnifyTemplateId, 'templateId')
        changeActionConfigPanelFieldValue(existingTemplate!.magnifyVersion, 'templateVersion')
        changeActionConfigPanelFieldValue(existingTemplate!.name, 'template')

        onClose()
        closeTemplateListModal()
      }
    }

    const saveAsNewTemplate = async (templateName: string) => {
      await cloneTemplate(template.magnifyTemplateId, template.magnifyVersion, templateName || template.name)
    }

    const setIsModalOpen = (isOpen: boolean) => {
      setIsSaveAsNewTempateModalOpen(isOpen)
      onClose()
    }

    /** Used for pluralizing the modal title and checking the count. */
    const dynamicInputCount = Object.keys(existingTemplate?.dynamicInputs ?? {}).length

    return (
      <>
        <SaveAsNewTemplateModal
          isModalOpen={isSaveAsNewTempateModalOpen}
          setIsModalOpen={setIsModalOpen}
          templateName={template.name || ''}
          saveAsNewTemplate={saveAsNewTemplate}
        />
        <section
          className='email-template-editor-container modal'
          id='email-template-presentation-view'
          data-testid='email-template-presentation-view'>
          <header>
            <div className='title-editor'>
              <IconMailGray className='m-r-10 title-editor__mail-icon' />
              <span className='title-editor__name' style={{ cursor: 'default' }}>
                {template.name}
              </span>
            </div>
            <div className='buttons__wrapper'>
              {isInActionFlow && (
                <Button text='Use This Email' className='smaller-button' size='L' onClickHandler={selectTemplate} />
              )}
              <Button
                type='secondary'
                text='Edit'
                className='smaller-button'
                size='L'
                onClickHandler={() => onEdit(template)}
              />
              <Button
                type='secondary'
                text='Save as New Email'
                className='smaller-button'
                size='L'
                onClickHandler={() => setIsSaveAsNewTempateModalOpen(true)}
              />
              <CloseButton className='close-btn' onClick={onClose}>
                <CloseOutlined />
              </CloseButton>
            </div>
          </header>
          <Spin spinning={isTemplateLoading}>
            <div
              className={classNames({
                'body-editor': true,
                'body-editor--action-flow': isInActionFlow,
                'body-editor--loading': isTemplateLoading,
              })}>
              <div ref={htmlRef} data-testid='email-template-html-container'></div>
              {dynamicInputCount > 0 && (
                <div className='dynamic-input-count' data-testid='dynamic-input-count'>
                  <IconDynamicInput />
                  {dynamicInputCount} Present
                </div>
              )}
            </div>
          </Spin>
        </section>
      </>
    )
  },
)

export default PresentationView
