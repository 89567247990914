/**
 * Remove all browser stored data.
 */
export const clearStorage = (reason?: string) => {
  console.log('Clearing Storage', reason)

  // Flush localStorage to clear out any data that may be stored.
  try {
    localStorage.clear()
  } catch (error: unknown) {
    console.log('Error clearing local storage', error)
  }

  // Flush sessionStorage to clear out any data that may be stored.
  try {
    sessionStorage.clear()
  } catch (error: unknown) {
    console.log('Error clearing session storage', error)
  }

  // Clear all cookies in all paths and all variants of the domain
  try {
    const cookies = document.cookie.split('; ')
    for (const cookie of cookies) {
      const domainParts = window.location.hostname?.split('.') ?? []
      while (domainParts.length > 0) {
        const cookieBase =
          encodeURIComponent(cookie.split(';')[0].split('=')[0]) +
          '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
          domainParts.join('.') +
          ' ;path='
        const pathParts = window.location.pathname.split('/')
        document.cookie = cookieBase + '/'
        while (pathParts.length > 0) {
          document.cookie = cookieBase + pathParts.join('/')
          pathParts.pop()
        }
        domainParts.shift()
      }
    }
  } catch (error: unknown) {
    console.log('Error deleting cookies manually', error)
  }
}
