import { InputNumber, Popover } from 'antd'
import classNames from 'classnames'
import React, { useState } from 'react'

import DynamicDataField from 'components/common/DynamicDataField'
import { IconMisuse, IconCheckMarkFilled, IconWarningTriangleRed, IconTrash } from 'components/common/Icons/Icons'
import FieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'
import { getAggregationOptions, getAggregationLevels } from 'pages/Motions/ConfigureReport/index.utils'

import type { MetadataDescription } from 'models/metadata.model'
import type { Item } from 'models/motion/motionBuilder.model'
import type { MotionMetricDefinition } from 'models/motionMetrics'

export interface MetricRowProps {
  currentEditingMetric: MotionMetricDefinition
  dataField: MetadataDescription
  index: number
  isEditingMetric: (index: number) => boolean
  isEditingEnabled: boolean
  metric: MotionMetricDefinition
  motionMetrics: MotionMetricDefinition[]
  removeMetric: (index: number) => void
  resetMetric: (index: number) => void
  saveMetric: (index: number) => void
  setCurrentEditingMetric: (item: MotionMetricDefinition) => void
  setDataField: (item: MetadataDescription) => void
  isEditing: number
  setIsEditing: (index: number) => void
  setAllErrors: (errors: string[]) => void
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
}

const MetricRow: React.FC<MetricRowProps> = ({
  currentEditingMetric,
  dataField,
  index,
  isEditing,
  isEditingMetric,
  isEditingEnabled,
  metric,
  motionMetrics,
  removeMetric,
  setCurrentEditingMetric,
  setDataField,
  setIsEditing,
  saveMetric,
  resetMetric,
  setAllErrors,
  setHasUnsavedChanges,
}) => {
  /** The errors that have occurred while validating the metric. */
  const [errors, setErrors] = useState<string[]>([])
  /** The metric id used as the data-testid for the row. */
  const metricId = 'id' in metric ? metric.id : 'new-metric'
  /** If we are editing, use the currentEditingMetric, otherwise use the metric. */
  const activeMetric = isEditingMetric(index) ? currentEditingMetric : metric
  /** Keep track of fields that have had interaction from the user to show validation errors only after interacting with them. */
  const [touched, setTouched] = useState({
    goal: false,
    displayName: false,
  })
  /** Keep track of whether the data field popover is open. */
  const [dataPopoverOpen, setDataPopoverOpen] = useState(false)

  /** Helper function to validate that the current metric platform, objectName, columnName, and operator combination is unique. */
  const isMetricUnique = (metric: MotionMetricDefinition) => {
    return !motionMetrics.some((m) => {
      // If both metrics have IDs and they match, skip comparison (same metric)
      if ('id' in metric && 'id' in m && metric.id === m.id) {
        return false
      }
      return (
        m.platformKey === metric.platformKey &&
        m.objectName === metric.objectName &&
        m.columnName === metric.columnName &&
        m.operator === metric.operator
      )
    })
  }

  /** Validate the metric and set the errors. */
  const validateMetric = (metric: MotionMetricDefinition, forceTouched = false) => {
    // We should not validate the metric if we just started creating it.
    if (!metric.platformKey) {
      return []
    }
    const newErrors: string[] = []
    if (!isMetricUnique(metric)) {
      newErrors.push('This Data Source and Aggregation combo already exists.')
    }
    if (!metric.operator) {
      newErrors.push('Aggregation is required.')
    }
    if ((forceTouched || touched.goal) && metric.goal && isNaN(metric.goal)) {
      newErrors.push('Target Goal must be numeric value.')
    }
    if ((forceTouched || touched.displayName) && (!metric.displayName || metric.displayName?.length === 0)) {
      newErrors.push('Chart label is required.')
    }
    setErrors(newErrors)
    setAllErrors(newErrors)
    return newErrors
  }

  return (
    <>
      <tr
        className={classNames({
          'metric-row': true,
          'metric-row-disabled': isEditing !== -1 && isEditing !== index,
        })}
        key={`row-${metricId}`}
        data-testid={`row-${metricId}-${activeMetric.operator}`}>
        <td
          className={classNames('data-field', {
            error: errors.includes('This Data Source and Aggregation combo already exists.'),
          })}
          data-testid='metric-data-field'>
          <Popover
            trigger='click'
            placement='bottom'
            overlayClassName='data-field__popover'
            onOpenChange={(value: boolean) => {
              // If we have an ID already we cannot change the data field.
              if (metricId !== 'new-metric') {
                return
              }
              setDataPopoverOpen(value)
              if (value) {
                setIsEditing(index)
                setCurrentEditingMetric({
                  ...activeMetric,
                })
                validateMetric(activeMetric)
                setHasUnsavedChanges(true)
              }
            }}
            open={!isEditingEnabled ? false : dataPopoverOpen}
            className={classNames({ 'data-field-active': dataPopoverOpen })}
            content={
              <DynamicDataField
                allowSelection={true}
                disabledFieldTooltip='Only fields with numeric values are supported.'
                disabledObjectTooltip='No metric fields available for this item.'
                filterFields={(field) => {
                  return [
                    // 'address',
                    // 'boolean',
                    // 'collection',
                    'currency',
                    // 'date',
                    // 'datepicker',
                    // 'datetime',
                    'double',
                    // 'dropdown',
                    // 'email',
                    // 'encryptedstring',
                    // 'id',
                    'int',
                    'integer',
                    // 'location',
                    // 'multipicklist',
                    'number',
                    // 'payload',
                    'percent',
                    // 'phone',
                    // 'picklist',
                    // 'reference',
                    // 'regexp',
                    // 'select',
                    // 'string',
                    // 'text',
                    // 'textarea',
                    // 'time',
                    // 'url',
                  ].includes(field?.type ?? '')
                }}
                filterObjects={(object) => {
                  return (object?.aggregationLevels?.user || object?.aggregationLevels?.account) ?? false
                }}
                handleSelectDataField={(item) => {
                  setDataPopoverOpen(false)
                  setDataField(item)
                  setCurrentEditingMetric({
                    ...activeMetric,
                    columnName: item?.key ?? '',
                    objectName: item?.object ?? '',
                    platformKey: item?.platform ?? '',
                    platformConnectionId: item?.solutionInstanceId ?? '',
                    aggregationLevel: item?.aggregationLevels?.user ? 'user' : 'account',
                    displayName: item?.name ?? '',
                  })
                  setHasUnsavedChanges(true)
                }}
              />
            }>
            <div
              className={classNames('data-field-container', {
                disabled: !isEditingEnabled,
                placeholder: !dataField?.object || !dataField?.name,
              })}
              data-testid='metric-data-field-container'>
              {activeMetric.objectName && activeMetric.columnName ? (
                <>
                  <span className='data-field-container-platform'>
                    {!!activeMetric.platformKey &&
                      getMenuItemIcon({ entityType: 'platform', name: activeMetric.platformKey })}
                  </span>
                  <span className='data-field-container-name'>
                    {activeMetric.objectName} / {activeMetric.columnName}
                  </span>
                </>
              ) : (
                <span className='data-field-placeholder'>Select data source</span>
              )}
            </div>
          </Popover>
        </td>

        <td
          className={classNames('aggregation', {
            error:
              errors.includes('This Data Source and Aggregation combo already exists.') ||
              errors.includes('Aggregation is required.'),
          })}
          data-testid='aggregation'>
          <FieldInput
            item={{ type: 'select', value: activeMetric.operator } as Item}
            range={false}
            isAction={true}
            disabled={!isEditingEnabled}
            defaultOptions={getAggregationOptions(dataField.type || metric.type)}
            changeFieldValue={(value) => {
              setIsEditing(index)
              setCurrentEditingMetric({
                ...activeMetric,
                operator: value as string,
              })
              validateMetric({
                ...activeMetric,
                operator: value as string,
              })
              setHasUnsavedChanges(true)
            }}
          />
        </td>

        <td
          className={classNames('aggregation-over', {
            error: errors.includes('This Data Source and Aggregation combo already exists.'),
          })}
          data-testid='aggregation-over'>
          <FieldInput
            item={{ type: 'select', value: activeMetric.aggregationLevel ?? 'account' } as Item}
            range={false}
            isAction={true}
            disabled
            defaultOptions={getAggregationLevels()}
            changeFieldValue={(value) => {
              setIsEditing(index)
              setCurrentEditingMetric({
                ...activeMetric,
              })
              validateMetric(activeMetric)
              setHasUnsavedChanges(true)
            }}
          />
        </td>

        <td
          className={classNames('metric-chart-label', { error: errors.includes('Chart label is required.') })}
          data-testid='metric-chart-label'>
          <FieldInput
            item={{ type: 'text', value: activeMetric.displayName } as Item}
            range={false}
            isAction={true}
            dataTestId='metric-chart-label-input'
            disabled={!isEditingEnabled}
            changeFieldValue={(value) => {
              setIsEditing(index)
              setCurrentEditingMetric({
                ...activeMetric,
                displayName: value as string,
              })
              setTouched((prev) => ({
                ...prev,
                displayName: true,
              }))
              validateMetric({
                ...activeMetric,
                displayName: value as string,
              })
              setHasUnsavedChanges(true)
            }}
          />
        </td>

        <td
          className={classNames('target-goal', { error: errors.includes('Target Goal must be numeric value.') })}
          data-testid='target-goal'>
          <InputNumber
            key='target-goal'
            className='track-goal-input'
            controls={false}
            min={0}
            precision={0}
            value={activeMetric.goal}
            onFocus={() => {
              setIsEditing(index)
              setCurrentEditingMetric({
                ...activeMetric,
              })
              validateMetric({
                ...activeMetric,
              })
              setTouched((prev) => ({
                ...prev,
                goal: true,
              }))
            }}
            onChange={(value) => {
              setIsEditing(index)
              setCurrentEditingMetric({
                ...activeMetric,
                goal: value as number,
              })
              setTouched((prev) => ({
                ...prev,
                goal: true,
              }))
              validateMetric({
                ...activeMetric,
                goal: value as number,
              })
              setHasUnsavedChanges(true)
            }}
            disabled={!isEditingEnabled}
          />
        </td>

        <td className='metric-actions'>
          <div>
            {isEditingMetric(index) && (
              <div className='editing-buttons'>
                <button
                  type='button'
                  className='editing-button'
                  disabled={errors.length > 0}
                  onClick={() => {
                    const newErrors = validateMetric(activeMetric, true)
                    if (newErrors.length === 0) {
                      saveMetric(index)
                      setHasUnsavedChanges(false)
                    }
                  }}>
                  <IconCheckMarkFilled />
                </button>
                <button
                  type='button'
                  className='editing-button'
                  onClick={() => {
                    setIsEditing(-1)
                    resetMetric(index)
                    setErrors([])
                    setAllErrors([])
                    setHasUnsavedChanges(false)
                    setTouched({
                      goal: false,
                      displayName: false,
                    })
                  }}>
                  <IconMisuse />
                </button>
              </div>
            )}
            {metricId !== 'new-metric' && !isEditingMetric(index) && (
              <button
                type='button'
                className='remove-button'
                title='Delete Metric'
                data-testid='metric-delete-button'
                onClick={() => removeMetric(index)}>
                <IconTrash />
              </button>
            )}
          </div>
        </td>
      </tr>
      {errors.length > 0 && (
        <tr className='metric-errors' data-testid={`row-${metricId}-errors`}>
          <td colSpan={5}>
            <div className='metric-errors-container'>
              {errors.map((error) => (
                <div className='metric-error'>
                  <IconWarningTriangleRed />
                  <p>{error}</p>
                </div>
              ))}
            </div>
          </td>
        </tr>
      )}
    </>
  )
}
MetricRow.displayName = 'MetricRow'

export default MetricRow
