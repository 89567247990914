import { useMemo } from 'react'

import Demo from 'configs/demo'

/**
 * Custom hook to determine whether the demo features are enabled
 * DEMO_FEATURES_ENABLED_KEY gets set to 'true' if the application loads and the URL contains `?demo=true` query parameter
 * It can be toggled by updating the query parameter between true and false, or clear it down via logging out
 */
const useDemoFeature = () => {
  const isMockApiEnabled = useMemo(() => Demo.mockApiIsEnabled(), [localStorage])

  const enableDemoMockApi = () => {
    Demo.enableMockApi()
  }

  const disableDemoMockApi = () => {
    Demo.disableMockApi()
  }

  return {
    isMockApiEnabled,
    enableDemoMockApi,
    disableDemoMockApi,
  }
}

export default useDemoFeature
