import { fieldTypes } from 'components/common'

import { FieldTypeEnum } from 'models/metadata.model'

const aggregationTypes = [
  FieldTypeEnum.Number,
  FieldTypeEnum.Percent,
  FieldTypeEnum.Boolean,
  FieldTypeEnum.Collection,
  FieldTypeEnum.Date,
  FieldTypeEnum.Others,
]
const aggregationOptions = {
  sum: { label: 'Sum', value: 'sum' },
  average: { label: 'Average', value: 'average' },
  min: { label: 'Min', value: 'min' },
  max: { label: 'Max', value: 'max' },
  count: { label: 'Count', value: 'count' },
}

// NOTE: We removed count as a default option because it only applies to non-numeric fields
export const getAggregationOptions = (type?: string) => {
  const commonOptions: { label: string; value: string }[] = []
  let options = commonOptions
  const categoryTypes = fieldTypes.filter((types) => type && types.includes(type))

  const filteredTypes = aggregationTypes.filter((aggregationType: string) =>
    categoryTypes[0]?.some((categoryType: string) => aggregationType === categoryType),
  )

  if (filteredTypes[0] === FieldTypeEnum.Number) {
    if (type === FieldTypeEnum.Percent) {
      options = options.concat([aggregationOptions.average, aggregationOptions.min, aggregationOptions.max])
    } else {
      options = options.concat([
        aggregationOptions.sum,
        aggregationOptions.average,
        aggregationOptions.min,
        aggregationOptions.max,
      ])
    }
  }

  return options
}

export const getAggregationLevels = () => [
  { label: 'User', value: 'user' },
  { label: 'Account', value: 'account' },
]
