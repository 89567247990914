import type { Dayjs } from 'dayjs'
import type { Elements } from 'react-flow-renderer'

import type { MetadataTypes } from 'models/metadata.model'
import type { AggregationData, CreateActionFields, Item, KeyValueField } from 'models/motion/motionBuilder.model'
import type { CreateSlackMessagePayload, DynamicInputs } from 'models/slack-messages'

export interface BaseMotion extends MotionError {
  title: string
  description: string
  // TODO: Remove goal &  once https://github.com/Encore-Post-Sales/data-core/pull/1013 is merged
  goal?: object
  metrics?: any[]
}

export interface Dsl {
  startAt: string
  states: States
  aggregations?: AggregationData[]
  /** title is deprecate is not used anywhere in FE, but it's still returned by some legacy Motions */
  title?: string
}

export interface MotionIntegration {
  platform: string
}

export interface States {
  [key: string]: NodeState
}

export interface MotionExecute extends MotionIdentifiers {
  executionCadence?: string
  refreshSegment?: boolean
  includePreviousAccounts?: boolean
  includePreviousUsers?: boolean
}

export interface NewMotion extends BaseMotion {
  currState: MotionStateEnum
  stateHistory: StateHistoryType
  dsl: Dsl
  tenantId?: string
  createdBy?: string
  stateMachineArn?: string
  isActive: boolean
  schedule?: string
  integrations?: MotionIntegration[]
  segmentTotals?: SegmentTotal[]
  segmentExports?: SegmentExport[]
  version?: number
}

export interface Motion extends NewMotion, MotionExecute, MotionIdentifiers {
  createdAt: number
  lastUpdatedAt: number
  segmentTotals?: SegmentTotal[]
  observabilityMetrics?: MotionObservabilityMetrics
  version: number
}

export interface MotionIdentifiers {
  playbookId: string
  version: number
}

export interface MotionObservabilityMetrics {
  participantStatistics: ParticipantStatistics
  actionStatistics: ActionStatistics
}

export interface ActionStatistics {
  totals: ActionStatisticsTotals
}

export interface ActionStatisticsTotals {
  IN_PROGRESS: number
  FAILED: number
  SUCCEEDED: number
}

export interface ParticipantStatistics {
  totals: ParticipantStatisticsTotals
  state: MotionState
}

export interface ParticipantStatisticsTotals {
  accounts: number
  users: number
}

export interface MotionState {
  ENTERED: number
  ACTIVE: number
  COMPLETED: number
}

export type CurrentMotion = NewMotion | Motion | null

export type ExecutionCadence = 'multiple' | 'once'
export type RecurringCadence = 'daily' | 'weekly' | 'monthly'

export interface Schedule {
  enabled: boolean
  minutes: number
  hours: number
  dayOfMonth: number
  dayOfWeek: string
  month: string
  executionCadence: ExecutionCadence
  recurringCadence?: RecurringCadence // Will only be populated if executionCadence === 'multiple'
  ruleName: string
}

export interface SegmentTotal {
  timestamp: number
  totalAccount: number
  totalUsers: number
  currentAccounts: number
  currentUsers: number
  rawSegmentAccounts: number
  rawSegmentUsers: number
}

export interface SegmentExport {
  id: string
  timestamp: string
  requestedBy: string
  status: 'PENDING' | 'SUCCESS' | 'FAILED'
  path?: string
  message?: string
}

export interface MotionsApiResponse {
  data: Motion[]
  total: number
}

export interface MotionItem {
  page: number
  items: Motion[]
}
export interface Motions {
  data: MotionItem[]
  total: number
}

export interface RecursiveTraverse {
  elements: Elements
  nodeId: string | undefined
  states: States
  prevNodeKey?: NodeTypeEnum
  edgeLabel?: BranchLabelEnum
}

export interface NodePosition {
  x: number
  y: number
}

export interface NodeState extends Partial<MetadataTypes>, MotionError {
  type?: string
  next?: string
  end?: boolean
  excludedUserIds?: string[]
  default?: string
  choices?: Choice[]
  payload?: NodePayload
  description?: string
  position?: NodePosition
  actionId?: string
  actionLevel?: string
  targetNodeId?: string
  iconName?: string
  fakeAction?: boolean
}

export interface Choice {
  next?: string
  end?: boolean
}

export interface RecursiveModel {
  flow: Dsl
  stateName: string
  parentStateName?: string
  isNoBranch?: boolean
}

export interface EdgeOption {
  stateName: string
  parentStateName: string
  end?: boolean
  isNoBranch?: boolean
}

export interface NodePayload {
  groups?: PayloadGroup[]
  amount?: number
  units?: string
  subject?: string
  body?: string
  timeOfLoop?: number
  targetNodeId?: string
  fields?: [] | ActionFieldsPayload[]
  targets?: string[]
  exitCondition?: {
    groups?: PayloadGroup[]
    relationOperator: RelationOperator
  }
  /** Used in tests */
  relationOperator?: RelationOperator
}

export interface PayloadGroup {
  groups: Item[] | MotionBuilderItem[]
  relationOperator: RelationOperator
}

export interface ActionFieldsPayload {
  key: string | undefined
  type: string | undefined
  isDynamicInput?: boolean
  displayValue?: string
  /** Used in tests */
  isInvalid?: boolean
  /** Used in tests */
  validationErrors?: string[]
  value:
    | boolean
    | number
    | number[]
    | string
    | string[]
    | Dayjs
    | Dayjs[]
    | CreateSlackMessagePayload
    | CreateActionFields
    | Record<string, number | string | CreateSlackMessagePayload | KeyValueField | DynamicInputs>
    | undefined
    | null
}

export interface CronSchedule {
  enabled: boolean
  minutes: number
  hours: number
  dayOfMonth: number
  dayOfWeek: string
  month: string
  year?: string
  executionCadence: 'once' | 'multiple'
  recurringCadence?: 'daily' | 'weekdaily' | 'weekly' | 'monthly'
  refreshSegment?: boolean
  includePreviousAccounts?: boolean
  includePreviousUsers?: boolean
}

export type LocalDateTimeParams = Pick<CronSchedule, 'minutes' | 'hours' | 'dayOfMonth' | 'month' | 'year'>
export interface LocalDateTime {
  dayOfWeek: string
  month: string
  time: string
  dayOfMonth: number
}

export interface MotionSchedule {
  playbookId: string
  version: number
  cronSchedule: CronSchedule
}

export enum NodeTypeEnum {
  Branch = 'ifElse',
  Loop = 'loop',
  Segment = 'segment',
  Merge = 'merge',
  WaitForTrigger = 'waitForTrigger',
  TimeDelay = 'timeDelay',
  End = 'end',
  Action = 'action',
}

export enum MotionStateEnum {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Executing = 'EXECUTING',
  Scheduled = 'SCHEDULED',
  Suspended = 'SUSPENDED',
  Failed = 'FAILED',
}

type ValueType = any // Array<string | number> | string | number | null
interface MotionBuilderItem {
  field: string | undefined
  isDynamicInput?: boolean
  isNewStatement: boolean
  key?: string
  object: string
  operator: string
  platform: string
  solutionInstanceId?: string
  type: string | undefined
  value: ValueType
  /** Used in tests */
  isInvalid?: boolean
  /** Used in tests */
  validationErrors?: string[]
}

export enum BranchLabelEnum {
  Yes = 'yes',
  No = 'no',
}

export type StateHistoryType = Partial<Record<MotionStateEnum | 'firstExecution', string>>

interface MotionError {
  isInvalid?: boolean
  validationErrors?: string[]
}

export enum MotionStatusEnum {
  Cloned = 'cloned',
  Archived = 'archived',
  Updated = 'updated',
  Added = 'added',
  Canceled = 'cancelled',
}

export enum RefreshSegmentOptions {
  USERS = 'users',
  ACCOUNTS = 'accounts',
}

export enum RelationOperator {
  AND = 'AND',
  OR = 'OR',
}

export interface RefreshSegmentCheckboxInterface {
  label: string
  value: string
  disabled?: boolean
}

export interface MotionExecutionStatuses {
  [key: string]: {
    Items: MotionExecutionStatusesItem[]
    Count: number
  }
}

export interface MotionExecutionStatusesItem {
  version: string
  accountId: string
  executionId: string
  tenantId: string
  status: string
  timestamp: string
  stateMachineArn: string
  SQS: {
    Payload: {
      Deduplication: {
        MessageDeduplicationId: string
        MessageGroupId: string
      }
    }
  }
  userId: string
  instanceId: string
  journeyId: string
  actionId: string
}

export interface InternalMotionExecutionOperationalStats {
  journey: Motion
  journeyExecutions: {
    completedTimestamp: string
    version: number
    csvPath: string
    executionId: string
    tenantId: string
    timestamp: string
    stateMachineArn: string
    journeyId: string
    includePreviousAccounts: boolean
    includePreviousUsers: boolean
    segmentUrl: string
  }[]
  journeyExecutionStatuses: MotionExecutionStatuses[]
}

export interface SendEmailPayload {
  from: string
  to: string
  subject: string
  text: string
  html: string
}

export interface SendTestMessagePayload {
  from: string
  to: string
  message: string
  unfurlLinks: boolean
  platformConnectionId: string
}

export type CheckboxValueType = string | number | boolean
