import axios, { isAxiosError } from 'axios'

import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { getBaseUrl } from 'api/utils'
import { LoggerService } from 'services/LogService/LogService'

import type { TenantsResponse } from 'models/admin'

/**
 * Return the tenants.
 * @returns {Promise<TenantsResponse>}
 */
export const getTenants = async () => {
  try {
    const { data } = await axios.get<TenantsResponse>(`${getBaseUrl('ADMIN_API')}/tenants`)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get tenants error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to get tenants')
    }
  }
}
