import axios, { isAxiosError } from 'axios'

import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { getBaseUrl } from 'api/utils'
import { LoggerService } from 'services/LogService/LogService'

import type { SupportFormValues } from 'models/support.model'

/**
 * Send a Submit Support Request payload to the server.
 * @param {SupportFormValues} payload The Submit Support Request fields.
 * @returns Promise<any> The response from the Support endpoint.
 */
export const post = async (payload: SupportFormValues) => {
  try {
    const { data } = await axios.post<Record<string, any>>(
      `${getBaseUrl('CORE_API')}/v1/core/support/requests`,
      payload,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'postSupportAPI error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to send support message')
    }
  }
}
