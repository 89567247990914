import { isEdge, isNode } from 'react-flow-renderer'

import { mergeArraysWithoutDuples } from 'components/common/MotionThumbnail/Nodes/Segment/SegmentUtils.common'
import { clone } from 'services/Utils/misc'

import type { Edge, Node, Elements, FlowElement } from 'react-flow-renderer'

import type { Motion, MotionExecute, MotionItem, Motions, MotionsApiResponse, NodePayload } from 'models/motion.model'
import { BranchLabelEnum, NodeTypeEnum } from 'models/motion.model'

/**
 * Returns nodes, edges, and loopEdges
 * @param {Elements} elements Elements where the siblings nodes can be.
 * @return { nodes: Node<{ type: string }>[], edges: Edge<{ edgeLabel: string }>[], loopEdges: Edge[] } edges are without special cases like loops
 */
export function elementsSeparator<T, U>(elements: Elements) {
  const nodes: Node<T>[] = []
  const edges: Edge<U>[] = []
  const loopEdges: Edge[] = []

  for (const element of elements) {
    if (isNode(element)) {
      nodes.push(element as Node<T>)
    } else if (isEdge(element)) {
      if (element.type === NodeTypeEnum.Loop) {
        loopEdges.push(element)
      } else {
        edges.push(element as Edge<U>)
      }
    }
  }

  return { nodes, edges, loopEdges }
}

export const isLoopEdge = (edge: Edge) => {
  return edge.type === NodeTypeEnum.Loop
}
const getElementById = (elements: Elements, id: string): FlowElement | undefined => {
  const element = elements.find((element) => {
    return element.id === id
  })
  return element
}

export const sortElementsById = (array: Elements) => {
  // This sort is used to compare arrays of objects ignoring the mock order of objects
  function compare(elA: FlowElement, elB: FlowElement) {
    if (elA.id < elB.id) {
      return -1
    }
    if (elA.id > elB.id) {
      return 1
    }
    return 0
  }

  return array.sort(compare)
}

interface LoopSourcePair {
  loop: string
  loopTarget: string
}

export function getLoopSourcePair(loops: FlowElement<NodePayload>[]): LoopSourcePair[] {
  return loops
    .filter((loop) => loop?.data?.targetNodeId)
    .map((loop) => ({ loop: loop.id, loopTarget: loop?.data?.targetNodeId ?? '' }))
}

// This utility function is able to return the edges between two node ids
interface GetChainOfEdgesProps {
  elements: Elements
  nodeSourceId: string
  nodeTargetId: string
}
export const getChainOfEdges = ({ elements, nodeSourceId, nodeTargetId }: GetChainOfEdgesProps): Edge[] => {
  const edges = elements.filter(isEdge)
  const edgesWithoutLoop = edges.filter((edge) => !isLoopEdge(edge))

  const nodeA = getElementById(elements, nodeSourceId) as Node
  const nodeB = getElementById(elements, nodeTargetId) as Node

  const isArrowDirectionTopBottom = nodeA.position.y < nodeB.position.y
  // for bottom to top we have to reverse the ids
  const source = isArrowDirectionTopBottom ? nodeSourceId : nodeTargetId
  const target = isArrowDirectionTopBottom ? nodeTargetId : nodeSourceId

  const chainOfEdges = []

  if (!source || !target) {
    return []
  }

  ;(function traverseEdges(edgesWithoutLoop: Edge[], sourceId: string, targetId: string) {
    const currentEdge = edgesWithoutLoop.find((edge) => edge.source === sourceId) as Edge
    if (currentEdge) {
      chainOfEdges.push(currentEdge)

      if (currentEdge.target !== targetId) {
        traverseEdges(edgesWithoutLoop, currentEdge.target, targetId)
      }
    }
  })(edgesWithoutLoop, source, target)

  return chainOfEdges
}

interface MarkDisableProps {
  edges: Edge[]
  elementsType: string | string[]
  unmark?: boolean
}

export const markDisableEdges = ({ edges, elementsType, unmark }: MarkDisableProps): Edge[] => {
  const output: Edge[] = clone(edges).map((edge: Edge<{ disabledEdgeFor?: string[] }>) => {
    let disabledEdgeFor: string[] = edge?.data?.disabledEdgeFor || []

    if (unmark) {
      // unmark edges
      if (disabledEdgeFor.length) {
        if (Array.isArray(elementsType)) {
          disabledEdgeFor = disabledEdgeFor.filter((type) => !elementsType.includes(type))
        } else {
          disabledEdgeFor = disabledEdgeFor.filter((type) => type !== elementsType)
        }
      }
    } else {
      if (Array.isArray(elementsType)) {
        elementsType.forEach((element) => {
          if (!disabledEdgeFor.includes(element)) {
            disabledEdgeFor.push(...elementsType)
          }
        })
      } else {
        if (!disabledEdgeFor.includes(elementsType)) {
          disabledEdgeFor.push(elementsType)
        }
      }
    }

    if (edge.data?.disabledEdgeFor?.length && !disabledEdgeFor.length) {
      delete edge.data.disabledEdgeFor
    }

    return {
      ...edge,
      data: {
        ...edge.data,
        // the role of this label is to keep track of what edges are disabled for some actions
        ...(disabledEdgeFor.length && { disabledEdgeFor: disabledEdgeFor }),
      },
    }
  })
  return output
}

interface MarkEdgesProps {
  elements: Elements
  nodeSourceId: string
  nodeTargetId: string
  elementsType: string | string[]
  unmark?: boolean
}

export const getMarkedEdges = ({
  elements,
  nodeSourceId,
  nodeTargetId,
  elementsType,
  unmark,
}: MarkEdgesProps): Elements => {
  // Mark the chain of edges from target to source restricted by loop conditions
  const chainOfLoopEdges = getChainOfEdges({ elements, nodeSourceId, nodeTargetId })
  const markedEdged = markDisableEdges({ edges: chainOfLoopEdges, elementsType, unmark })

  const elementsWithMarkedEdges = mergeArraysWithoutDuples(elements, markedEdged)
  return elementsWithMarkedEdges
}

export const getElementsMergeValidated = (elements: Elements) => {
  const { edges, nodes, loopEdges } = elementsSeparator<
    { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(elements)
  let markedEdges = []
  const potentialEdges: Edge[] = []
  const foundBranches = nodes.filter(isBranchNode)
  if (!foundBranches.length) {
    markedEdges = markDisableEdges({ edges, elementsType: NodeTypeEnum.Merge })
  } else {
    for (let index = 0; index < foundBranches.length; index++) {
      const currentBranch = foundBranches[index]
      potentialEdges.push(...getPotentialMergeEdges([...edges, ...nodes], currentBranch.id))
    }
    // restrict dropping merge inner loops
    const loops = elements.filter((element: FlowElement<{ type: string }>) => element.data?.type === NodeTypeEnum.Loop)
    const loopSourcePair = getLoopSourcePair(loops)
    const chainOfLoopEdges: Edge[] = []
    loopSourcePair.forEach((pair) => {
      chainOfLoopEdges.push(...getChainOfEdges({ elements, nodeSourceId: pair.loop, nodeTargetId: pair.loopTarget }))
    })
    const notPotentialEdges = edges.filter(
      (edge) =>
        !potentialEdges.some((potential) => potential.id === edge.id) ||
        chainOfLoopEdges.some((loopEdge) => loopEdge.id === edge.id),
    )
    markedEdges = markDisableEdges({ edges: notPotentialEdges, elementsType: NodeTypeEnum.Merge })
  }
  const mergedEdges = mergeArraysWithoutDuples(edges, [...markedEdges, ...potentialEdges])
  const elementsWithMarkedEdges = [...nodes, ...mergedEdges, ...loopEdges]
  return elementsWithMarkedEdges
}

export const getPotentialMergeEdges = (elements: Elements, startNodeId: string) => {
  const { edges, nodes } = elementsSeparator<
    { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
    { edgeLabel: string; edgeType: NodeTypeEnum }
  >(elements)
  const chainOfEdges = []
  // Restrict based on "No branch"
  let noBranchX
  ;(function traverseEdges(edges: Edge<{ edgeLabel: string }>[], startNodeId: string) {
    const targetEdges = edges.filter((edge) => edge.source === startNodeId)
    // we have multiple edges with the same source for branch cases
    for (let index = 0; index < targetEdges.length; index++) {
      const currentEdge: Edge<{ edgeLabel: string }> = targetEdges[index]
      const targetNode = nodes.find((node) => node.id === currentEdge.target)
      if (currentEdge.data?.edgeLabel === BranchLabelEnum.No) {
        noBranchX = targetNode?.position.x
      }
      if (targetNode?.position.x !== noBranchX) {
        chainOfEdges.push(currentEdge)
      }
      if (currentEdge && currentEdge.target !== startNodeId) {
        traverseEdges(edges, currentEdge.target)
      }
    }
  })(edges, startNodeId)
  const unmarkedMergeEdges = markDisableEdges({ edges: chainOfEdges, elementsType: NodeTypeEnum.Merge, unmark: true })
  return unmarkedMergeEdges
}

export const motionCleanUpBeforeUpdate = (motion: Motion): MotionExecute => {
  delete motion.integrations
  return motion
}

function isBranchNode(node: Node<{ type: string }>) {
  return node.data?.type === NodeTypeEnum.Branch
}

export const mapApiResponseToStore = (
  response: MotionsApiResponse,
  limit: number,
  offset: number,
  storedMotions: Motions,
): Motions => {
  const DEFAULT_PAGE_SIZE = 10
  const page = Math.ceil((offset + (response.data.length ?? limit)) / DEFAULT_PAGE_SIZE)
  if (limit > DEFAULT_PAGE_SIZE) {
    const startingFrom = offset ? Math.floor(offset / DEFAULT_PAGE_SIZE) : 1
    const totalPages = Array.from({ length: page }, (_, i) => i + startingFrom)
    const data: MotionItem[] = totalPages
      .map((page, index) => {
        const motionItems = response.data.slice(index * DEFAULT_PAGE_SIZE, (index + 1) * DEFAULT_PAGE_SIZE)
        if (!!motionItems.length) {
          return {
            page,
            items: motionItems,
          }
        } else return null
      })
      .filter(Boolean) as MotionItem[]
    const actualPages = data.map((item) => item.page)
    const filteredStoredMotions = storedMotions.data.filter((item) => !actualPages.includes(item.page))
    return {
      data: [...data, ...filteredStoredMotions],
      total: response.total,
    }
  }
  const pageData = {
    page,
    items: response.data,
  } as MotionItem
  const filteredStoredMotions = storedMotions.data.filter((item) => item.page !== page)
  return {
    data: [...filteredStoredMotions, pageData],
    total: response.total,
  }
}
