import { Dropdown, Form, Input, Button as AntBtn, Popover } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import EllipsisVertical from 'assets/images/ellipsis-vertical.svg?react'
import {
  Button,
  Callout,
  IconClose,
  IconFeedbackAlert,
  IconPenUnderlined,
  IconScheduleExecution,
} from 'components/common'
import { displayMotionErrorPopUp } from 'components/MotionBuilder/MotionErrorPopUp'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import { archiveMotion, getMotionCalloutMessage, cancelMotion, cloneMotion } from 'pages/Motions/Motion.utils'
import useStore from 'store/useStore'

import type { FormInstance, MenuProps } from 'antd'

import type { Motion } from 'models/motion.model'
import { MotionStateEnum } from 'models/motion.model'

export interface MotionTopBarProps {
  isEditingMotionTitle: boolean
  form: FormInstance<any>
  setIsEditingMotionTitle: (isEditing: boolean) => void
  handleOnSave: (options: { updateMotionUrl: boolean; confirmCloseModal: boolean }) => void
  isSaveDisabled: boolean
  closeRedirectPath: string
  isExecutionBtnEnabled: boolean
}

const MotionTopBar = ({
  isEditingMotionTitle,
  form,
  setIsEditingMotionTitle,
  handleOnSave,
  isSaveDisabled,
  closeRedirectPath,
  isExecutionBtnEnabled,
}: MotionTopBarProps) => {
  const [showDeletConfirmationModal, setShowDeletConfirmationModal] = useState(false)
  const [isDeleteMotionLoading, setIsDeleteMotionLoading] = useState(false)

  const { motionStore, motionMetricsStore } = useStore()
  const navigate = useNavigate()
  const { motionMetrics } = useFlags<LaunchDarklyFeatureFlags>()
  const [showNoMetricsModal, setShowNoMetricsModal] = useState(false)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const motionTitle = motionStore.currentMotion?.title

  // Update the form with the most recent title value if it is changed outside of the form.
  useEffect(() => {
    form.setFieldsValue({ title: motionTitle })
  }, [form, motionTitle])

  const handleOnBlurTitle = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setIsEditingMotionTitle(false)
      motionStore.setMotionTitle(event.target.value)
      form.setFieldsValue({ title: event.target.value })
    } else {
      event.stopPropagation()
    }
  }

  const handleValidationDisplay = () => {
    if (motionStore.currentMotion?.validationErrors) {
      displayMotionErrorPopUp(motionStore.currentMotion?.validationErrors)
    }
  }

  const handleMotionScheduleClick = () => {
    // Only show this if the feature flag is enabled and there are no metrics defined for the Motion.
    if (motionMetrics && motionMetricsStore.motionMetrics.length === 0) {
      setShowNoMetricsModal(true)
      return
    }

    // Handles scenario that it is a new Motion that hasn't been persisted to the backend
    if (motionStore.currentMotion && motionStore.currentMotion.createdBy !== undefined) {
      // Validates that the Motion is of type `Motion` and has been persisted to the backend
      motionStore.setDisplayExecuteModal(true, motionStore.currentMotion as Motion)
    }
  }

  const handleViewReportingClick = () => {
    motionStore.setIsInMotionReportingEnabled(false)

    const motionId = (motionStore.currentMotion as Motion)?.playbookId
    const motionVersion = (motionStore.currentMotion as Motion)?.version
    navigate(`/journeys/journey/${motionId}/${motionVersion}/details`)
  }

  const handleDeleteMotionConfirm = () => {
    setIsDeleteMotionLoading(true)

    archiveMotion(motionStore?.currentMotion as Motion, motionStore.archive, motionStore.getAll)
      .catch(console.error)
      .finally(() => {
        setShowDeletConfirmationModal(false)
        setIsDeleteMotionLoading(true)
        navigate('/motions')
      })
  }

  const handleDeleteModalCancel = () => {
    setShowDeletConfirmationModal(false)
  }

  const items: MenuProps['items'] = [
    {
      label: 'Clone',
      key: 'clone',
      className: classNames('dropdown__item'),
      onClick: () => cloneMotion(motionStore.currentMotion as Motion, motionStore.clone, motionStore.getAll),
    },
    {
      label: 'Cancel',
      key: 'cancel',
      className: classNames('dropdown__item'),
      onClick: () => cancelMotion(motionStore.currentMotion as Motion, motionStore.cancel, motionStore.getAll),
      disabled: motionStore.currentMotion?.currState !== MotionStateEnum.Executing,
    },
    {
      label: 'Delete',
      key: 'delete',
      className: classNames('dropdown__item__danger'),
      onClick: () => setShowDeletConfirmationModal(true),
    },
  ]

  /** Control when the Motion Metrics Popover is visible */
  const isVisible = motionMetrics && motionMetricsStore.motionMetrics.length === 0 && isPopoverVisible

  return (
    <>
      <div className='motion'>
        <div className='motion__title'>
          {isEditingMotionTitle ? (
            <Form
              form={form}
              name='motion_title_form'
              labelCol={{
                span: 10,
              }}
              autoComplete='off'
              style={{ display: 'flex' }}
              initialValues={{
                title: motionTitle,
              }}>
              <Form.Item
                name='title'
                colon={false}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Title can't be empty",
                  },
                ]}
                validateStatus={motionTitle ? 'success' : 'error'}
                data-testid='motion__title__input'
                className='motion__title__item'>
                <Input
                  autoFocus={true}
                  onBlur={handleOnBlurTitle}
                  className='motion-title__input'
                  data-testid='motion-title__input'
                  placeholder='New Motion'
                />
              </Form.Item>
            </Form>
          ) : (
            <div className='motion__title__display'>
              <span className='motion__name' onClick={() => setIsEditingMotionTitle(true)}>
                {motionTitle ? motionTitle : <span className='motion__name--placeholder'>New Motion</span>}
              </span>
              {!motionStore.isInMotionReportingEnabled && (
                <IconPenUnderlined
                  className='motion__edit-btn'
                  data-testid='edit-title-btn'
                  onClick={() => setIsEditingMotionTitle(true)}
                />
              )}

              <Callout variant='2'>{getMotionCalloutMessage(motionStore.currentMotion)}</Callout>

              {!!motionStore.currentMotion?.validationErrors?.length && (
                <IconFeedbackAlert onClick={handleValidationDisplay} className='clickable' data-testid='errors-icon' />
              )}
            </div>
          )}
        </div>

        <div className='motion__actions'>
          <Dropdown
            overlayClassName='motions-table-row-actions'
            menu={{ items }}
            placement='bottomRight'
            trigger={['click']}>
            <AntBtn data-testid='motion-actions-button' className='motion__options-btn'>
              <EllipsisVertical />
            </AntBtn>
          </Dropdown>
          {motionStore.currentMotion &&
            !motionStore.isInMotionReportingEnabled &&
            // Validates that the Motion is of type `Motion` and has been persisted to the backend
            (motionStore.currentMotion as Motion)?.playbookId !== undefined &&
            motionStore.currentMotion?.currState === MotionStateEnum.Draft && (
              <Popover
                overlayClassName='motion__execution-btn__popover'
                placement='bottom'
                arrow
                content={
                  !isExecutionBtnEnabled && (
                    <span>You must add at least 1 action to your Motion before running it.</span>
                  )
                }>
                <div
                  className={classNames('motion__execution-btn', {
                    'motion__execution-btn--disabled': !isExecutionBtnEnabled,
                  })}
                  data-testid='motion-schedule-execution'
                  onClick={isExecutionBtnEnabled ? handleMotionScheduleClick : undefined}>
                  <IconScheduleExecution />
                </div>
              </Popover>
            )}
          {motionStore.isInMotionReportingEnabled ? (
            <>
              <Button
                text='Configure Report'
                testId='configure-report'
                type='secondary'
                disabled={(motionStore.currentMotion as Motion)?.playbookId === undefined}
                onClickHandler={() => {
                  navigate(
                    `/motions/motion/${(motionStore.currentMotion as Motion)?.playbookId}/${(motionStore.currentMotion as Motion)?.version}/report`,
                  )
                }}
                style={{ marginRight: '16px' }}
              />
              <Button
                text='View Reporting'
                testId='view-reporting'
                type='secondary'
                onClickHandler={handleViewReportingClick}
                style={{ marginRight: '16px' }}
              />
              <Button
                text='Save Motion'
                testId='save-motion'
                onClickHandler={() => {
                  setIsPopoverVisible(true)
                  handleOnSave({ updateMotionUrl: true, confirmCloseModal: false })
                }}
                disabled={isSaveDisabled}
              />
            </>
          ) : (
            <>
              {motionMetrics && (
                <Popover
                  open={isVisible}
                  placement='bottom'
                  overlayClassName='motion-goals-popover'
                  arrow={true}
                  content={
                    <>
                      <span>
                        You haven't tracked any metrics of this Motion, click here to set your metrics to track your
                        Motion performance.
                      </span>
                      <Button
                        testId='motion-goal-popover-btn'
                        text='OK, I got it'
                        type='secondary'
                        size='L'
                        onClickHandler={() => {
                          setIsPopoverVisible(false)
                        }}
                      />
                    </>
                  }>
                  <Button
                    text='Configure Report'
                    testId='configure-report'
                    type='secondary'
                    disabled={(motionStore.currentMotion as Motion)?.playbookId === undefined}
                    onClickHandler={() => {
                      navigate(
                        `/motions/motion/${(motionStore.currentMotion as Motion)?.playbookId}/${(motionStore.currentMotion as Motion)?.version}/report`,
                      )
                    }}
                    style={{ marginRight: '16px' }}
                  />
                </Popover>
              )}
              <Button
                text={'Save Motion'}
                testId='save-motion'
                onClickHandler={() => {
                  setIsPopoverVisible(true)
                  handleOnSave({ updateMotionUrl: true, confirmCloseModal: false })
                }}
                disabled={isSaveDisabled}
              />
            </>
          )}

          <Link
            className='c-close'
            to={closeRedirectPath}
            onClick={() => motionStore.setIsInMotionReportingEnabled(false)}
            data-testid='close-motion'>
            <IconClose />
          </Link>
        </div>
      </div>

      <Modal
        title='No metrics defined for this Motion'
        open={showNoMetricsModal}
        onCancel={() => setShowNoMetricsModal(false)}
        centered
        footer={[
          <Button
            key='cancel-no-motion-metrics'
            text='Run without Metrics'
            htmlType='button'
            testId='cancel-no-motion-metrics'
            type='secondary'
            onClickHandler={() => {
              // Handles scenario that it is a new Motion that hasn't been persisted to the backend
              if (motionStore.currentMotion && motionStore.currentMotion.createdBy !== undefined) {
                // Validates that the Motion is of type `Motion` and has been persisted to the backend
                motionStore.setDisplayExecuteModal(true, motionStore.currentMotion as Motion)
              }
              setShowNoMetricsModal(false)
            }}
          />,
          <Button
            key='submit-no-motion-metrics'
            text='Add Metrics'
            htmlType='submit'
            testId='submit-no-motion-metrics'
            type='primary'
            onClickHandler={() => {
              navigate(
                `/motions/motion/${(motionStore.currentMotion as Motion).playbookId}/${(motionStore.currentMotion as Motion).version}/report`,
              )
            }}
          />,
        ]}>
        <p>
          No metrics have been defined for this motion. You will not have historical data to report against for this
          motion until you define metrics. Do you want to proceed?
        </p>
      </Modal>

      <Modal
        title='Delete Motion?'
        centered
        open={showDeletConfirmationModal}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button
            key='cancel'
            text='Cancel'
            type='secondary'
            testId='cancel-btn'
            onClickHandler={handleDeleteModalCancel}
            disabled={isDeleteMotionLoading}
          />,
          <Button
            key='confirm'
            text='Confirm'
            type='danger'
            testId='confirm-btn'
            onClickHandler={handleDeleteMotionConfirm}
            disabled={isDeleteMotionLoading}
            loading={isDeleteMotionLoading}
          />,
        ]}>
        <p>Are you sure you want to delete this Motion? This action can not be undone.</p>
      </Modal>
    </>
  )
}
MotionTopBar.displayName = 'MotionTopBar'

export default MotionTopBar
