import { message, Modal, Skeleton, Table } from 'antd'
import Search from 'antd/lib/input/Search'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import Button from 'components/common/Button'
import MotionExecutionModal from 'components/MotionExecutionModal'
import useDemoFeature from 'hooks/useDemoFeature'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import { archiveMotion, cancelMotion, cloneMotion } from 'pages/Motions/Motion.utils'
import type { MotionTableSource } from 'pages/Motions/MotionsList/helpers/table'
import { DEFAULT_PAGE_SIZE, getTableColumns } from 'pages/Motions/MotionsList/helpers/table'
import useStore from 'store/useStore'

import type { Motion, MotionIdentifiers } from 'models/motion.model'

const getMotionExecuteData = (motion: Motion): MotionIdentifiers => ({
  playbookId: motion.playbookId,
  version: motion.version,
})

const MotionsList = observer(() => {
  const { motionStore } = useStore()
  const { motions, isLoading, getAll, setDisplayExecuteModal, setIsInMotionReportingEnabled } = motionStore

  const [motionTableSource, setMotionTableSource] = useState<MotionTableSource>({ data: [], page: 1, total: 0 })
  const [isSearching, setIsSearching] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [isShiftPressed, setIsShiftPressed] = useState(false)
  const [motionToDelete, setMotionToDelete] = useState<Motion>()

  const navigate = useNavigate()
  const { search } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { isMockApiEnabled } = useDemoFeature()

  useDisplayErrorNotification(motionStore)

  const redirectMotion = useCallback(
    (id: string, version: number, suffix: string, shouldEnableInMotionReporting: boolean = false) => {
      setIsInMotionReportingEnabled(shouldEnableInMotionReporting)
      navigate(`/motions/motion/${id}/${version}${suffix}`, {
        state: {
          to: `/motions${suffix}`,
        },
      })
    },
    [navigate],
  )

  const getPageMotions = (page: number = 1): Motion[] => {
    return motions.data.find((data) => data.page === page)?.items ?? []
  }

  const getLastPossiblePage = (): number => {
    return Math.ceil(motions.total / DEFAULT_PAGE_SIZE)
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.shiftKey) {
        setIsShiftPressed(true)
      }
    }
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setIsShiftPressed(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const handleArchive = async (motion: Motion) => {
    // when shift is pressed remove without displaying the popup
    if (isShiftPressed) {
      await archiveMotion(motion, motionStore.archive, motionStore.getAll)
    } else {
      setShowConfirmModal(true)
      setMotionToDelete(motion)
    }
  }

  const handleExecuteNow = async (motion: Motion) => {
    const executeData = getMotionExecuteData(motion)

    try {
      await motionStore.executeNow(executeData)
      void message.success('Next Motion execution will run in 5 minutes')

      await getAll(true)
    } catch (error: unknown) {
      void message.error('Failed to Execute the Motion')
    }
  }

  const handleClone = async (motion: Motion) => cloneMotion(motion, motionStore.clone, motionStore.getAll)

  const handleCancel = async (motion: MotionIdentifiers) => cancelMotion(motion, motionStore.cancel, motionStore.getAll)

  const handleExecute = (motion: Motion) => {
    setDisplayExecuteModal(true, motion)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    const isMissingOrFirstOrLastPage =
      page === 1 || page >= getLastPossiblePage() || motions.data.findIndex((motion) => motion.page === page) === -1
    if (!isSearching && isMissingOrFirstOrLastPage) {
      motionStore.getPaginatedMotions(pageSize, page * pageSize - pageSize).catch(console.error)
    }

    setSearchParams({ page: `${page}` })
  }

  const handleOnSearch = async (value: string) => {
    setIsSearching(!!value)
    setSearchParams({})

    const SEARCH_LIMIT = 1000
    await getAll(true, value ? SEARCH_LIMIT : undefined, 0, value)
  }

  const handleDeleteSchedule = async (motion: Motion) => {
    try {
      await motionStore.deleteMotionSchedule(motion)
      void message.success('Successfully deleted Motion schedule')

      // Refresh the Motions to prevent the user from having to manually refresh the entire application
      await getAll(true)
    } catch (error: unknown) {}
  }

  useEffect(() => {
    motionStore.getAll().catch(console.error)

    if (!!motions.data.length) {
      const pageMotions = getPageMotions(motionTableSource.page)
      setMotionTableSource((prev) => ({ ...prev, data: pageMotions, total: motions.total }))
    }
  }, [motions, motionTableSource.page])

  useEffect(() => {
    const page = Number(searchParams.get('page'))
    if (!page || motionTableSource.page === page) return

    setMotionTableSource((prev) => ({ ...prev, page }))
  }, [searchParams])

  const handleModalConfirm = () => {
    archiveMotion(motionToDelete, motionStore.archive, motionStore.getAll).catch(console.error)
    setShowConfirmModal(false)
  }

  const handleModalCancel = () => {
    setShowConfirmModal(false)
  }

  if (isLoading && !motions.data.length && !motionTableSource.data.length) {
    return <Skeleton />
  }

  return (
    <div>
      <MotionExecutionModal />
      <Search className='motion-search' placeholder='Search Motion name...' onSearch={handleOnSearch} allowClear />
      <Table
        className='motions-table'
        data-testid='motions-table'
        loading={isLoading}
        dataSource={motionTableSource.data}
        columns={getTableColumns({
          search,
          isMockApiEnabled,
          redirectMotion,
          handleArchive,
          handleClone,
          handleCancel,
          handleExecute,
          handleDeleteSchedule,
          handleExecuteNow,
          totalMotions: isSearching ? motionTableSource.total : motions.total,
        })}
        rowKey='playbookId'
        pagination={{
          current: motionTableSource.page,
          onChange: handlePageChange,
          showSizeChanger: false,
          total: motionTableSource.total,
          defaultPageSize: DEFAULT_PAGE_SIZE,
        }}
      />
      <Modal
        title='Delete Motion?'
        centered
        open={showConfirmModal}
        onCancel={handleModalCancel}
        footer={[
          <Button key='cancel' text='Cancel' type='secondary' testId='cancel-btn' onClickHandler={handleModalCancel} />,
          <Button
            key='confirm'
            text='Confirm'
            testId='confirm-btn'
            type='danger'
            onClickHandler={handleModalConfirm}
          />,
        ]}>
        <p>Are you sure you want to delete this Motion? This action cannot be undone.</p>
      </Modal>
    </div>
  )
})
MotionsList.displayName = 'MotionsList'
export default MotionsList
