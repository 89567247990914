import type { ImpactEnum } from 'models/insights'

export interface AccountDetails {
  granular: string
  datetime: string
  dataset_date: string
  account_id: string
  account_name: string
  /** 0, "nan" */
  user_count: number | string
  usages: Usages
  events: Events
}

export interface Usages {
  revenues: Revenues
}

export interface Revenues {
  trend: UsagesTrend[]
  lift: UsagesLift
}

export interface UsagesTrend {
  label: string
  value: number
  lowerbound?: number | null
  upperbound?: number | null
  forecast: boolean
}

export interface UsagesLift {
  from: string
  to: string
  value: string
  percent: number
}

export interface Events {
  churn?: Event
  expansion?: Event
  conversion?: Event
}

export interface Event {
  impact: Impact
  propensity: number
  propensity_text: string
  segment: string
  trend: EventTrend[]
  lift: EventLift
  drivers: Driver[]
}

export interface EventTrend {
  label: string
  value: number
}

export interface EventLift {
  from: string
  to: string
  value: number
  percent: number
}

export interface Driver {
  driver_id: number | string
  family: Family
  propensity: number
  segment: string
  metrics: Metrics
}

export interface Family {
  platform: string
  object: string
  field: string
}

export interface Metrics {
  '360d average'?: Metric
  '90d total'?: Metric
  '180d minimum'?: Metric
  '180d average'?: Metric
}

export interface Metric {
  value: number | string
  segment: string
}

export interface MachineLearningMetricsTable {
  key: React.Key
  driverId: number | string
  rank: string
  factor: Factor
  factorProbability: string
}

export enum MlMetricCategoryEnum {
  Churn = 'churn',
  Expansion = 'expansion',
  Conversion = 'conversion',
}

export type Impact = ImpactEnum | 'neutral'

export type MlMetricCategory = 'churn' | 'expansion' | 'conversion'

export type MLMetricRiskLevel = 'low' | 'medium' | 'high' | null

export interface MLMetricContent {
  id: number
  chartData: ChartData
  tableColumns: TableColumn[]
  tableData: TableData[]
}

export interface ChartData {
  title: string
  labels: string[]
  data: number[]
  isPercentage?: boolean
}

export interface TableColumn {
  title: string
  dataIndex: string
  key: string
  width?: string
}

export interface TableData {
  key: number
  rank: string
  factor: Factor
  factorProbability: string
}

export interface Factor {
  platform: string
  familyObj: string
  field: string
  metrics: {
    [key: string]: {
      segment: string
      improve_from: number
      improve_from_text: string
      improve_to: number
      improve_to_text: string
    }
  }
}

export interface ParticipatedMotionsTable {
  key: React.Key
  journeyName: string
  participatedSince: string
  participationStatus: string
}

export interface ParticipatedMotion {
  'accountId#tenantId': string
  journeyId: string
  journeyVersion: string
  journeyName: string
  participatedSince: string
  participationStatus: string
  firstExecutionId: string
  latestExecutionId: string
  users?: number
  actions?: number
}
