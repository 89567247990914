import { makeAutoObservable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { skipSnowflakePlatformRequest } from 'components/common/Utils/constants'
import type { ChildStore } from 'store/StoreTypes'

import type {
  CreateActionDataTypes,
  CriteriaLocatorMetadata,
  MetadataDescription,
  MetadataPlatformResponse,
  MetadataRoot,
  MetadataSearchItem,
  MetadataSearchPayload,
  MetadataTypes,
  SetMetadata,
  TransversMetadata,
} from 'models/metadata.model'
import type {
  BreadcrumbInfo,
  CreateActionFields,
  Item,
  MetadataActionFields,
  SelectOptions,
} from 'models/motion/motionBuilder.model'

export interface MetadataApiError {
  coreApiError: { id: string; error: CoreAPIErrorResponse }
  seen: boolean
}

export class MetadataStore implements ChildStore {
  currentItem: MetadataTypes = {}
  isLoading = true
  metadata: MetadataRoot = {
    data: [],
  }
  viewListMetadata: MetadataDescription[] = []
  filteredMetadataList: MetadataDescription[] = []
  criteriaSelect = {
    isLoading: false,
    options: new Map<string, SelectOptions[]>(),
  }

  metadataActionFields: MetadataActionFields = {
    data: {},
    isLoading: false,
  }
  isSearchLoading: boolean = false
  displaySearchResult: boolean = false
  hasError: boolean = false
  errorMsg: string = ''
  searchOutput: MetadataSearchItem[] = []
  breadCrumbItems: BreadcrumbInfo[] = []
  criteriaLocatorMetadata: CriteriaLocatorMetadata = {
    hasError: false,
    isOpen: false,
    metadataViewList: [],
  }
  customMetadataLoading: boolean = false

  apiErrors: MetadataApiError[] = []

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.currentItem = {}
    this.isLoading = true
    this.metadata = {
      data: [],
    }
    this.viewListMetadata = []
    this.filteredMetadataList = []
    this.criteriaSelect = {
      isLoading: false,
      options: new Map<string, SelectOptions[]>(),
    }
    this.metadataActionFields = {
      data: {},
      isLoading: false,
    }
    this.isSearchLoading = false
    this.displaySearchResult = false
    this.hasError = false
    this.errorMsg = ''
    this.searchOutput = []
    this.breadCrumbItems = []
    this.criteriaLocatorMetadata = {
      hasError: false,
      isOpen: false,
      metadataViewList: [],
    }
    this.customMetadataLoading = false
    this.apiErrors = []
  }

  initMetadata = () => {
    this.setCurrentItem(null)
    this.setViewListMetadata(this.metadata)
  }

  /**
   * Returns true if metadata is loaded successfully
   * @param {MetadataTypes} [options] MetadataTypes
   * @returns {boolean} When true the fetch was successful, when false the fetch failed.
   **/
  get = async (options?: MetadataTypes): Promise<boolean> => {
    let success = false
    this.isLoading = true
    try {
      let newMetadata: MetadataRoot = {
        data: [],
      }
      if (skipSnowflakePlatformRequest(options)) {
        newMetadata = (await API.metadata.get(options)) as MetadataRoot
      }

      await runInAction(async () => {
        if (newMetadata instanceof Error) {
          this.setHasError(true)
          this.errorMsg = newMetadata.message
        } else {
          this.setHasError(false)
          await this.setMetadata({ ...options, newMetadata })
          this.setViewListMetadata(newMetadata)
          this.criteriaLocatorMetadata.hasError = false

          if (this.criteriaLocatorMetadata.isOpen) {
            this.criteriaLocatorMetadata.metadataViewList = newMetadata.data
          }
          success = true
        }
      })
    } catch (error: unknown) {
      const uuid = crypto.randomUUID()
      this.setApiErrors({ id: uuid, error: error as CoreAPIErrorResponse }, false)
      runInAction(() => {
        if (this.criteriaLocatorMetadata.isOpen) {
          this.criteriaLocatorMetadata.hasError = true
        } else {
          this.setCurrentItem(null)
        }
      })
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }

    return success
  }

  setViewListMetadata = (values: MetadataRoot, withoutTypes?: boolean) => {
    if (withoutTypes) {
      this.viewListMetadata = values.data
      return
    }

    if (this.hasError) {
      this.setHasError(false)
    }

    this.viewListMetadata = this.addObjectsEntityType(values)
  }

  setMetadata = async (props: SetMetadata) => {
    const { newMetadata, platform, object, field } = props

    if (platform) {
      this.transverseMetadata({
        metadata: this.metadata,
        options: { platform, object, field },
        newMetadata,
        iterationNumber: 0,
      })
    }

    if (!platform) {
      const metadataWithTypes = this.addObjectsEntityType(newMetadata)
      this.metadata.data = metadataWithTypes
    }

    /* START - [MAGPROD-1263] - group snowflake connections into array of objects  */
    try {
      if (!platform && !object && Array.isArray(newMetadata.data)) {
        const snowflakeMetadata = newMetadata.data.find((metadata) => metadata.name === 'snowflake')

        if (snowflakeMetadata) {
          const connections = snowflakeMetadata.connections || []
          const platformIndex = this.metadata.data.map((metadata) => metadata.name).indexOf(snowflakeMetadata.name)

          if (platformIndex > -1) {
            this.metadata.data[platformIndex].data = []
            this.metadata.data[platformIndex].connections = []
            this.customMetadataLoading = true

            const response = await Promise.all(
              connections.map((connection) =>
                API.metadata.get({
                  platform: snowflakeMetadata.name,
                  solutionInstanceId: connection.solutionInstanceId,
                }),
              ),
            )

            response.forEach((result, resIndex) => {
              if (result && Array.isArray(result.data)) {
                result.data.forEach((data) => {
                  this.metadata.data[platformIndex].data.push({
                    name: data.name || '',
                    magnifyDisplayName: (data as CreateActionFields).magnifyDisplayName || '',
                    solutionInstanceId: connections[resIndex].solutionInstanceId,
                    data: [],
                    connections: [],
                  })
                })
              }
            })
          }
        }
      }
    } catch (error: unknown) {
      const uuid = crypto.randomUUID()
      this.setApiErrors({ id: uuid, error: error as CoreAPIErrorResponse }, false)
    } finally {
      runInAction(() => {
        this.customMetadataLoading = false
      })
    }
    /* END - [MAGPROD-1263] ------------------------------------------------------ */
    return this.metadata
  }

  addObjectsEntityType = (metadata: MetadataRoot): MetadataDescription[] => {
    // platform/object/field
    if (!Array.isArray(metadata.data)) {
      return this.viewListMetadata
    }
    const metadataWithTypes = metadata?.data.map((metadataDescription: MetadataDescription) => {
      let entityType = 'object'
      if (metadataDescription.connections && Array.isArray(metadataDescription.connections)) {
        entityType = 'platform'
      } else if (metadataDescription.type) {
        entityType = 'field'
      }
      return {
        ...metadataDescription,
        entityType: entityType,
        solutionInstanceId: this.currentItem.solutionInstanceId,
      }
    })
    return metadataWithTypes
  }

  transverseMetadata = (data: TransversMetadata) => {
    const { metadata, options, newMetadata, iterationNumber, nextSearchItem } = data
    const itemToSearch = Object.values(options).filter((element) => element !== undefined) as string[]
    let currentIteration = iterationNumber
    const searchedName = nextSearchItem || itemToSearch[iterationNumber]
    const mustAddData = searchedName === itemToSearch[itemToSearch.length - 1]

    if (metadata?.data?.length && searchedName) {
      for (let i = 0; i < metadata.data.length; i++) {
        if (metadata.data[i].name === searchedName && mustAddData) {
          metadata.data[i].data = newMetadata.data
          break
        }
        if (metadata.data[i].name === searchedName) {
          currentIteration++
          this.transverseMetadata({
            metadata: metadata.data[i],
            options,
            newMetadata,
            iterationNumber: currentIteration,
            nextSearchItem: itemToSearch[currentIteration],
          })
        }
      }
    }
  }

  setCurrentItem = (item: MetadataDescription | null, forced?: boolean) => {
    if (forced) {
      this.currentItem = item as MetadataTypes
      return
    }
    if (!item) {
      this.currentItem = {}
      return
    }

    const object: { [k: string]: string | undefined } = {}
    if (item.entityType !== undefined && item.type === undefined) {
      object[item.entityType] = item.name
    } else {
      object.field = item.name
    }

    object.solutionInstanceId = item.solutionInstanceId ?? this.currentItem.solutionInstanceId

    if (object.platform) {
      delete this.currentItem.object
      delete this.currentItem.magnifyDisplayName
    }

    this.currentItem = {
      ...this.currentItem,
      ...object,
      ...(item.magnifyDisplayName && { magnifyDisplayName: item.magnifyDisplayName }),
    }
  }

  loadCriteriaInputOptions = async (item: CreateActionFields | Item, itemKey: string, isAction?: boolean) => {
    this.criteriaSelect.isLoading = true
    const { platform, object, key, field, solutionInstanceId } = item

    const options = this.criteriaSelect.options.get(itemKey) as SelectOptions[]

    try {
      let picklistValues: MetadataPlatformResponse = {} as MetadataPlatformResponse
      if (!options || (options && !options.length)) {
        picklistValues = await API.metadata.get(
          {
            platform,
            object,
            field: key || field,
            solutionInstanceId,
            type: (item as CreateActionFields).ctaTypeValue,
          },
          isAction,
        )
      }

      runInAction(() => {
        if ('dropdownValues' in picklistValues?.data) {
          this.setCriteriaSelectOptions(itemKey, picklistValues.data.dropdownValues as SelectOptions[])
        } else {
          this.setCriteriaSelectOptions(itemKey, [])
        }
      })
    } catch (error: unknown) {
      const uuid = crypto.randomUUID()
      this.setApiErrors({ id: uuid, error: error as CoreAPIErrorResponse }, false)
    } finally {
      runInAction(() => {
        this.criteriaSelect.isLoading = false
      })
    }
  }

  setCriteriaSelectOptions = (key: string, value: SelectOptions[]) => {
    this.criteriaSelect.options.set(key, value)
  }

  getLoadedMetadata = (data: {
    metadata: MetadataRoot
    options: MetadataTypes | undefined
    iterationNumber: number
    nextSearchItem?: string
  }): MetadataDescription[] => {
    const { metadata, options, iterationNumber, nextSearchItem } = data
    if (!options) {
      return []
    }
    let existingMetadata: MetadataDescription[] = []

    const itemToSearch = Object.values(options).filter((element) => element !== undefined) as string[]
    let currentIteration = iterationNumber
    const searchedName = nextSearchItem || itemToSearch[iterationNumber]
    const mustAddData = searchedName === itemToSearch[itemToSearch.length - 1]

    if (metadata?.data?.length && searchedName) {
      for (let i = 0; i < metadata.data.length; i++) {
        if (metadata.data[i].name === searchedName && mustAddData) {
          return (existingMetadata = metadata.data[i]?.data)
        }
        if (metadata.data[i].name === searchedName) {
          currentIteration++
          return this.getLoadedMetadata({
            metadata: metadata.data[i],
            options,
            iterationNumber: currentIteration,
            nextSearchItem: itemToSearch[currentIteration],
          })
        }
      }
    }
    return existingMetadata
  }

  getMetadataActionFields = async (options: MetadataTypes) => {
    this.metadataActionFields.isLoading = true
    try {
      const { data } = await API.metadata.get(options)

      runInAction(() => {
        this.setMetadataActionFields(data as CreateActionDataTypes[], options)
      })
    } catch (error: unknown) {
      const uuid = crypto.randomUUID()
      this.setApiErrors({ id: uuid, error: error as CoreAPIErrorResponse }, false)
    } finally {
      runInAction(() => {
        this.metadataActionFields.isLoading = false
      })
    }
  }

  setMetadataActionFields = (data: CreateActionDataTypes[], options: MetadataTypes) => {
    const platforms = Object.keys(this.metadataActionFields.data)
    if (!options.platform) {
      console.error('setMetadataActionFields called with no platform:', options)
      return
    }

    if (platforms.includes(options.platform)) {
      const objects = Object.keys(this.metadataActionFields.data[options.platform])
      if (!options.object) {
        console.error('setMetadataActionFields called with no object:', options)
        return
      }

      if (objects.includes(options.object)) {
        this.metadataActionFields = {
          ...this.metadataActionFields,
          data: {
            ...this.metadataActionFields.data,
            [options.platform]: {
              ...this.metadataActionFields.data[options.platform],
              [options.object]: {
                ...this.metadataActionFields.data[options.platform][options.object],
                [options.action as string]: data as CreateActionFields[],
              },
            },
          },
        }
      } else {
        this.metadataActionFields = {
          ...this.metadataActionFields,
          data: {
            ...this.metadataActionFields.data,
            [options.platform]: {
              ...this.metadataActionFields.data[options.platform],
              [options.object]: {
                [options.action as string]: data as CreateActionFields[],
              },
            },
          },
        }
      }
    } else {
      this.metadataActionFields = {
        ...this.metadataActionFields,
        data: {
          ...this.metadataActionFields.data,
          [options.platform]: {
            [options.object as string]: {
              [options.action as string]: data as CreateActionFields[],
            },
          },
        },
      }
    }
  }

  fetchSearch = async (payload: MetadataSearchPayload) => {
    this.isSearchLoading = true
    try {
      const result = await API.metadata.search(payload)

      runInAction(() => {
        if (result instanceof Error) {
          this.setHasError(true)
          this.errorMsg = result.message
        } else {
          this.setHasError(false)
          this.searchOutput = result.data
        }

        this.isSearchLoading = false
      })
    } catch (error: unknown) {
      const uuid = crypto.randomUUID()
      this.setApiErrors({ id: uuid, error: error as CoreAPIErrorResponse }, false)
    } finally {
      runInAction(() => {
        this.isSearchLoading = false
      })
    }
  }

  setBreadCrumbItems = (breadcrumbs: BreadcrumbInfo[]) => {
    this.breadCrumbItems = breadcrumbs
  }

  setDisplaySearchResult = (value: boolean) => {
    this.displaySearchResult = value
  }

  getEntityType = (item: MetadataSearchItem, isLast: boolean, index: number): string => {
    const localType = index === 0 ? 'platform' : 'object'
    return isLast ? item.type : localType
  }

  addSearchBreadcrumbs = (item: MetadataSearchItem) => {
    const breadcrumbs: BreadcrumbInfo[] = []
    const path: string[] = []

    item.order.forEach((element, index) => {
      path.push(element.name)
      const isLastElement = index === item.order.length
      const displayName = element.magnifyDisplayName

      breadcrumbs.push({
        name: element.name,
        path: [...path],
        entityType: this.getEntityType(item, isLastElement, index),
        ...(displayName && { magnifyDisplayName: displayName }),
      })
    })

    this.setBreadCrumbItems(breadcrumbs)
  }

  setHasError = (value: boolean) => {
    this.hasError = value
  }

  setFreezeBreadcrumbs = (value: boolean) => {
    this.criteriaLocatorMetadata.isOpen = value
  }

  setApiErrors = (error: { id: string; error: CoreAPIErrorResponse }, seen: boolean) => {
    if (seen === true) {
      const existingError = this.apiErrors.find((apiError) => apiError.coreApiError.id === error.id)

      if (existingError) {
        runInAction(() => {
          this.apiErrors = this.apiErrors.map((apiError) => {
            if (apiError.coreApiError.id === existingError.coreApiError.id) {
              return {
                ...apiError,
                seen,
              }
            }
            /* c8 ignore next 1 */
            return apiError
          })
        })
      }
    }

    runInAction(() => {
      this.apiErrors = [
        ...this.apiErrors,
        {
          coreApiError: error,
          seen,
        },
      ]
    })
  }

  shouldApplyLocalMetadataFilter = () => {
    return !!this.currentItem.platform && !!this.currentItem.object
  }

  setFilteredMetadataList = (items: MetadataDescription[]) => {
    this.filteredMetadataList = items
  }
}
