import type { Impact, TableColumn } from 'models/insights'
import type { Motion } from 'models/motion.model'
import type { MotionMetricsPerformance } from 'models/motionMetrics'

import type { ChartTypeEnum } from 'components/MotionDetails/GoalMetric/Chart/helpers/types'

export interface Reporting {
  accountList: AccountList
  goalMetrics?: GoalMetrics[]
  motion?: ReportingMotion
  machineLearningMetrics: MachineLearningMetrics
  emailStatistics: EmailStatistics[]
  motionMetricsPerformance: Record<string, MotionMetricsPerformance>
}

export type EmailStatistics = {
  bounce?: number
  click?: number
  delivered: number
  open: number
  processed: number
  spamreport: number
  journeyId: string
  magnifyTemplateId: string
  templateName: string
  tenantId: string
  createdAt?: number
  updatedAt?: number
  deliveredPercentage?: string
  openPercentage?: string
  clickPercentage?: string
  [key: string]: string | number | undefined
}

export type EmailStatisticsApiResponse = {
  data: EmailStatistics[]
}

export interface ReportingMotion extends Motion {
  csManagers: string[]
  segmentDefinition: string
}

export interface MotionStats {
  journey: {
    users: number
    accounts: number
  }
}

export interface GoalMetrics {
  annotation: GoalMetricsAnnotation
  data: GoalMetricsData[]
  label?: string
  labels: string[]
  title: GoalMetricsTitle
  type?: ChartTypeEnum
  visualization: string
}

export interface DataTimeFrame {
  goalMetricsTimeFrame: number
  machineLearningMetricsTimeFrame: number
}

export interface GoalMetricsTitle {
  yTitle: string
  chartTitle: string
}

export interface GoalMetricsAnnotation {
  limit: number
  label: number | string
  tooltip: string
}

export interface GoalMetricsData {
  label: ChartLegendEnum | 'Count'
  data: number[][] | number[]
  backgroundColor?: string
  borderColor?: string
  borderWidth?: number
}

export enum ChartLegendEnum {
  FirstQuartile = '1st quartile',
  SecondQuartile = '2nd quartile',
  ThirdQuartile = '3rd quartile',
  FourthQuartile = '4th quartile',
  Average = 'Average',
}

export interface MachineLearningMetrics {
  labels: string[]
  data: number[]
  title: string
}

export interface AccountListPage {
  items: AccountListRow[]
  page: number
}

export interface AccountList {
  columns: TableColumn[]
  data: AccountListPage[]
  total: number
  lastKey?: string | null
  lastKeyHistory?: string[] | null
}

export interface AccountsTableSource {
  data: AccountListRow[]
  page: number
  total: number
}

export enum AccountListKeyEnum {
  AccountID = 'id',
  Account = 'account',
  TotalUsers = 'totalUsers',
  CsManager = 'csManager',
  ParticipatedSince = 'participatedSince',
  ProductStayDuration = 'productStayDuration',
  ServiceTickets = 'serviceTickets',
  ChurnProbability = 'churnProbability',
}

export interface AccountListRow {
  id: string
  [AccountListKeyEnum.Account]: string
  [AccountListKeyEnum.TotalUsers]?: number
  [AccountListKeyEnum.CsManager]: string
  [AccountListKeyEnum.ParticipatedSince]?: string
  [AccountListKeyEnum.ProductStayDuration]?: Composite
  [AccountListKeyEnum.ServiceTickets]?: Composite
  [AccountListKeyEnum.ChurnProbability]?: Composite
  [key: string]: any
}

export interface AccountListApiResponse {
  columns: TableColumn[]
  limit: number
  offset: number
  rows: AccountListRow[]
  search: string | null
  total: number
  /** Used in tests */
  page?: number
}

export interface Composite {
  old: number
  new: number
  unit?: CompositeUnitEnum
  impact: Impact
}

export enum CompositeUnitEnum {
  Months = 'm',
  Percentage = '%',
}

export enum AccountListTypeEnum {
  String = 'string',
  Number = 'number',
  Composite = 'composite',
}

export interface ReportingApiResponse {
  data: ReportingMotion | GoalMetrics[] | MachineLearningMetrics | AccountList
}

export interface MetricsPayload {
  journeyId: string
  weeks: number
}

export interface ReportingLoading {
  isMotionLoading: boolean
  isOperationalStatisticsLoading: boolean
  isGoalMetricsLoading: boolean
  isMachineLearningMetricsLoading: boolean
  isAccountListLoading: boolean
  isEmailStatsLoading: boolean
  isMotionMetricPerformanceLoading: boolean
}

/* API */
export interface OperationalStatisticsApiResponse {
  executionId: string
  JOURNEY?: string
}

export interface GoalMetricData {
  count?: { [key: string]: number }
  averages?: { [key: string]: number }
  quartiles?: { [key: string]: number[] }
}

export interface GoalMetric {
  /** The type of aggregation: countOfValues, average, etc. */
  aggregationType: string
  annotation: GoalMetricsAnnotation
  /** This value will change structure based on the visualization value.
   * For example a value of `barChart` will recieve an object with a single key of `count`
   * that is an object that has the keys of weeks and the value of their count `{ "2023-06-19": 15, ... }`,
   * but `quartile` will recieve an object with 2 keys `average` & `quartiles`, where `average`
   * matches the shape for a bar chart, but `quartiles` has many values `{ "2023-06-19": [5, 10, 15, 20, 40], ... }`. */
  data: GoalMetricData
  id: string
  title: GoalMetricsTitle
  /** The type of chart visualization to use: barChart, quartile, etc. */
  visualization: string
}

export interface GoalMetricsApiResponse {
  journeyId: string
  metrics: GoalMetric[]
  weeks: number
}

export interface MachineLearningMetricsApiData {
  [date: string]: number
}
export interface MachineLearningMetricsApiResponse {
  title: string
  metrics: MachineLearningMetricsApiData
}
