import axios, { isAxiosError } from 'axios'

import type { DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { getBaseUrl } from 'api/utils'
import { LoggerService } from 'services/LogService/LogService'

import type { Tenant } from 'models/tenant'

export const get = async (id: string): Promise<Tenant> => {
  try {
    const { data } = await axios.get<Tenant>(`${getBaseUrl('CORE_API')}/v1/core/tenants/${id}`, {
      demoData: {
        id,
        insightsPublishedAt: Date.now(),
        primarySegmentSource: 'salesforce',
        sandbox: false,
        tenantName: 'Demo Tenant',
      },
    } as DemoAxiosRequestConfig)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'tenants get error', error: error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    }
    throw new Error('Failed to fetch tenant')
  }
}
