import dayjs from 'dayjs'

import appcuesIcon from 'assets/icons/integrations/appcues.png'
import clientSuccessIcon from 'assets/icons/integrations/clientSuccess.png'
import gainsightIcon from 'assets/icons/integrations/gainsight.png'
import hubspotIcon from 'assets/icons/integrations/hubspot.png'
import intercomIcon from 'assets/icons/integrations/intercom.png'
import marketoIcon from 'assets/icons/integrations/marketo.png'
import mongodbIcon from 'assets/icons/integrations/mongodb.png'
import netsuiteIcon from 'assets/icons/integrations/netsuite.png'
import outreachIcon from 'assets/icons/integrations/outreach.png'
import pendoIcon from 'assets/icons/integrations/pendo.png'
import pokemonIcon from 'assets/icons/integrations/pokemon-catalog.png'
import postgresIcon from 'assets/icons/integrations/postgres.png'
import redshiftIcon from 'assets/icons/integrations/redshift.png'
import salesforceIcon from 'assets/icons/integrations/salesforce.png'
import sftpBulkIcon from 'assets/icons/integrations/sftp-bulk.png'
import shopifyIcon from 'assets/icons/integrations/shopify.png'
import slackIcon from 'assets/icons/integrations/slack.png'
import snowflakeIcon from 'assets/icons/integrations/snowflake.png'
import totangoIcon from 'assets/icons/integrations/totango.png'
import zendeskIcon from 'assets/icons/integrations/zendesk.png'
import { IconCheckCircle, IconInfo, IconProcessing } from 'components/common/Icons/Icons'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'

import { DataFormatEnum } from 'models/insights'
import type { Integration, IntegrationConnection } from 'models/Integration.model'

/** The smallest empty PNG image as a data URL. */
export const EMPTY_PNG =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

/**
 * Return the icon for a given integration key.
 * @param {string} key The integration key.
 * @returns The icon URL.
 */
export function getIconUrl(key: string) {
  switch (key) {
    case 'appcues':
      return appcuesIcon
    case 'client-success':
      return clientSuccessIcon
    case 'hubspot':
      return hubspotIcon
    case 'gainsight':
      return gainsightIcon
    case 'intercom':
      return intercomIcon
    case 'marketo':
      return marketoIcon
    case 'mongodb':
      return mongodbIcon
    case 'netsuite':
      return netsuiteIcon
    case 'outreach':
      return outreachIcon
    case 'pendo':
      return pendoIcon
    case 'postgres':
      return postgresIcon
    case 'pokemon-catalog':
      return pokemonIcon
    case 'redshift':
      return redshiftIcon
    case 'salesforce':
      return salesforceIcon
    case 'sftp-bulk':
      return sftpBulkIcon
    case 'shopify':
      return shopifyIcon
    case 'slack':
      return slackIcon
    case 'snowflake':
    case 'snowflakedb':
      return snowflakeIcon
    case 'totango':
      return totangoIcon
    case 'zendesk':
    case 'zendesk-support':
      return zendeskIcon
    default:
      console.warn('Unknown Icon Key:', key)
      return EMPTY_PNG
  }
}

/**
 * Get the live connections stat card data.
 * @param {Integration[]} integrationConnections The integrations.
 * @returns {{title: string, integrations: Integration[], text: string}} The live connections stat card data.
 */
export const getLiveConnectionsStatCardData = (integrationConnections: Integration[]) => {
  if (!integrationConnections || integrationConnections.length === 0) {
    return {
      title: 'Live Connections',
      integrations: [],
      text: '-',
    }
  }
  const liveConnections = integrationConnections.filter(
    (integration) => integration.connections && integration.connections.length,
  )

  return {
    title: 'Live Connections',
    integrations: liveConnections,
    text: liveConnections.length ? liveConnections.length.toString() : '-',
  }
}

/**
 * Get the latest ingested connection from a list of connections.
 * @param {IntegrationConnection[]} connections The connections.
 * @returns {IntegrationConnection} The latest connection or an empty object if the list is empty.
 */
export const getLatestConnection = (connections: IntegrationConnection[]) => {
  if (connections.length === 1) {
    return connections[0]
  }

  if (connections.length === 0) {
    return {} as IntegrationConnection
  }

  return connections.reduce((latest, current) => {
    // Skip connections without lastIngestedDate
    if (!current.lastIngestedDate) return latest

    // If we don't have a latest connection yet, use the current one
    if (!latest.lastIngestedDate) return current

    // Compare dates directly without converting to dayjs objects for better performance
    return new Date(current.lastIngestedDate) > new Date(latest.lastIngestedDate) ? current : latest
  }, {} as IntegrationConnection)
}

/**
 * Get the integration with the latest ingested connection.
 * @param {Integration[]} integrationConnections The integrations.
 * @returns {Integration} The integration with the latest ingested connection or an empty object if the list is empty.
 */
export const getIntegrationWithLatestConnection = (integrationConnections: Integration[]) => {
  return integrationConnections.reduce((acc: Integration, integration) => {
    if (!integration.connections || !integration.connections.length) return acc

    const latestConnection = getLatestConnection(integration.connections)

    if (
      !acc ||
      !acc.connections ||
      new Date(latestConnection.lastIngestedDate) > new Date(acc.connections[0]?.lastIngestedDate ?? 0)
    ) {
      return {
        ...integration,
        connections: [latestConnection],
      }
    }
    return acc
  }, {} as Integration)
}

/**
 * Get the latest ingest stat card data.
 * @param {Integration[]} integrationConnections The integrations.
 * @returns {{title: string, integrations: Integration[], text: string}} The latest ingest stat card data.
 */
export const getLatestIngestStatCardData = (integrationConnections: Integration[]) => {
  if (
    !integrationConnections ||
    integrationConnections.length === 0 ||
    !getIntegrationsWithConnections(integrationConnections).length
  ) {
    return {
      title: 'Lastest Ingest',
      integrations: [],
      text: '-',
    }
  }

  const latestIngestDateConnection = getIntegrationWithLatestConnection(integrationConnections)

  const formattedDate = dayjs(latestIngestDateConnection.connections?.[0]?.lastIngestedDate).format('D[-]MMM h:mm A')

  return {
    title: 'Lastest Ingest',
    integrations: [latestIngestDateConnection],
    text: formattedDate,
  }
}

/**
 * Get the actions executed stat card data.
 * @param {Integration[]} integrationConnections The integrations.
 * @returns {{title: string, integrations: Integration[], text: string}} The actions executed stat card data.
 */
export const getActionsExecutedStatCardData = (integrationConnections: Integration[]) => {
  if (!integrationConnections || integrationConnections.length === 0) {
    return {
      title: 'Actions Executed',
      integrations: [],
      text: '-',
    }
  }

  let totalActionsExecuted = 0

  const connectionsWithExecutedActions = integrationConnections.reduce((acc, integration) => {
    const totalActions = integration.actions?.total || 0
    if (totalActions > 0) {
      totalActionsExecuted += totalActions
      acc.push(integration)
    }
    return acc
  }, [] as Integration[])

  const formattedNumber = formatNumber({
    number: totalActionsExecuted,
    format: DataFormatEnum.CurrencyKMB,
    decimal: 1,
  }).slice(1)

  return {
    title: 'Actions Executed',
    integrations: connectionsWithExecutedActions,
    text: connectionsWithExecutedActions.length > 0 ? formattedNumber : '-',
  }
}

/**
 * Get the status icon for a given status.
 * @param {string} status The status.
 * @returns {React.ReactNode} The status icon.
 */
export const getStatusIcon = (status: string) => {
  switch (status) {
    case 'Live':
      return <IconCheckCircle />
    case 'Processing':
      return <IconProcessing />
    default:
      return <IconInfo />
  }
}

/**
 * Group integrations by category.
 * @param {Integration[]} integrations The integrations.
 * @returns {Record<string, Integration[]>} The integrations grouped by category.
 */
export const groupIntegrationsByCategory = (integrations: Integration[]) => {
  if (!integrations) return {}
  return integrations.reduce(
    (acc, integration) => {
      if (!acc[integration.category]) {
        acc[integration.category] = []
      }
      acc[integration.category].push(integration)
      return acc
    },
    {} as Record<string, Integration[]>,
  )
}

/**
 * Count the status of connections by category.
 * @param {Integration[]} integrations The integrations.
 * @returns {Record<string, Record<string, number>>} The status of connections by category.
 */
export const countStatusByCategory = (integrations: Integration[]) => {
  if (!integrations) return {}
  return integrations.reduce(
    (acc, integration) => {
      if (!acc[integration.category]) {
        acc[integration.category] = {
          Live: 0,
          Processing: 0,
          Error: 0,
        }
      }
      if (!integration.connections) return acc
      integration.connections.forEach((connection) => {
        acc[integration.category][connection.status]++
      })
      return acc
    },
    {} as Record<string, Record<string, number>>,
  )
}

/**
 * Get the integrations with connections.
 * @param {Integration[]} integrations The integrations.
 * @returns {Integration[]} The integrations with connections.
 */
export const getIntegrationsWithConnections = (integrations: Integration[]) => {
  if (!integrations) return []
  return integrations.filter((integration) => integration.connections && integration.connections.length)
}
