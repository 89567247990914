import { Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useFlags } from 'launchdarkly-react-client-sdk'

import AccountsForecastTable from 'components/AccountsForecastTable'
import ActiveMotions from 'components/ActiveMotions'
import { Insights } from 'components/Insights/Insights'
import ActiveMotionsReporting from 'components/MotionObservability/ActiveMotionsReporting'
import RevenueRetentionForecast from 'components/RevenueRetentionForecast'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'

export const Dashboard = () => {
  const { journeyObservability } = useFlags<LaunchDarklyFeatureFlags>()

  return (
    <Layout className='container'>
      <Content className='content content__spacing--lg'>
        <RevenueRetentionForecast />
        {journeyObservability ? <ActiveMotionsReporting /> : <ActiveMotions />}
        <Insights />
        <AccountsForecastTable />
      </Content>
    </Layout>
  )
}
Dashboard.displayName = 'Dashboard'
