/**
 * Function that gets a string and remove spaces,adds undescores and turn the whole string to lower case
 * @param    {value} string - The string that you want to transform
 * @return   {string}       - The transformed string
 */
export const getNormalize = (value: string): string => {
  return (
    value
      .replace('snowflakedb', 'snowflake')
      .replace(/[^\s\w]/g, '')
      .replace(/\s+/g, '_')
      .toLowerCase() || ''
  )
}
/**
 * Function that gets a string and replace special caracters with underscores (_) turnin the whole string to lower case
 * @param    {value} string - The string that you want to transform
 * @return   {string}  - The transformed string
 */
export const sanitizeString = (value: string): string => {
  return value.replace(/[^\da-z]+/gi, '_').toLowerCase() || ''
}
//
export const getOnlyLetters = (str: string) => {
  return str.replace(/\d/g, '')
}

export const getOnlyNumbers = (str: string) => {
  return str.replace(/\D/g, '')
}

/**
 * Converts a string to camelCase format. Avoids using regex to avoid maximum call depth issues.
 * @param {string} str The input string to be converted.
 * @returns {string} The camelCase formatted string.
 */
export function toCamelCase(str: string) {
  let result = ''
  let shouldCapitalize = false

  for (const ch of str) {
    if (isAlphanumeric(ch)) {
      if (!result) {
        // First valid character, always lowercase
        result += ch.toLowerCase()
      } else if (shouldCapitalize) {
        // Capitalize if we just passed a separator
        result += ch.toUpperCase()
        shouldCapitalize = false
      } else {
        result += ch.toLowerCase()
      }
    } else {
      // Mark next alphanumeric for capitalization
      shouldCapitalize = true
    }
  }

  return result
}

/**
 * Checks if a character is alphanumeric (0-9, A-Z, a-z).
 * @param {string} ch The character to check.
 * @returns {boolean} True if the character is alphanumeric, false otherwise.
 */
function isAlphanumeric(ch: string) {
  const code = ch.charCodeAt(0)
  // 0-9, A-Z, a-z
  return (code >= 48 && code <= 57) || (code >= 65 && code <= 90) || (code >= 97 && code <= 122)
}

// converts to camelCase a string
export const camelCaseToNormalCase = (str: string) => {
  const splitByUppercase = str.replace(/([A-Z])/g, ' $1')
  const result = splitByUppercase.charAt(0).toUpperCase() + splitByUppercase.slice(1).toLowerCase()
  return result
}

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

/**
 * Capitalizes the first letter of a given string and converts the rest to lowercase.
 *
 * @param {string} str - The input string to be modified.
 * @returns {string} - The modified string with the first letter capitalized and the rest in lowercase.
 */
export const capitalizeFirstLetter = (str: string) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

/**
 * Checks if the first character of the given string is a vowel.
 *
 * @param str - The string to check. If undefined or empty, returns false.
 * @returns True if the first character is a vowel (a, e, i, o, u), false otherwise.
 */
export const isVowel = (str: string | undefined) => {
  if (!str) return false
  const firstLetter = str.charAt(0).toLowerCase()
  return ['a', 'e', 'i', 'o', 'u'].includes(firstLetter)
}
