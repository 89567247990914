import axios, { isAxiosError } from 'axios'

import { type DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { demoGetAllIntegrations } from 'api/mockResponses/demo/integrations.mock'
import { getBaseUrl } from 'api/utils'
import Demo from 'configs/demo'
import { LoggerService } from 'services/LogService/LogService'
import { mockedIntegration, mockedIntegrations } from 'store/integrations/integrations.store.mocks'

import type {
  IntegrationNew,
  IntegrationParam,
  SalesforceOpportunity,
  MarketoAction,
  Integration,
} from 'models/Integration.model'

export const getActionsData = async (
  app: string,
  connectionId: string,
): Promise<SalesforceOpportunity[] | MarketoAction> => {
  try {
    const { data } = await axios.get<SalesforceOpportunity[] | MarketoAction>(
      `${getBaseUrl('INTEGRATIONS_API')}/testConnection/${app}/${connectionId}`,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getActionsData integration error', error })
    return []
  }
}

/**
 * Get a single integration from the data-core service.
 * @param id {string} The id of the integration to fetch.
 * @param options The parameters of the integration to fetch.
 * @returns {IntegrationNew} The fetched integration.
 */
export const getNew = async (id: string, options?: IntegrationParam) => {
  if (!id) {
    return
  }
  try {
    if (Demo.mockApiIsEnabled()) {
      return mockedIntegrations.find((integration) => integration.key === id) || mockedIntegration
    } else {
      const { data } = await axios.get<IntegrationNew>(`${getBaseUrl('CORE_API')}/v2/core/integrations/${id}`, {
        params: options,
      })
      return data
    }
  } catch (error: unknown) {
    LoggerService.error({ message: 'get integration error', error: error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch connection')
    }
  }
}

/**
 * Create an integration in the data-core service.
 * @param {string} id The type of integration to create.
 * @param {object} payload The payload to send to the API to create the integration.
 * @returns {IntegrationNew} The created integration.
 */
export const postNew = async (id: string, payload: any) => {
  try {
    const { data } = await axios.post<IntegrationNew>(`${getBaseUrl('CORE_API')}/v2/core/integrations/${id}`, payload)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'postNew integration error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to create connection')
    }
  }
}

export const getIntegrations = async () => {
  try {
    const { data } = await axios.get<{ platforms: Integration[] }>(
      `${getBaseUrl('CORE_API')}/v2/core/integrations/connections`,
      {
        demoData: demoGetAllIntegrations,
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get integration connnections error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch integration connections')
    }
  }
}
