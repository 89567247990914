import axios, { isAxiosError } from 'axios'

import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { getBaseUrl } from 'api/utils'
import { LoggerService } from 'services/LogService/LogService'
import { generateWAFErrorMessage, isWAFError } from 'utils/waf-error'

import type { CreateSlackMessagePayload, SlackMessage } from 'models/slack-messages'

/**
 * Create a new slack message.
 * @param {CreateSlackMessagePayload} params
 * @returns {Promise<SlackMessage>}
 */
export const create = async (params: CreateSlackMessagePayload) => {
  try {
    const { data } = await axios.post<SlackMessage>(`${getBaseUrl('CORE_API')}/v1/core/slack-messages`, params)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'create slack-messages error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      // Check for Amazon WAF errors to at least show an error message.
      if (isWAFError(error)) {
        throw new CoreApiError(
          generateWAFErrorMessage({
            instance: '/v1/core/slack-messages',
            message: 'Failed to create Slack message',
          }),
        )
      } else {
        throw new CoreApiError(error.response.data ?? error.message)
      }
    } else {
      throw new Error('Failed to create slack message')
    }
  }
}

/**
 * Return an slack message for the provided ID.
 * @param {object} params The params object of slackMessageId and slackMessageVersion
 * @returns {Promise<SlackMessage>}
 */
export const get = async ({
  slackMessageId,
  slackMessageVersion,
}: {
  slackMessageId: string
  slackMessageVersion: number
}) => {
  try {
    const { data } = await axios.get<SlackMessage>(
      `${getBaseUrl('CORE_API')}/v1/core/slack-messages/messageId/${slackMessageId}/messageVersion/${slackMessageVersion}`,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get slack message error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error(
        `Failed to get slack message with slackMessageId of '${slackMessageId}' & slackMessageVersion of '${slackMessageVersion}'`,
      )
    }
  }
}

/**
 * Update an slack message for the provided ID.
 * @param {object} params The params object of slackMessageId and payload
 * @returns {Promise<SlackMessage>}
 */
export const update = async ({
  slackMessageId,
  payload,
}: {
  slackMessageId: string
  payload: CreateSlackMessagePayload
}) => {
  try {
    const { data } = await axios.put<SlackMessage>(
      `${getBaseUrl('CORE_API')}/v1/core/slack-messages/messageId/${slackMessageId}`,
      payload,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'update slack-messages error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      // Check for Amazon WAF errors to at least show an error message.
      if (isWAFError(error)) {
        throw new CoreApiError(
          generateWAFErrorMessage({
            instance: `/v1/core/slack-messages/messageId/${slackMessageId}`,
            message: 'Failed to update Slack message',
          }),
        )
      } else {
        throw new CoreApiError(error.response.data ?? error.message)
      }
    } else {
      throw new Error(`Failed to update slack messages with ID of '${slackMessageId}'`)
    }
  }
}
