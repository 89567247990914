import { CloseOutlined } from '@ant-design/icons'
import { Button as CloseButton, Layout } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Heading, LogoList } from 'components/common'
import {
  generateMotionTag,
  generateRunSchedule,
  generateTitleAndDescription,
} from 'components/MotionDetails/Header/index.utils'
import ExecutionStatus from 'pages/Motions/InternalOperationalStats/components/ExecutionStatus'
import MotionExecutions from 'pages/Motions/InternalOperationalStats/components/MotionExecutions'
import { DateService } from 'services/Utils/date'
import useStore from 'store/useStore'

import type { MotionIntegration, SegmentTotal } from 'models/motion.model'
import type { ReportingMotion } from 'models/reporting.model'

const generateIntegrations = (integrations: MotionIntegration[] | undefined) => (
  <div className='motions-table-row-integrations'>
    <LogoList integrations={integrations?.map((integration) => integration.platform) ?? []} limit={3} />
  </div>
)

const InternalOperationalStats = observer(() => {
  const navigate = useNavigate()
  const { id: journeyId, version } = useParams()

  const { motionStore } = useStore()
  const { executionOperationalStats, getInternalMotionOperationalStats } = motionStore

  useEffect(() => {
    if (journeyId && version && executionOperationalStats === null) {
      getInternalMotionOperationalStats({ playbookId: journeyId, version: parseInt(version) }).catch(console.error)
    }
  }, [executionOperationalStats])

  const generateSegmentTotals = (segmentTotals: SegmentTotal[] | undefined) => {
    const segmentTotal = !!segmentTotals?.length && segmentTotals[segmentTotals.length - 1]
    return (
      <div className='motions-table-row-segment'>
        {segmentTotal && (
          <>
            <span>
              <span className='motions-table-row-segment__value'>{segmentTotal.totalAccount} Accounts</span>
            </span>
            <span>
              <span className='motions-table-row-segment__value'>{segmentTotal.totalUsers} Users</span>
            </span>
            <span>{DateService.relativeDate(segmentTotal.timestamp)}</span>
          </>
        )}
      </div>
    )
  }

  return (
    <Layout className='container internal-operational-stats-container'>
      <header className='motion-details-header'>
        <section className='motion-details-header-top'>
          <div className='motion-details-header-top-left'>
            <h3>{executionOperationalStats?.journey?.title}</h3>{' '}
            {generateMotionTag(executionOperationalStats?.journey as ReportingMotion)}
          </div>
          <div className='motion-details-header-top-right'>
            <Button
              text='View Motion'
              testId='motion-details-view-motion'
              onClickHandler={() => navigate(`/motions/motion/${journeyId}/${version}`)}
            />
            <CloseButton shape='circle' onClick={() => navigate('/motions')} data-testid='close-details'>
              <CloseOutlined />
            </CloseButton>
          </div>
        </section>

        <section className='motion-details-header-bottom'>
          {generateTitleAndDescription(
            'Created at',
            DateService.parseDateTimestamp(executionOperationalStats?.journey.createdAt ?? '', {
              showTime: true,
            }),
          )}
          {generateTitleAndDescription(
            'Last Updated at',
            DateService.parseDateTimestamp(executionOperationalStats?.journey.lastUpdatedAt ?? '', {
              showTime: true,
            }),
          )}
          {generateTitleAndDescription(
            'Started on',
            executionOperationalStats?.journey?.stateHistory?.EXECUTING
              ? DateService.parseDateTimestamp(executionOperationalStats?.journey.stateHistory.EXECUTING, {
                  showTime: true,
                })
              : 'N/A',
          )}
          {generateTitleAndDescription(
            'Run Schedule',
            generateRunSchedule(executionOperationalStats?.journey as ReportingMotion),
          )}
          {generateTitleAndDescription('Created by', executionOperationalStats?.journey?.createdBy ?? 'N/A')}
          {generateTitleAndDescription(
            'Segment Totals',
            generateSegmentTotals(executionOperationalStats?.journey.segmentTotals) ?? 'N/A',
          )}
          {generateTitleAndDescription(
            'Integrations',
            generateIntegrations(executionOperationalStats?.journey.integrations) ?? 'N/A',
          )}
        </section>
      </header>

      <section className='motion-details-header'>
        <Heading variant='7' level='2'>
          State History
        </Heading>

        <div className='internal-operational-stats-executions-container'>
          {executionOperationalStats &&
            Object.entries(executionOperationalStats?.journey.stateHistory).map(([history, value]) => (
              <div key={history}>
                <div>
                  {generateTitleAndDescription(
                    history,
                    DateService.parseDateTimestamp(value, {
                      showTime: true,
                    }),
                  )}
                </div>
              </div>
            ))}
        </div>
      </section>
      <MotionExecutions
        motionExecutions={executionOperationalStats?.journeyExecutions ?? []}
        id={executionOperationalStats?.journey.playbookId ?? ''}
      />

      <ExecutionStatus executionOperationalStats={executionOperationalStats} />
    </Layout>
  )
})
InternalOperationalStats.displayName = 'InternalOperationalStats'

export default InternalOperationalStats
