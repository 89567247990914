import { CloseOutlined } from '@ant-design/icons'
import { Button as CloseButton, Skeleton } from 'antd'
import Layout, { Content } from 'antd/lib/layout/layout'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import AccountInformation from 'components/AccountDetails/AccountInformation'
import MachineLearningMetrics from 'components/AccountDetails/MachineLearningMetrics'
import ParticipatedMotions from 'components/AccountDetails/ParticipatedMotions'
import { participatedMotionsColumns } from 'components/AccountDetails/ParticipatedMotions/utils'
import RevenueForcasting from 'components/AccountDetails/RevenueForcasting'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import Sandbox from 'configs/sandbox'
import GitHubDemo from 'pages/AccountDetails/GitHubDemo'
import useStore from 'store/useStore'

const AccountDetails = observer(() => {
  const { actionsAcrossChannels } = useFlags<LaunchDarklyFeatureFlags>()

  const { insightsStore } = useStore()
  const {
    isLoading,
    fetchAccountDetails,
    currentAccountDetails,
    fetchParticipatedMotions,
    currentParticipatedMotions,
    selectedDimension,
  } = insightsStore

  const navigate = useNavigate()
  const location = useLocation()

  const accountId = location.pathname.split('/')[2]
  const accountName = currentAccountDetails?.account_name ?? 'Loading...'
  /** This value can come back as "nan" or a number. */
  const userValue = Number.parseInt(currentAccountDetails?.user_count as string)
  const userCount = Number.isNaN(userValue) ? -1 : userValue

  useEffect(() => {
    fetchAccountDetails({ dimension: selectedDimension, id: accountId }).catch(console.error)
    fetchParticipatedMotions({ id: accountId }).catch(console.error)
  }, [Sandbox.isEnabled()])

  if (isLoading) {
    return (
      <Layout className='container'>
        <Content className='content account-details__loading-container' data-testid='loading-account-details'>
          <div className='account-details__btn-container'>
            <CloseButton shape='circle' onClick={() => navigate('/dashboard')}>
              <CloseOutlined />
            </CloseButton>
          </div>
          <Skeleton active />
        </Content>
      </Layout>
    )
  }

  return (
    <Layout className='container account-details'>
      <Content className='content' data-testid='account-details__content'>
        <AccountInformation name={accountName} numberOfUsers={userCount} />
        <div className='content__spacing'>
          <RevenueForcasting data={currentAccountDetails.usages.revenues} />
          <MachineLearningMetrics data={currentAccountDetails.events} />
          <ParticipatedMotions
            participatedMotions={currentParticipatedMotions}
            participatedMotionsColumns={
              actionsAcrossChannels
                ? [
                    ...participatedMotionsColumns,
                    {
                      title: 'Users',
                      dataIndex: 'users',
                      key: 'users',
                      className: 'participated-motions__table-users',
                    },
                    {
                      title: 'Actions',
                      dataIndex: 'actions',
                      key: 'actions',
                      className: 'participated-motions__table-actions',
                    },
                  ]
                : participatedMotionsColumns
            }
          />
          {actionsAcrossChannels && <GitHubDemo />}
        </div>
      </Content>
    </Layout>
  )
})
AccountDetails.displayName = 'AccountDetails'

export default AccountDetails
