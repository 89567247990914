import axios, { isAxiosError } from 'axios'

import { type DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import {
  demoHubspotEmailDropdown,
  sandboxHubspotEmailDropdown,
  sandboxZendeskPriorityDropdown,
  sandboxZendeskStatusDropdown,
  demoMarketoEmailCampaignDropdown,
  demoSalesforceForecastCategoryDropdown,
  demoSalesforceStageDropdown,
  demoSalesforceWhiteGloveDropdown,
  demoSalesforceStatusDropdown,
  demoSalesforcePriorityDropdown,
  demoGainsightCompanyDropdown,
  demoGainsightEntityTypeDropdown,
  demoGainsightPriorityDropdown,
  demoGainsightStatusDropdown,
  demoGainsightOwnerDropdown,
  demoSalesforceActiveDropdown,
  demoSlackFromDropdown,
  demoGainsightTypeDropdown,
} from 'api/mockResponses/actionDropdowns.mock'
import {
  demoGetDynamicInputsMetadataOptions,
  demoGetPlatformsMetadataDefault,
} from 'api/mockResponses/demo/metadata.mock'
import { getPlatformsMetadataDemoData } from 'api/mockResponses/demo/utils'
import { getBaseUrl } from 'api/utils'
import { skipSnowflakePlatformRequest } from 'components/common/Utils/constants'
import Demo from 'configs/demo'
import Sandbox from 'configs/sandbox'
import { LoggerService } from 'services/LogService/LogService'

import type {
  MetadataPlatform,
  MetadataPlatformResponse,
  MetadataRoot,
  MetadataSearch,
  MetadataSearchPayload,
  MetadataTypes,
} from 'models/metadata.model'
import type { DynamicInputsMetadataApiResponse } from 'models/motion/dynamicInput.model'
import type {
  AggregationDataResult,
  CreateActionFields,
  CreateActionFieldsGainsight,
  DataCreateActionFieldsDropdown,
  GainsightDropdownValue,
} from 'models/motion/motionBuilder.model'

/**
 * Fetch metadata from the API for a given platform, object and field for the current tenant.
 * When called without options, it will return all platforms for the current tenant.
 * @param {MetadataTypes} [options] The options
 * @param {boolean} [isAction] Used by `populatePickListValues` to return the correct format.
 * @returns {Connections | CoreApiError}
 */
export const get = async (
  options?: MetadataTypes & { type?: string },
  isAction?: boolean,
): Promise<MetadataPlatformResponse | MetadataRoot> => {
  try {
    const { platform, object, field, solutionInstanceId, action, type } = options || {}

    // demo and sandbox only return of hardcoded dropdown options until hubspot ingestion
    // is working and we have actual memoized data
    if (Demo.mockApiIsEnabled() || Sandbox.isEnabled()) {
      if (platform === 'hubspot' && object === 'Send_Transactional_Email' && field === 'emailId') {
        if (Demo.mockApiIsEnabled()) {
          return demoHubspotEmailDropdown as DataCreateActionFieldsDropdown
        }
        return sandboxHubspotEmailDropdown as DataCreateActionFieldsDropdown
      }

      if (Sandbox.isEnabled() && platform === 'zendesk' && object === 'Ticket') {
        if (field === 'priority') {
          return sandboxZendeskPriorityDropdown as DataCreateActionFieldsDropdown
        }
        if (field === 'status') {
          return sandboxZendeskStatusDropdown as DataCreateActionFieldsDropdown
        }
      }

      if (platform === 'marketo' && object === 'sendemail' && field === 'campaign_id') {
        return demoMarketoEmailCampaignDropdown as DataCreateActionFieldsDropdown
      }

      if (platform === 'salesforce' && object === 'Opportunity' && field === 'ForecastCategory') {
        return demoSalesforceForecastCategoryDropdown as DataCreateActionFieldsDropdown
      }
      if (platform === 'salesforce' && object === 'Opportunity' && field === 'StageName') {
        return demoSalesforceStageDropdown as DataCreateActionFieldsDropdown
      }
      if (platform === 'salesforce' && object === 'Opportunity' && field === 'White_Glove__c') {
        return demoSalesforceWhiteGloveDropdown as DataCreateActionFieldsDropdown
      }
      if (platform === 'salesforce' && object === 'Task' && field === 'Priority') {
        return demoSalesforcePriorityDropdown as DataCreateActionFieldsDropdown
      }
      if (platform === 'salesforce' && object === 'Task' && field === 'Status') {
        return demoSalesforceStatusDropdown as DataCreateActionFieldsDropdown
      }
      if (platform === 'salesforce' && object === 'Account' && field === 'Active__c') {
        return demoSalesforceActiveDropdown as DataCreateActionFieldsDropdown
      }

      if (
        platform === 'gainsight' &&
        object?.toLowerCase() === 'call_to_action' &&
        field?.toLowerCase() === 'company'
      ) {
        return demoGainsightCompanyDropdown as DataCreateActionFieldsDropdown
      }
      if (
        platform === 'gainsight' &&
        object?.toLowerCase() === 'call_to_action' &&
        field?.toLowerCase() === 'entity type'
      ) {
        return demoGainsightEntityTypeDropdown as DataCreateActionFieldsDropdown
      }
      if (platform === 'gainsight' && object?.toLowerCase() === 'call_to_action' && field?.toLowerCase() === 'owner') {
        return demoGainsightOwnerDropdown as DataCreateActionFieldsDropdown
      }
      if (
        platform === 'gainsight' &&
        object?.toLowerCase() === 'call_to_action' &&
        field?.toLowerCase() === 'priority'
      ) {
        return demoGainsightPriorityDropdown as DataCreateActionFieldsDropdown
      }
      if (platform === 'gainsight' && object?.toLowerCase() === 'call_to_action' && field?.toLowerCase() === 'status') {
        return demoGainsightStatusDropdown as DataCreateActionFieldsDropdown
      }
      if (platform === 'gainsight' && object?.toLowerCase() === 'call_to_action' && field?.toLowerCase() === 'type') {
        return demoGainsightTypeDropdown as DataCreateActionFieldsDropdown
      }

      if (
        platform === 'slack' &&
        (object === 'Message' || object === 'Channel') &&
        (field === 'from' || field === 'to')
      ) {
        return demoSlackFromDropdown as DataCreateActionFieldsDropdown
      }

      // Demo: Prevents API call when adding a new Gainsight or Pendo action
      if (Demo.mockApiIsEnabled() && action === 'create' && (platform === 'gainsight' || platform === 'pendo')) {
        return {
          data: {
            dropdownValues: [],
            key: '',
            value: '',
            type: '',
          },
        }
      }
    }

    const isCTAField =
      platform?.toLowerCase() === 'gainsight' &&
      object?.toLowerCase() === 'call_to_action' &&
      ['reasonid', 'priorityid', 'statusid', 'typeid'].includes(field?.toLowerCase() || '')

    let url = `${getBaseUrl('CORE_API')}/v1/core/metadata/platforms`
    if (platform && object && field) {
      url += `/${platform}/objects/${object}/fields/${field}/solutionInstanceId/${solutionInstanceId}`
    } else if (platform && object) {
      url += `/${platform}/objects/${object}/fields/solutionInstanceId/${solutionInstanceId}`
    } else if (platform) {
      url += `/${platform}/objects/solutionInstanceId/${solutionInstanceId}`
    } else {
      return getPlatforms(!!options?.action || isAction)
    }

    // Fetch the data
    const { data } = await axios.get<{ data: CreateActionFields[] }>(url, {
      params: {
        ...(platform && object && action && { action: action }),
        ...(platform && object && type && { type: type }),
      },
      demoData: getPlatformsMetadataDemoData(platform, object, field),
    } as DemoAxiosRequestConfig)

    // For Gainsight `type` field dropdowns
    if (isCTAField && isAction) {
      return processDropdownValues(data.data, field?.toLowerCase() === 'typeid')
    }

    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get metdata error', error })
    if (skipSnowflakePlatformRequest(options)) {
      /* [MAGPROD-1525] - skip throwing errors when trying to send bulk requests */
      if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
        throw new CoreApiError(error.response.data)
      } else {
        throw new Error('Failed to fetch metadata')
      }
    }
    return {} as MetadataPlatformResponse
  }
}

export const processDropdownValues = (
  data: CreateActionFields[] | CreateActionFieldsGainsight,
  isTypeField: boolean,
): DataCreateActionFieldsDropdown => {
  let apiResponseMapping: (CreateActionFields | GainsightDropdownValue)[] = []
  // This code is unoptimized to allow TypeScript to understand the resulting type.
  if (!Array.isArray(data)) {
    if ('dropdownValues' in data) {
      apiResponseMapping = data.dropdownValues
    }
  } else {
    apiResponseMapping = data
  }

  const dropdownValues = apiResponseMapping.map((item) => {
    let label
    // Extract the correct value from the correct key in a type safe way
    if ('label' in item) {
      label = item.label
    }

    return {
      ...item,
      label,
      ...(isTypeField ? { ctaTypeId: item.value, value: label } : { value: label }),
    }
  })

  const result: DataCreateActionFieldsDropdown = {
    data: {
      dropdownValues,
      // Added values to satisfy type requirements
      key: '',
      value: '',
      type: '',
    },
  }

  return result
}

/**
 * Fetch metadata from the API for all platforms for the current tenant.
 * @returns {Connections | CoreApiError}
 */
export const getPlatforms = async (isAction?: boolean) => {
  try {
    const { data } = await axios.get<{ data: MetadataPlatform[] }>(
      `${getBaseUrl('CORE_API')}/v1/core/metadata/platforms`,
      {
        params: {
          source: isAction ? 'action' : null,
        },
        demoData: demoGetPlatformsMetadataDefault,
      } as DemoAxiosRequestConfig,
    )

    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getPlatforms metdata error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch metadata')
    }
  }
}

export const search = async (payload: MetadataSearchPayload) => {
  try {
    const { data } = await axios.get<MetadataSearch>(`${getBaseUrl('CORE_API')}/v1/core/metadata/searchMetadata`, {
      params: payload,
    })
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'search metdata error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to search metadata')
    }
  }
}

/**
 * Fetch the dynamic inputs metadata for a given platform & object.
 * @param {MetadataTypes} params The platform & object combination to fetch dynamic inputs for.
 * @returns {DynamicInputsMetadataApiResponse} The dynamic inputs metadata for the given platform & object.
 */
export const getDynamicInputsMetadataOptions = async (
  params: MetadataTypes,
): Promise<DynamicInputsMetadataApiResponse> => {
  try {
    const { data } = await axios.get<DynamicInputsMetadataApiResponse>(
      `${getBaseUrl('CORE_API')}/v1/core/metadata/dynamicInputs`,
      {
        params,
        demoData: demoGetDynamicInputsMetadataOptions,
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get metdata dynamic inputs error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch metadata dynamic inputs')
    }
  }
}

export const getAggregations = async (options: MetadataTypes): Promise<AggregationDataResult> => {
  try {
    const { data } = await axios.get<AggregationDataResult>(`${getBaseUrl('CORE_API')}/v1/core/metadata/aggregations`, {
      params: options,
    })
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get metadata aggregations', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch metadata aggregations')
    }
  }
}
