import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { fetchAuthSession, fetchUserAttributes, fetchMFAPreference, getCurrentUser } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import { useEffect } from 'react'

import { API } from 'api/api'
import { checkForAuthTokenSetUser } from 'components/Auth/SignOut/SignOut'
import type { RoleEnum } from 'services/Utils/Role'
import type { UserStore } from 'store/user.store'
import useStore from 'store/useStore'

import type { Tenant } from 'models/tenant'
import type { CognitoSSOIdentity, User } from 'models/users.model'

export const initializePendo = (user: User) => {
  try {
    if (typeof window.pendo !== 'undefined') {
      window.pendo.initialize({
        visitor: {
          id: user.sub,
          email: user.email,
          username: user.username,
          full_name: user.username,
          tenantId: user.tenantId,
          tenantName: user.tenantName,
          role: user.role,
        },

        account: {
          id: user.tenantId,
          name: user.tenantName,
        },
      })
    }
  } catch (error: unknown) {
    console.error('Error initializing Pendo', error)
  }
}

export const setAuthListener = (userStore: UserStore) => {
  Hub.listen('auth', async (data) => {
    switch (data.payload.event) {
      case 'signedIn': {
        const attributes = await fetchUserAttributes()
        const session = await fetchAuthSession()
        let tenant: Tenant = { id: attributes['custom:tenantId'] || '', tenantName: '' } as Tenant
        try {
          tenant = await API.tenants.get(attributes['custom:tenantId'] || '')
        } catch (error: unknown) {
          console.error('Error fetching tenant', error)
          tenant = { id: attributes['custom:tenantId'] || '', tenantName: '' } as Tenant
        }
        userStore.setUser(
          {
            username: data.payload?.data?.username,
            email: attributes.email || '',
            roleId: attributes['custom:roleId'] as RoleEnum,
            name: String(session.tokens?.idToken?.payload['cognito:username']),
          },
          tenant,
        )
        break
      }
      case 'signedOut': {
        const hasToken = typeof localStorage.getItem('ADMIN_TOKEN') === 'string'
        // We have a token, but we need to manually add it to the store on refresh.
        if (hasToken) {
          checkForAuthTokenSetUser(userStore)
          return
        }
        userStore.reset()
        break
      }
      default:
        // https://docs.amplify.aws/javascript/build-a-backend/auth/auth-events/#listen-to-and-log-auth-events
        // console.warn(`Unknown data.payload.event for Hub.listen: "${data.payload.event}"`)
        break
    }
  })
}

export const checkUser = async (userStore: UserStore) => {
  try {
    const hasToken = typeof localStorage.getItem('ADMIN_TOKEN') === 'string'
    // We have a token, but we need to manually add it to the store on refresh.
    if (hasToken) {
      checkForAuthTokenSetUser(userStore)
      return
    }

    const user = await getCurrentUser()
    const username = user.username

    const attributes = await fetchUserAttributes()
    const email = attributes.email || ''
    const sub = attributes.sub
    const role = attributes['custom:roleId']
    const tenantId = attributes['custom:tenantId'] || ''

    // Fetch the actual Tenant information from the API
    let tenant: Tenant = { id: tenantId, tenantName: '' } as Tenant
    let tenantName = ''
    try {
      tenant = await API.tenants.get(tenantId)
      tenantName = tenant.tenantName
    } catch (error: unknown) {
      console.error('Error fetching tenant', error)
      tenant = { id: tenantId, tenantName: '' } as Tenant
    }

    let isSsoUser = false
    if (attributes.identities) {
      const identities = JSON.parse(attributes.identities) as CognitoSSOIdentity[]
      isSsoUser = identities.some((identity: CognitoSSOIdentity) => identity.providerType === 'SAML')
    }

    const session = await fetchAuthSession()
    const name = String(session.tokens?.idToken?.payload['cognito:username']) || ''

    const mfa = await fetchMFAPreference()

    if (import.meta.env.VITE_ENV !== 'local') {
      initializePendo({ sub, email, username, role, name, tenantId, tenantName })
    }

    // Announce the user to datadogRum
    // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#user-session
    try {
      datadogRum.setUser({
        id: sub,
        name,
        role,
        email,
        tenantId,
        tenantName,
      })
      datadogLogs.setUser({
        id: sub,
        name,
        role,
        email,
        tenantId,
        tenantName,
      })
    } catch (error: unknown) {
      console.error('Error setting datadogRum user', error)
    }

    userStore.setUser(
      {
        username,
        email,
        roleId: role as RoleEnum,
        name,
        mfaEnabled: !!mfa.enabled,
        isSsoUser,
      },
      tenant,
    )
  } catch (error: unknown) {
    console.warn('Error Setting User', error)
    userStore.setUser({ username: null, email: null })
  }
}

export const AuthListener = () => {
  const { userStore } = useStore()

  useEffect(() => {
    setAuthListener(userStore)
    checkUser(userStore).catch(console.warn)
  }, [])

  return <span></span>
}
AuthListener.displayName = 'AuthListener'
