import { renderToString } from 'react-dom/server'

import { Tag } from 'components/common'
import { DIV_NODE_TYPE, DYNAMIC_INPUT, TEXT_NODE_TYPE } from 'components/common/Utils/constants'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'
import { isDayjsObject } from 'utils/type-helpers'

import type { Dayjs } from 'dayjs'

import type { EmailTemplate } from 'models/email-templates.model'
import type { DynamicInputPosition } from 'models/motion/dynamicInput.model'
import { type Item, type CreateActionFields, InputOperatorEnum } from 'models/motion/motionBuilder.model'
import type { CreateSlackMessagePayload } from 'models/slack-messages'

export type AcceptedValues =
  | boolean
  | number
  | number[]
  | string
  | string[]
  | Dayjs
  | Dayjs[]
  | CreateActionFields
  | CreateSlackMessagePayload
  | null

export const DynamicInputRegex = {
  textAfter: /\${dynamicInput}(.+)/,
  textBefore: /.+(\${dynamicInput})/,
  spaceBefore: /\s(\${dynamicInput})/,
  textAndSpaceBefore: /.+(\s\${dynamicInput})/,
  spaceAfter: /(\${dynamicInput})\s/,
  spaceAndTextAfter: /\${dynamicInput}\s(.+)/,
}

const DATE_ONLY_FORMAT = 'YYYY-MM-DD'

/**
 * Get the caret position of the input
 * @param setDynamicInputPosition The function to set the dynamic input position
 * @param item The item to get the caret position from
 */
export const getCaretPosition = (
  setDynamicInputPosition: (value?: DynamicInputPosition) => void,
  item: Item | CreateActionFields,
) => {
  if (window.getSelection()?.rangeCount !== 0) {
    const range = window.getSelection()?.getRangeAt(0)
    const selectedObj = window.getSelection()
    let rangeCount = 0
    if (selectedObj?.anchorNode?.parentNode?.childNodes) {
      const childNodes = selectedObj.anchorNode.parentNode.childNodes
      for (let i = 0; i < childNodes.length; i++) {
        if (childNodes[i] === selectedObj.anchorNode) {
          break
        }
        if ((childNodes[i] as Element).outerHTML) rangeCount += (childNodes[i] as Element).outerHTML.length
        else if (childNodes[i].nodeType === TEXT_NODE_TYPE) {
          rangeCount += childNodes[i].textContent?.length || 0
        }
      }
    }

    setDynamicInputPosition({ key: item.key, position: (range?.startOffset || 0) + rangeCount })

    return
  }

  setDynamicInputPosition()
}

/**
 * Get the thumbnail URL for an email template
 * @param payloadFields The payload fields from the action
 * @param getTemplate The function to get the email template
 * @returns The thumbnail URL for the email template
 */
export const getEmailTemplateThumbnail = async (
  payloadFields: CreateActionFields[] | undefined,
  getTemplate: ({
    magnifyTemplateId,
    magnifyVersion,
  }: {
    magnifyTemplateId: string
    magnifyVersion: number
  }) => Promise<EmailTemplate | undefined>,
) => {
  const templateId = String(payloadFields?.find((field) => field.key === 'templateId')?.value) || null
  const templateVersion = Number(payloadFields?.find((field) => field.key === 'templateVersion')?.value) || null

  if (!templateId || !templateVersion) return

  const existingTemplate = await getTemplate({
    magnifyTemplateId: templateId,
    magnifyVersion: templateVersion,
  })

  if (!existingTemplate || !existingTemplate.thumbnailUrl) return

  return existingTemplate?.thumbnailUrl
}

export const isSelectValue = (item: Item | CreateActionFields) => {
  const isSelectValue = [InputOperatorEnum.NotInclude, InputOperatorEnum.Include, InputOperatorEnum.Between].some(
    (operator) => (item as Item)?.operator === operator,
  )
  return isSelectValue || item.type === 'boolean'
}

/**
 * Dynamic Input handler for blur events
 * @param {React.FocusEvent<HTMLElement>} event The blur event
 */
export const onBlur =
  (
    item: Item | CreateActionFields,
    changeFieldValue: (
      value: boolean | number | number[] | string | string[] | Dayjs | Dayjs[] | CreateActionFields | null,
      fieldKey: string | undefined,
      index?: number,
    ) => void,
    isTagDeleted: boolean,
  ) =>
  (event: React.FocusEvent<HTMLElement>) => {
    let dynamicValue = ''
    const element = event.target as HTMLElement
    element.childNodes.forEach((element) => {
      switch (element.nodeType) {
        case DIV_NODE_TYPE:
          /* Add dynamic input variable only if it was selected and restrict to only one per input */
          if ((item.value as CreateActionFields)?.platform && !dynamicValue.includes(DYNAMIC_INPUT) && !isTagDeleted) {
            dynamicValue += DYNAMIC_INPUT
          }
          break
        case TEXT_NODE_TYPE:
          dynamicValue += element.textContent
          break
        default:
          dynamicValue += ''
          break
      }
    })

    if (dynamicValue === item.value || dynamicValue === (item.value as CreateActionFields)?.value) {
      return
    }

    if ((item.value as CreateActionFields)?.platform && dynamicValue.includes(DYNAMIC_INPUT) && !isTagDeleted) {
      if (
        DynamicInputRegex.spaceBefore.test(dynamicValue) &&
        !DynamicInputRegex.textAndSpaceBefore.test(dynamicValue)
      ) {
        dynamicValue = dynamicValue.replace(DynamicInputRegex.spaceBefore, DYNAMIC_INPUT)
      }
      if (DynamicInputRegex.spaceAfter.test(dynamicValue) && !DynamicInputRegex.spaceAndTextAfter.test(dynamicValue)) {
        dynamicValue = dynamicValue.replace(DynamicInputRegex.spaceAfter, DYNAMIC_INPUT)
      }

      changeFieldValue(
        {
          ...(item.value as CreateActionFields),
          value: dynamicValue,
        },
        item?.key,
      )
    } else {
      changeFieldValue(dynamicValue, item?.key)
    }
  }

/**
 * Dynamic Input handler for click events
 * @param {React.MouseEvent<HTMLElement>} event The click event
 */
export const onClick =
  (removeTag: () => void, openDynamicFieldPopup: (value: boolean) => void) =>
  (event: React.MouseEvent<HTMLElement>) => {
    const element = event.target as HTMLElement
    switch (element.nodeName) {
      case 'svg':
      case 'path':
        removeTag?.()
        break
      case 'DIV':
      case 'SPAN': {
        if (element.classList.contains('tag-field') || element.classList.contains('tag-field__name')) {
          openDynamicFieldPopup?.(true)
        } else if (element.classList.contains('remove-btn')) {
          removeTag?.()
        }
        break
      }
      case 'IMG':
        openDynamicFieldPopup?.(true)
        break
      default:
        break
    }
  }

/**
 * Dynamic Input handler for key press events
 * @param {React.KeyboardEvent<HTMLInputElement>} event The key press event
 */
export const onKeyPress =
  (item: Item | CreateActionFields, setIsTagDeleted: (value: boolean) => void) =>
  (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      return false
    }

    /* Check if the tag was deleted by backspace */
    if (event.key === 'Backspace' && (item.value as CreateActionFields)?.platform) {
      let isTagVisible
      const element = event.target as HTMLElement

      element.childNodes.forEach((element) => {
        if (element.nodeType === DIV_NODE_TYPE) {
          isTagVisible = true
        }
      })

      if (!isTagVisible) {
        setIsTagDeleted(!!isTagVisible)
      }
    }
  }

export const buildDynamicInputHTML = (
  item: Item | CreateActionFields,
  setHTML: (value: React.SetStateAction<string>) => void,
  openDynamicFieldPopup: (value: boolean) => void,
  removeTag: () => void,
) => {
  if (!(item.value as CreateActionFields)?.platform) {
    setHTML((item.value as string) || '')
  } else if (item.value && (item.value as CreateActionFields)?.platform) {
    const value = (item.value as CreateActionFields)?.value ?? ''
    const hasTextBefore = DynamicInputRegex.textBefore.test(value as string)
    const hasTextAfter = DynamicInputRegex.textAfter.test(value as string)
    const inputValue = (value as string).replace(
      DYNAMIC_INPUT,
      renderToString(
        <>
          {!hasTextBefore && <>&nbsp;</>}
          <Tag
            icon={
              getMenuItemIcon({
                name: (item.value as CreateActionFields).platform,
                entityType: 'platform',
              }) as React.ReactElement
            }
            text={(item.value as CreateActionFields)?.field || ''}
            title={`${(item.value as CreateActionFields)?.object}/${(item.value as CreateActionFields)?.field}`}
            openPopover={openDynamicFieldPopup}
            removeTag={removeTag}
          />
          {!hasTextAfter && <>&nbsp;</>}
        </>,
      ),
    )
    setHTML(inputValue)
  }
}

export const updateValueByType =
  (
    changeFieldValue: (value: AcceptedValues, fieldKey: string | undefined, index?: number) => void,
    item: Item | CreateActionFields,
  ) =>
  (inputType: string, newVal: AcceptedValues): void => {
    switch (inputType) {
      case 'num': {
        if (typeof newVal !== 'number') {
          console.error(
            `Invalid type passed to updateValue with inputType of '${inputType}': expecting a number`,
            newVal,
          )
        }
        changeFieldValue(newVal as number, item?.key)
        break
      }

      case 'html':
      case 'select': {
        // Select input types can also have booleans as their options
        if (typeof newVal !== 'string' && typeof newVal !== 'boolean') {
          console.error(
            `Invalid type passed to updateValue with inputType of '${inputType}': expecting a string or boolean`,
            newVal,
          )
        }
        changeFieldValue(newVal as string | boolean, item?.key)
        break
      }

      case 'str': {
        if (typeof newVal !== 'string') {
          console.error(
            `Invalid type passed to updateValue with inputType of '${inputType}': expecting a string`,
            newVal,
          )
        }
        changeFieldValue(newVal as string, item?.key)
        break
      }

      case 'num_min': {
        if (typeof newVal !== 'string') {
          console.error(
            `Invalid type passed to updateValue with inputType of '${inputType}' expecting a string:`,
            newVal,
          )
        }
        changeFieldValue(newVal as string, item?.key, 0)
        break
      }

      case 'num_max': {
        if (typeof newVal !== 'string') {
          console.error(
            `Invalid type passed to updateValue with inputType of '${inputType}' expecting a string:`,
            newVal,
          )
        }
        changeFieldValue(newVal as string, item?.key, 1)
        break
      }

      case 'date': {
        // MAGPROD-538 - only pass date format downstream to orchestrator
        if (!isDayjsObject(newVal)) {
          console.error(
            `Invalid type passed to updateValue with inputType of '${inputType}' expecting a Dayjs:`,
            newVal,
          )
        }
        if (newVal) {
          changeFieldValue((newVal as Dayjs).format(DATE_ONLY_FORMAT), item?.key)
        } else {
          changeFieldValue(null, item?.key)
        }
        break
      }

      case 'date_range': {
        // MAGPROD-538 - only pass date format downstream to orchestrator
        if (newVal) {
          if (!Array.isArray(newVal) || (Array.isArray(newVal) && !newVal.every(isDayjsObject))) {
            console.error(
              `Invalid type passed to updateValue with inputType of '${inputType}' expecting a Dayjs[]:`,
              newVal,
            )
          }
          const formattedDates = (newVal as Dayjs[])?.map((x: Dayjs) => x.format(DATE_ONLY_FORMAT))
          changeFieldValue(formattedDates, item.key)
        } else {
          changeFieldValue(null, item?.key)
        }
        break
      }

      default:
        console.warn(`Unknown updateValue inputType '${inputType}' with value:`, newVal)
        break
    }
  }
