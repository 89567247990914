import { Select } from 'antd'

import { capitalizeFirstLetter } from 'services/Utils/parseString.utils'

import type { ColumnsType } from 'antd/es/table'

import type {
  ChartData,
  Driver,
  EventTrend,
  Events,
  MLMetricContent,
  MachineLearningMetricsTable,
  MlMetricCategory,
} from 'models/account.model'

export const CHART_FILTER_INTERVAL = 4

export const getChartFilterOptions = (data: number[]) => {
  const chartDataCount = data.length

  const options = []
  for (let i = 1; i <= chartDataCount; i++) {
    if (i % CHART_FILTER_INTERVAL === 0) {
      options.push(
        <Select.Option key={i} value={i}>
          Last {i} weeks
        </Select.Option>,
      )
    }
  }
  return options
}

export const filterChartData = (chartData: ChartData, chartTimeRangeValue: number) => {
  const chartDataCount = chartData.data.length
  if (chartDataCount <= CHART_FILTER_INTERVAL) {
    return { filteredLabels: chartData.labels, filteredData: chartData.data }
  }
  const filteredChartData = chartData.data.slice(chartDataCount - chartTimeRangeValue, chartDataCount)
  const filteredChartLabels = chartData.labels.slice(chartDataCount - chartTimeRangeValue, chartDataCount)
  return { filteredLabels: filteredChartLabels, filteredData: filteredChartData }
}

export const getMlMetricContent = (
  data: Events,
  mlMetricData: Events,
  redirectToAccountDetails: (factorName: any, driverId: any) => void,
) => {
  return Object.keys(mlMetricData).map((key: string, index: number) => {
    const mlMetricKey = key as MlMetricCategory
    if (!data[mlMetricKey]) {
      return {
        id: index + 1,
        chartData: { labels: [], data: [], title: '' },
        tableColumns: [],
        tableData: [],
      } as MLMetricContent
    }

    const chartData = {
      title: capitalizeFirstLetter(mlMetricKey),
      labels: data[mlMetricKey]?.trend.map((item: EventTrend) => item.label),
      data: data[mlMetricKey]?.trend.map((item: EventTrend) => item.value * 100),
      isPercentage: true,
    }

    const columnFactorTitle = capitalizeFirstLetter(mlMetricKey)

    const tableColumns: ColumnsType<MachineLearningMetricsTable> = [
      {
        title: 'Rank',
        dataIndex: 'rank',
        key: 'rank',
        width: '15%',
        render: (_, { rank }) => <p className='ml-metrics-tab-content-table-rank'>{rank}</p>,
      },
      {
        title: `${columnFactorTitle} Driver`,
        dataIndex: 'factor',
        key: 'factor',
        render: (_, { driverId, factor }) => {
          const { field, familyObj, metrics } = factor
          const churnFactorString = Object.keys(metrics)
            .map((metricKey: string) => {
              return `${field} from ${familyObj} has a ${metricKey} of ${metrics[metricKey].improve_from_text}`
            })
            .join(' AND ')

          return (
            <p
              className='ml-metrics-tab-content-table-churn-factor'
              onClick={() => redirectToAccountDetails(columnFactorTitle.toLowerCase(), driverId)}>
              {churnFactorString}
            </p>
          )
        },
      },
    ]

    const tableData: MachineLearningMetricsTable[] | undefined = data[mlMetricKey]?.drivers.map(
      (driver: Driver, index: number) => {
        const driverRank = index + 1
        return {
          key: driverRank,
          driverId: driver.driver_id,
          rank: '#' + driverRank,
          factor: {
            platform: driver.family.platform,
            field: driver.family.field,
            familyObj: driver.family.object,
            metrics: driver.metrics,
          },
          factorProbability: driver.segment,
        } as MachineLearningMetricsTable
      },
    )

    return {
      id: index + 1,
      chartData: chartData,
      tableColumns: tableColumns,
      tableData: tableData,
    } as MLMetricContent
  })
}
