import { Select, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'

import { DropdownArrowDown, IconSearch } from 'components/common/Icons/Icons'
import {
  criteriaItemKey,
  filteredSelectOptions,
  setLabelIfEmpty,
} from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/SingleSelect/utils'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import { isVowel } from 'services/Utils/parseString.utils'
import useStore from 'store/useStore'

import type { Dayjs } from 'dayjs'

import type { CreateActionFields, ExtendedSelectOptions, Item, SelectOptions } from 'models/motion/motionBuilder.model'
import type { CreateSlackMessagePayload } from 'models/slack-messages'

interface SingleSelectProps {
  item: Item | CreateActionFields
  autoFocus?: boolean
  defaultOptions?: SelectOptions[] | ExtendedSelectOptions[]
  disabled?: boolean
  isAction?: boolean
  updateValue: (
    inputType: string,
    newVal: number | string | boolean | Dayjs | Dayjs[] | CreateSlackMessagePayload | null,
  ) => void
  isSearchEnabled?: boolean
  dataTestId?: string
}

export const SingleSelect = observer(
  ({
    item,
    autoFocus,
    defaultOptions,
    disabled,
    updateValue,
    isAction,
    isSearchEnabled = true,
    dataTestId,
  }: SingleSelectProps) => {
    const { metadataStore } = useStore()
    const { criteriaSelect, loadCriteriaInputOptions } = metadataStore
    const [dropdownOptions, setDropdownOptions] = useState<ExtendedSelectOptions[]>([])
    const [dropdownIcon, setDropdownIcon] = useState<React.ReactElement>(<DropdownArrowDown />)

    useMetadataDisplayErrorNotification(metadataStore)

    useEffect(() => {
      const options: ExtendedSelectOptions[] = defaultOptions?.length
        ? defaultOptions
        : ((filteredSelectOptions(
            item,
            criteriaSelect as unknown as { options: Map<string, CreateActionFields[]> },
            isAction,
          ) as unknown as ExtendedSelectOptions[]) ?? [])

      setDropdownOptions(setLabelIfEmpty(options))
    }, [defaultOptions, item, JSON.stringify([...criteriaSelect.options.entries()])])

    const handleOnFocus = () => {
      setDropdownIcon(<IconSearch />)

      if (!dropdownOptions?.length) {
        loadCriteriaInputOptions(item, criteriaItemKey({ item, isAction }), isAction).catch(console.error)
      }
    }

    const handleOnBlur = () => {
      setDropdownIcon(<DropdownArrowDown />)
    }

    // If we have a default value and no value, use the default value.
    let value = item.displayValue || item.value
    if (!value && item.defaultValue !== undefined) {
      value = item.defaultValue as string
    }

    return (
      <Select
        size='large'
        className='criteria-input_select_right'
        popupClassName='group-container__item-container__item__select-input-range__dropdown'
        suffixIcon={dropdownIcon}
        placeholder={`Select ${'name' in item ? `${isVowel(item.name) ? 'an' : 'a'} ${item.name}` : 'an option'}`}
        {...(disabled && { disabled: disabled })}
        showSearch={isSearchEnabled}
        optionFilterProp='children'
        filterOption={(input, option) => {
          const optionLabel = option?.children?.toString().toLowerCase() ?? ''
          return optionLabel.indexOf(input.toLowerCase()) >= 0
        }}
        onChange={(e) => updateValue('select', e as unknown as string | boolean)}
        value={value}
        notFoundContent={criteriaSelect.isLoading ? <Spin /> : <p>No results found</p>}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onSelect={handleOnBlur}
        defaultOpen={autoFocus}
        autoFocus={autoFocus}
        data-testid={dataTestId || 'single-select'}>
        {dropdownOptions?.length &&
          dropdownOptions.map((option, index: number) => (
            <Select.Option key={index} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
      </Select>
    )
  },
)
SingleSelect.displayName = 'SingleSelect'
