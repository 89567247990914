import React from 'react'

import type { variant } from 'models'

export type headingLvl = '1' | '2' | '3' | '4' | '5' | '6'

interface Props {
  level: headingLvl
  variant: variant
  className?: string
  children: React.JSX.Element | string | string[]
}

export const Heading = ({ level, variant, className, children }: Props) => {
  const Element = level !== '6' ? (`h${level}` as keyof React.JSX.IntrinsicElements) : 'p'

  return (
    <Element
      className={className ? `c-heading c-heading--${variant} ${className}` : `c-heading c-heading--${variant}`}
      data-testid='heading'>
      {children}
    </Element>
  )
}
