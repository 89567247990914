import axios, { isAxiosError } from 'axios'

import { type DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import {
  demoGetAllMotions,
  demoGetMotionExecutionStats,
  demoGetMotions,
  demoSegmentExportResponse,
} from 'api/mockResponses/demo/motions.mock'
import { getBaseUrl } from 'api/utils'
import { LoggerService } from 'services/LogService/LogService'
import { generateWAFErrorMessage, isWAFError } from 'utils/waf-error'

import type { SegmentExportData } from 'models/motion/motionBuilder.model'
import type {
  BaseMotion,
  InternalMotionExecutionOperationalStats,
  Motion,
  MotionExecute,
  MotionIdentifiers,
  MotionsApiResponse,
  MotionSchedule,
  SegmentExport,
  SendEmailPayload,
  SendTestMessagePayload,
} from 'models/motion.model'

export const getAll = async (limit?: number, offset?: number, search?: string) => {
  try {
    const { data } = await axios.get<MotionsApiResponse>(`${getBaseUrl('CORE_API')}/v1/core/journeys`, {
      params: {
        limit,
        offset,
        search,
      },
      demoData: demoGetAllMotions,
    } as DemoAxiosRequestConfig)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getAll Motions error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch Motions')
    }
  }
}

export const get = async (motion: MotionIdentifiers) => {
  const { playbookId, version } = motion
  try {
    const { data } = await axios.get<{ data: Motion }>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/${playbookId}/${version}`,
      {
        demoData: demoGetMotions.find(
          (motion) => motion.data.playbookId === playbookId && motion.data.version === version,
        ),
      } as DemoAxiosRequestConfig,
    )

    return data.data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get Motion error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch Motion')
    }
  }
}

export const update = async (motion: MotionExecute, isEditSegmentWhilstExecuting: boolean): Promise<Motion> => {
  try {
    const requestBody = {
      ...motion,
      isEditSegment: isEditSegmentWhilstExecuting,
    }

    const { data } = await axios.put<Motion>(`${getBaseUrl('CORE_API')}/v1/core/journeys`, requestBody)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'update error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      // Check for Amazon WAF errors to at least show an error message.
      if (isWAFError(error)) {
        throw new CoreApiError(
          generateWAFErrorMessage({
            instance: '/v1/core/journeys',
            message: 'Failed to update Motion',
          }),
        )
      } else {
        throw new CoreApiError(error.response.data ?? error.message)
      }
    } else {
      throw new Error('Failed to update Motion')
    }
  }
}

export const archive = async (motion: MotionIdentifiers) => {
  try {
    return await axios.put<any>(`${getBaseUrl('CORE_API')}/v1/core/journeys/archive`, motion)
  } catch (error: unknown) {
    LoggerService.error({ message: 'archive Motion error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to archive Motion')
    }
  }
}

export const clone = async (motion: MotionIdentifiers) => {
  try {
    return await axios.post<any>(`${getBaseUrl('CORE_API')}/v1/core/journeys/clone`, motion)
  } catch (error: unknown) {
    LoggerService.error({ message: 'clone Motion error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to clone Motion')
    }
  }
}

export const executeNow = async (motion: MotionIdentifiers) => {
  try {
    return await axios.post<any>(`${getBaseUrl('CORE_API')}/v1/core/journeys/schedule/execute`, motion)
  } catch (error: unknown) {
    LoggerService.error({ message: 'execute Motion error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to execute the Motion')
    }
  }
}

export const cancel = async (motion: MotionIdentifiers) => {
  try {
    return await axios.post<any>(`${getBaseUrl('CORE_API')}/v1/core/journeys/stop`, motion)
  } catch (error: unknown) {
    LoggerService.error({ message: 'cancel Motion error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to cancel Motion')
    }
  }
}

export const post = async (motion: BaseMotion): Promise<Motion> => {
  try {
    const { data } = await axios.post<Motion>(`${getBaseUrl('CORE_API')}/v1/core/journeys/`, motion)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'createMotion error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      // Check for Amazon WAF errors to at least show an error message.
      if (isWAFError(error)) {
        throw new CoreApiError(
          generateWAFErrorMessage({
            instance: '/v1/core/journeys',
            message: 'Failed to create Motion',
          }),
        )
      } else {
        throw new CoreApiError(error.response.data ?? error.message)
      }
    } else {
      throw new Error('Failed to create Motion')
    }
  }
}

export const execute = async (motion: MotionExecute) => {
  try {
    return await axios.post<any>(`${getBaseUrl('CORE_API')}/v1/core/journeys/execute`, motion)
  } catch (error: unknown) {
    LoggerService.error({ message: 'execute Motion error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to execute Motion')
    }
  }
}

export const deleteMotionSchedule = async (motion: Motion) => {
  try {
    const requestBody = { journeyId: motion.playbookId, version: motion.version.toString() }
    return await axios.delete<any>(`${getBaseUrl('CORE_API')}/v1/core/journeys/schedule`, { data: requestBody })
  } catch (error: unknown) {
    LoggerService.error({ message: 'delete Motion schedule error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to delete Motion schedule')
    }
  }
}

export const createOrUpdateMotionSchedule = async (scheduleData: MotionSchedule): Promise<string | null> => {
  try {
    const scheduleResponse = await axios.post<{ message: string }>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/schedule`,
      scheduleData,
    )

    return scheduleResponse.data.message
  } catch (error: unknown) {
    LoggerService.error({ message: 'createOrUpdateMotionSchedule error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to create/update Motion schedule')
    }
  }
}

export interface SegmentOutputResponse {
  message: string
  segmentExports: SegmentExport[]
  status: number
}
export const generateSegmentOutput = async (
  segmentExportData: SegmentExportData,
): Promise<{ message: string; segmentExports: SegmentExport[]; status: number }> => {
  try {
    const response = await axios.post<SegmentOutputResponse>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/segmentoutput/export`,
      segmentExportData,
      {
        demoData: demoSegmentExportResponse,
      } as DemoAxiosRequestConfig,
    )

    return { message: response.data.message, segmentExports: response.data.segmentExports, status: response.status }
  } catch (error: unknown) {
    LoggerService.error({ message: 'genrerateSegentOutput error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      const errResponse = error.response?.data
      const message = errResponse ? errResponse.message : 'Error occurred'
      const segmentExports: SegmentExport[] = []
      const status = error.response?.status || 500
      return { message, segmentExports, status }
    } else {
      return { message: (error as Error).message, segmentExports: [], status: 500 }
    }
  }
}

/**
 * ⚠ This information is exclusively intended for INTERNAL USE and is utilized solely for debugging purposes. There are not any client-side links that point to the page that displays this information.
 * Get the execution details of a Motion
 * @param motion The Motion that needs the stats
 * @returns The execution details of a Motion
 */
export const getMotionExecutionStats = async (motion: MotionIdentifiers, limit: number, offset: number) => {
  const { playbookId, version } = motion
  try {
    const { data } = await axios.get<InternalMotionExecutionOperationalStats>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/${playbookId}/${version}/statistics`,
      {
        params: {
          limit,
          offset,
        },
        demoData: demoGetMotionExecutionStats,
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getMotionExecutionStats error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch Motion execution statistics')
    }
  }
}

/**
 * Send a test email through the core API using the internal SendGrid account.
 * @param {SendEmailPayload} payload The payload to send a test email.
 * @returns The response from the server & status code.
 */
export const sendTestEmail = async (payload: SendEmailPayload): Promise<{ message: string; status: number }> => {
  try {
    const response = await axios.post<{ message: string; status: number }>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/sendTestEmail`,
      payload,
    )

    return { message: response.data.message, status: response.status }
  } catch (error: unknown) {
    LoggerService.error({ message: 'sendTestEmail error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to send test email')
    }
  }
}

/**
 * Send a test message through the core API.
 * @param {SendTestMessagePayload} payload The payload to send a test message.
 * @returns The response from the server & status code.
 */
export const sendTestMessage = async (
  payload: SendTestMessagePayload,
): Promise<{ message: string; status: number }> => {
  try {
    const response = await axios.post<{ message: string; status: number }>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/send-test-slack-message`,
      payload,
    )

    return { message: response.data.message, status: response.status }
  } catch (error: unknown) {
    LoggerService.error({ message: 'sendTestMessage error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to send test message')
    }
  }
}

export const getSegmentExports = async (
  motionId: string,
  version: number,
): Promise<{ segmentExports: SegmentExport[] }> => {
  try {
    const response = await axios.get<{ segmentExports: SegmentExport[] }>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/${motionId}/${version}/segment-exports`,
    )

    return response.data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get segment exports error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to get segment exports')
    }
  }
}
