import { DatePicker, Input, InputNumber } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import ContentEditable from 'react-contenteditable'

import { Button, STRING_TYPE, checkTypeEquality } from 'components/common'
import { DropdownArrowDown, IconBolt } from 'components/common/Icons/Icons'
import { MultipleSelect } from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/MultipleSelect/MultipleSelect'
import MultiValueInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/MultiValueInput'
import RichText from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/RichText'
import { SingleSelect } from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/SingleSelect/SingleSelect'
import type { AcceptedValues } from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/utils'
import {
  getEmailTemplateThumbnail,
  isSelectValue,
  onBlur,
  onClick,
  onKeyPress,
  getCaretPosition,
  buildDynamicInputHTML,
  updateValueByType,
} from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/utils'
import { onPasteReturnRawText } from 'components/MotionBuilder/Utils/event.utils'
import useInputType from 'components/MotionBuilder/Utils/useInputType'
import EmailTemplateModal from 'pages/Motions/EmailTemplateModal'
import useStore from 'store/useStore'

import type { Dayjs } from 'dayjs'

import { FieldTypeEnum } from 'models/metadata.model'
import type { CreateActionFields, ExtendedSelectOptions, Item, SelectOptions } from 'models/motion/motionBuilder.model'
import { MotionStateEnum } from 'models/motion.model'

const { RangePicker } = DatePicker

export interface FieldInputProps {
  item: CreateActionFields | Item
  /** Whether the input is a range input like `between`. */
  range: boolean
  /** Whether the input is focused. */
  autoFocus?: boolean
  /** Whether the input is an action. */
  isAction?: boolean
  /** The default options for the input. */
  defaultOptions?: SelectOptions[] | ExtendedSelectOptions[]
  /** The placeholder for the input. */
  placeholder?: string
  /** Whether the input is disabled. */
  disabled?: boolean
  /** The payload fields for the input. */
  payloadFields?: CreateActionFields[]
  /** The data test id for the input. */
  dataTestId?: string
  /** Sets the focus on the input. */
  setFocusOnInput?: (focus: string) => void
  /** Changes the value of the input. */
  changeFieldValue: (value: AcceptedValues, fieldKey: string | undefined, index?: number) => void
  /** Whether the input is enabled for dynamic input. */
  enableDynamicInput?: boolean
  /** The dynamic field popover for the input. */
  displayDynamicFieldPopover?: boolean
  /** Opens the dynamic field popup. */
  openDynamicFieldPopup?: (value: boolean) => void
  /** Whether the input is a dynamic field. */
  isDynamicField?: boolean
  /** Removes the tag. */
  removeTag?: () => void
  /** Whether the input is borderless. */
  borderless?: boolean
}

const FieldInput = observer(
  ({
    item,
    range,
    autoFocus,
    isAction,
    defaultOptions,
    placeholder,
    disabled,
    payloadFields,
    dataTestId,
    setFocusOnInput,
    changeFieldValue,
    enableDynamicInput,
    displayDynamicFieldPopover,
    openDynamicFieldPopup,
    isDynamicField,
    removeTag,
    borderless,
  }: FieldInputProps) => {
    /** Normal input specific, the email thumbnail URL. */
    const [emailThumbnailUrl, setEmailThumbnailUrl] = useState('')
    /** Normal input specific, the show email modal. */
    const [showEmailModal, setShowEmailModal] = useState(false)

    /** Shared, whether the input is a multiple input or nullish. */
    const { isMultipleInput, isNullishInput } = useInputType(item)

    /** Dynamic Input specific, the HTML value of the input. */
    const [html, setHTML] = useState('')
    /** Dynamic Input specific, whether the tag has been deleted. */
    const [isTagDeleted, setIsTagDeleted] = useState<boolean>(false)

    const { dynamicInputStore, emailTemplatesStore, motionStore } = useStore()
    const { getTemplate } = emailTemplatesStore
    const { currentMotion } = motionStore
    const { setDynamicInputPosition } = dynamicInputStore

    /** Get the email thumbnail URL when the payload fields change and is an HTML field but not a Slack field. */
    useEffect(() => {
      if (item.type?.toLowerCase() !== FieldTypeEnum.HTML || item.platform === 'slack') {
        return
      }
      getEmailTemplateThumbnail(payloadFields, getTemplate)
        .then((emailThumbnailUrl) => {
          setEmailThumbnailUrl(emailThumbnailUrl || '')
        })
        .catch(console.error)
    }, [payloadFields])

    /** Get the HTML value when the value changes and is a dynamic input field. */
    useEffect(() => {
      if (!isDynamicField) {
        return
      }
      buildDynamicInputHTML(item, setHTML, openDynamicFieldPopup || (() => {}), removeTag || (() => {}))
    }, [isDynamicField, (item.value as CreateActionFields)?.value, item.value])

    /** Change the value of the input when the operator changes. */
    useEffect(() => {
      if (isNullishInput) {
        return changeFieldValue(true, item?.key)
      }

      if (Array.isArray(item?.value) && !isMultipleInput && !isSelectValue(item)) {
        return changeFieldValue('', item?.key)
      }

      if (!Array.isArray(item?.value) && isMultipleInput && isSelectValue(item)) {
        return changeFieldValue([], item?.key)
      }

      if (typeof item?.value === 'boolean' && !isNullishInput && !isSelectValue(item)) {
        return changeFieldValue('', item?.key)
      }
    }, [(item as Item).operator])

    /** The default attributes for input components */
    const inputAttributes = {
      autoFocus: autoFocus,
      placeholder: placeholder,
      disabled: disabled || motionStore.isSegmentBuilderEditDisabled,
      onBlur: () => setFocusOnInput?.(''),
    }

    /** The default attributes for select components */
    const selectInputAttributes = {
      defaultOpen: autoFocus,
      autoFocus: autoFocus,
      disabled: disabled || motionStore.isSegmentBuilderEditDisabled,
      onBlur: () => setFocusOnInput?.(''),
    }

    /** The default attributes for multiple select components */
    const multipleSelectAttributes = {
      autoFocus: autoFocus,
      defaultOptions: defaultOptions,
      disabled: disabled || motionStore.isSegmentBuilderEditDisabled,
      setFocusOnInput: setFocusOnInput,
      isAction: isAction,
    }

    /** The key for the input component and any sub-components */
    const key = `${item.platform}-${item.object}-${item.operator}`

    /** The input component to render */
    let inputComponent: React.ReactNode | null = null

    /** The function to update the value of the input based on the type of the input */
    const updateValue = updateValueByType(changeFieldValue, item)

    switch (item.type?.toLowerCase()) {
      case FieldTypeEnum.Int:
      case FieldTypeEnum.Integer:
      case FieldTypeEnum.Number:
      case FieldTypeEnum.Currency:
      case FieldTypeEnum.Double:
      case FieldTypeEnum.Decimal:
      case FieldTypeEnum.Percent: {
        if (!range && !isMultipleInput) {
          if (Array.isArray(item.value)) {
            item.value = item.value[0]
          }
          inputComponent = (
            <InputNumber
              key={key}
              {...inputAttributes}
              data-testid={dataTestId || 'criteria-input-field-numeric'}
              size='large'
              onChange={(e: number | null) => {
                updateValue('num', e)
              }}
              value={item.value as number}
              controls={{ upIcon: <DropdownArrowDown />, downIcon: <DropdownArrowDown /> }}
            />
          )
          break
        }

        if (isMultipleInput) {
          inputComponent = (
            <MultiValueInput
              autoFocus={autoFocus}
              item={item}
              changeFieldValue={changeFieldValue}
              setFocusOnInput={setFocusOnInput}
              dataType='number'
            />
          )
          break
        }

        // Between cannot support dynamic input
        return (
          <div className='range-number-input-container'>
            <InputNumber
              key={`${key}-min`}
              {...inputAttributes}
              data-testid={dataTestId || 'criteria-input-field-numeric'}
              size='large'
              className='range-number-input-min'
              placeholder='Minimum'
              onChange={(e: number | null) => {
                updateValue('num_min', e)
              }}
              value={Array.isArray(item.value) ? (item.value[0] as number) : (item.value as number)}
              controls={{ upIcon: <DropdownArrowDown />, downIcon: <DropdownArrowDown /> }}
            />
            <InputNumber
              key={`${key}-max`}
              {...inputAttributes}
              data-testid={dataTestId || 'criteria-input-field-numeric'}
              size='large'
              className='range-number-input-max'
              placeholder='Maximum'
              onChange={(e) => {
                updateValue('num_max', e)
              }}
              value={Array.isArray(item.value) ? (item.value[1] as number) : (item.value as number)}
              controls={{ upIcon: <DropdownArrowDown />, downIcon: <DropdownArrowDown /> }}
            />
          </div>
        )
      }

      case FieldTypeEnum.Url:
      case FieldTypeEnum.Id:
      case FieldTypeEnum.Textarea:
      case FieldTypeEnum.Address:
      case FieldTypeEnum.String:
      case FieldTypeEnum.Phone:
      case FieldTypeEnum.Reference:
      case FieldTypeEnum.Regexp:
      case FieldTypeEnum.Text:
      case FieldTypeEnum.Email: {
        if (isMultipleInput) {
          inputComponent = (
            <MultiValueInput
              autoFocus={autoFocus}
              item={item}
              changeFieldValue={changeFieldValue}
              setFocusOnInput={setFocusOnInput}
              dataType='string'
              dataTestId={dataTestId}
            />
          )
        } else {
          inputComponent = (
            <Input
              key={key}
              {...inputAttributes}
              data-testid={dataTestId || 'criteria-input-field-text'}
              size='large'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateValue('str', e.target.value)
              }}
              value={item.value as string}
            />
          )
        }
        break
      }

      case FieldTypeEnum.HTML: {
        if (item.platform === 'slack') {
          const messageId = payloadFields?.find((field) => field.key === 'messageId')?.value
          const messageVersion = payloadFields?.find((field) => field.key === 'messageVersion')?.value

          inputComponent = (
            <RichText
              item={item as CreateActionFields}
              updateValue={updateValue}
              messageInfo={{
                messageId: messageId && typeof messageId !== 'object' ? String(messageId) : null,
                messageVersion: messageVersion ? Number(messageVersion) : null,
              }}
              dataTestId={dataTestId}
            />
          )
          break
        }

        inputComponent = (
          <>
            {!!item.value && (
              <div>
                <span className='magnify-send-email-action-config-selected-template-text'>
                  Selected Email: {item.value as string}
                </span>
                {emailThumbnailUrl && (
                  <img
                    src={emailThumbnailUrl}
                    alt={`${item.value as string} thumbnail`}
                    title={`${item.value as string} thumbnail`}
                    className='thumbnail'
                    loading='lazy'
                    onError={() => setEmailThumbnailUrl('')}
                  />
                )}
              </div>
            )}
            {currentMotion?.currState !== MotionStateEnum.Executing && (
              <Button
                className='magnify-send-email-action-config-selected-template-button'
                text={!!item.value ? 'Change Email' : 'Choose Email'}
                size='full-width'
                onClickHandler={() => setShowEmailModal((val) => !val)}
                disabled={motionStore.isSegmentBuilderEditDisabled}
              />
            )}
            <EmailTemplateModal
              open={showEmailModal}
              onCancel={() => setShowEmailModal(false)}
              onConfirm={() => setShowEmailModal(false)}
              selectedTemplate={item.value as string}
              changeFieldValue={changeFieldValue}
              isInActionFlow
            />
          </>
        )
        break
      }

      case FieldTypeEnum.Payload: {
        inputComponent = (
          <Input.TextArea
            key={key}
            {...inputAttributes}
            data-testid={dataTestId || 'criteria-input-field-textarea'}
            rows={4}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              updateValue('str', e.target.value)
            }}
            value={item.value as string}
          />
        )
        break
      }

      case FieldTypeEnum.Date:
      case FieldTypeEnum.Datetime:
      case FieldTypeEnum.Datepicker: {
        // Specific use case for relative date calculation based on # of days
        if (item.operator === 'withinLast' || item.operator === 'withinNext') {
          inputComponent = (
            <InputNumber
              key={key}
              {...inputAttributes}
              min={1}
              data-testid='criteria-input-field-numeric'
              size='large'
              onChange={(e: number | null) => {
                updateValue('num', e)
              }}
              value={item.value as number}
              controls={{ upIcon: <DropdownArrowDown />, downIcon: <DropdownArrowDown /> }}
            />
          )
          break
        }
        if (isNullishInput) {
          inputComponent = (
            <Input
              key={key}
              {...inputAttributes}
              data-testid={dataTestId || 'criteria-input-field-null'}
              size='large'
              value={item.value as string}
            />
          )
          break
        }

        const singleDate =
          item.value && !Array.isArray(item.value) && typeof item.value !== 'boolean'
            ? dayjs(item.value as string)
            : undefined
        const startDate = Array.isArray(item.value) && item.value[0] ? dayjs(String(item.value[0])) : null
        const endDate = Array.isArray(item.value) && item.value[1] ? dayjs(String(item.value[1])) : null

        if (range) {
          inputComponent = (
            <RangePicker
              key={`${key}-datepicker-range`}
              {...selectInputAttributes}
              data-testid={dataTestId || 'criteria-input-field-datepicker-range'}
              size='large'
              value={[startDate, endDate]}
              onChange={(e) => {
                updateValue('date_range', e as Dayjs[])
              }}
            />
          )
        } else {
          inputComponent = (
            <DatePicker
              key={`${key}-datepicker`}
              {...selectInputAttributes}
              data-testid={dataTestId || 'criteria-input-field-datepicker'}
              size='large'
              value={singleDate}
              onChange={(e) => {
                updateValue('date', e)
              }}
            />
          )
        }
        break
      }

      case FieldTypeEnum.Picklist:
      case FieldTypeEnum.Collection: {
        if (item.value === '') {
          item.value = []
        }
        inputComponent = (
          <MultipleSelect
            key={key}
            {...multipleSelectAttributes}
            item={item}
            updateValue={updateValue}
            dataTestId={dataTestId}
          />
        )
        break
      }

      case FieldTypeEnum.Dropdown:
      case FieldTypeEnum.Select: {
        inputComponent = (
          <SingleSelect
            key={key}
            {...multipleSelectAttributes}
            item={item}
            updateValue={updateValue}
            dataTestId={dataTestId}
          />
        )
        break
      }

      case FieldTypeEnum.Boolean: {
        const options: SelectOptions[] = [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ]
        inputComponent = (
          <SingleSelect
            key={key}
            {...multipleSelectAttributes}
            item={item}
            updateValue={updateValue}
            defaultOptions={options}
            dataTestId={dataTestId}
            isSearchEnabled={false}
          />
        )
        break
      }

      case FieldTypeEnum.Hidden: {
        inputComponent = (
          <Input
            {...inputAttributes}
            data-testid={dataTestId || 'criteria-input-field-hidden'}
            size='large'
            value={item.value as string}
          />
        )
        break
      }

      default: {
        console.error('Unknown Field Type:', item.type)
        inputComponent = (
          <Input
            key={key}
            {...inputAttributes}
            data-testid={dataTestId || 'criteria-input-field-hidden'}
            size='large'
            placeholder='Unknown type'
            value={item.value as string}
          />
        )
        break
      }
    }

    if (isDynamicField) {
      if (isMultipleInput) {
        inputComponent = <MultiValueInput changeFieldValue={changeFieldValue} item={item} dataType='string' />
      } else {
        inputComponent = (
          <div
            className={classNames('dynamic-field-input', {
              borderless: borderless,
              'dynamic-field-input--disabled': motionStore.isSegmentBuilderEditDisabled,
            })}
            {...(item.onlyDynamicInput && { onClick: () => openDynamicFieldPopup?.(true) })}
            data-testid='dynamic-field-input'>
            <ContentEditable
              className='editable-area'
              data-testid='editable-area'
              html={Array.isArray(html) || !html || typeof html === 'boolean' ? '' : html}
              disabled={
                !checkTypeEquality(item.type, STRING_TYPE) ||
                item.onlyDynamicInput ||
                motionStore.isSegmentBuilderEditDisabled
              }
              onBlur={onBlur(item, changeFieldValue, isTagDeleted)}
              onPaste={onPasteReturnRawText}
              onClick={onClick(removeTag || (() => {}), openDynamicFieldPopup || (() => {}))}
              onChange={() => {}}
              onKeyDown={onKeyPress(item, setIsTagDeleted)}
              onKeyUp={() => getCaretPosition(setDynamicInputPosition, item)}
              onMouseUp={() => getCaretPosition(setDynamicInputPosition, item)}
            />
          </div>
        )
      }
    }

    return (
      <>
        {inputComponent}
        {enableDynamicInput && !isNullishInput && (
          <div
            className={classNames('dynamic-field-popup-btn', {
              'dynamic-field-popup-btn--active': displayDynamicFieldPopover,
              'dynamic-field-popup-btn--disabled': motionStore.isSegmentBuilderEditDisabled,
            })}
            data-testid='dynamic-field-popup-btn'
            onClick={() => openDynamicFieldPopup?.(true)}>
            <IconBolt />
          </div>
        )}
      </>
    )
  },
)
FieldInput.displayName = 'FieldInput'

export default FieldInput
