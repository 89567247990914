import { Layout, Row } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import { Link, Route, Routes } from 'react-router-dom'

import { Button, Heading } from 'components/common'
import MotionListObservability from 'components/MotionObservability/MotionListObservability'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import ConfigureReport from 'pages/Motions/ConfigureReport'
import InternalOperationalStats from 'pages/Motions/InternalOperationalStats'
import { MotionPage } from 'pages/Motions/Motion/Motion.page'
import MotionDetailsPage from 'pages/Motions/MotionDetails'
import MotionsList from 'pages/Motions/MotionsList/MotionsList'

export const Motions = observer(() => {
  const { journeyObservability } = useFlags<LaunchDarklyFeatureFlags>()

  return (
    <main className='motions-main' data-testid='motions-main'>
      <Routes>
        <Route path='motion/new' element={<MotionPage />} />
        <Route path='motion/:id/:version' element={<MotionPage />} />
        <Route path='motion/:id/:version/details' element={<MotionDetailsPage />} />
        <Route path='motion/:id/:version/statistics' element={<InternalOperationalStats />} />
        <Route path='motion/:id/:version/report' element={<ConfigureReport />} />
        <Route
          path='/*'
          element={
            <Layout className='bg-secondary ant-layout-has-sider'>
              <Content className='container motions-main__container'>
                <Content className='content content__spacing'>
                  <Row justify='space-between'>
                    <Heading variant='1' level='1'>
                      Motions
                    </Heading>
                    <Link to='/motions/motion/new'>
                      <Button text='New Motion' testId='motions-create-button' />
                    </Link>
                  </Row>
                  {journeyObservability && <MotionListObservability />}
                  <MotionsList />
                </Content>
              </Content>
            </Layout>
          }
        />
      </Routes>
    </main>
  )
})
Motions.displayName = 'Motions'
