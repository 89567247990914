import axios, { isAxiosError } from 'axios'

import { type DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { demoGetActions } from 'api/mockResponses/demo/actions.mock'
import { getBaseUrl } from 'api/utils'
import { LoggerService } from 'services/LogService/LogService'

import type { ActionsItems } from 'models/actions.model'

export const getActions = async () => {
  try {
    const { data } = await axios.get<ActionsItems[]>(`${getBaseUrl('CORE_API')}/v1/core/actions`, {
      demoData: demoGetActions,
    } as DemoAxiosRequestConfig)

    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'get getActions error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch actions')
    }
  }
}
