import { Heading } from 'components/common'
import PerformanceMetricChart from 'components/MotionDetails/PerformanceMetrics/PerformanceMetricChart'

import type { MotionMetricsPerformance } from 'models/motionMetrics'

interface PerformanceMetricsProps {
  motionId: string
  performanceMetricsData: Record<string, MotionMetricsPerformance>
}

const PerformanceMetrics = ({ motionId, performanceMetricsData }: PerformanceMetricsProps) => {
  return (
    <div className='performance-metrics'>
      <Heading level='2' variant='7'>
        Performance Metrics
      </Heading>
      <div className='performance-metrics__container'>
        {Object.values(performanceMetricsData).map((metric, index) => (
          <PerformanceMetricChart key={index} motionId={motionId} metric={metric} />
        ))}
      </div>
    </div>
  )
}
PerformanceMetrics.displayName = 'PerformanceMetrics'

export default PerformanceMetrics
