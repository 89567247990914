export default function isEqual(a: unknown, b: unknown): boolean {
  if (a === b) return true

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (a.constructor !== b.constructor) return false

    if (Array.isArray(a)) {
      const length = a.length
      if (length !== (b as Array<unknown>).length) return false
      for (let i = length; i-- !== 0; ) {
        if (!isEqual(a[i], (b as Array<unknown>)[i])) {
          return false
        }
      }
      return true
    }

    if (a instanceof Map && b instanceof Map) {
      if (a.size !== b.size) return false
      for (const i of a.entries()) if (!b.has(i[0])) return false
      for (const i of a.entries()) if (!isEqual(i[1], b.get(i[0]))) return false
      return true
    }

    if (a instanceof Set && b instanceof Set) {
      if (a.size !== b.size) return false
      for (const i of a.entries()) if (!b.has(i[0])) return false
      return true
    }

    if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
      const length = (a as Int32Array).length
      if (length !== (b as Int32Array).length) return false
      for (let i = length; i-- !== 0; ) {
        if ((a as Int32Array)[i] !== (b as Int32Array)[i]) {
          return false
        }
      }
      return true
    }

    if (a.constructor === RegExp)
      return (a as RegExp).source === (b as RegExp).source && (a as RegExp).flags === (b as RegExp).flags
    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf()
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString()

    const keys = Object.keys(a)
    const length = keys.length
    if (length !== Object.keys(b).length) return false

    for (let i = length; i-- !== 0; ) if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false

    for (let i = length; i-- !== 0; ) {
      const key = keys[i]

      // React Specific
      if (key === '_owner' && (a as unknown as Record<string, unknown>).$$typeof) {
        // React-specific: avoid traversing React elements' _owner.
        //  _owner contains circular references
        // and is not needed when comparing the actual elements (and not their owners)
        continue
      }

      if (!isEqual((a as Record<string, unknown>)[key], (b as Record<string, unknown>)[key])) {
        return false
      }
    }

    return true
  }

  // Note: Returns `true` if both values are `NaN`, and returns `false` otherwise
  // eslint-disable-next-line no-self-compare
  return a !== a && b !== b
}
