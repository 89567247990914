import { formatCurrencyWithAbbreviation, formatNumberWithAbbreviation } from 'components/common/Utils/insights'
import { NumberFormat } from 'services/Utils'

import type { AccountRowValue, RangeSource, RowValue, Source } from 'models/insights'
import { DataFormatEnum } from 'models/insights'

/**
 * Function that gets from the account table data based on source and returns an output
 * @param source - the source of impact
 * @param tableRows - the list of selected accounts
 * @returns the value of the source calculated based on accounts table
 */
export const getSourceValue = (source: Source, tableRows: AccountRowValue[]) => {
  switch (source.aggregator) {
    case 'count':
      return formatNumber({ number: tableRows?.length ?? 0, format: source.format, decimal: source.decimal })

    case 'sum': {
      let sum = 0
      if (tableRows) {
        sum = calculateSumOf(source.column, tableRows)
      }

      return formatNumber({ number: sum, format: source.format, decimal: source.decimal })
    }

    case 'mean': {
      const meanSum = calculateSumOf(source.column, tableRows)
      const average = meanSum / (tableRows?.length ?? 1)
      return formatNumber({ number: average, format: source.format, decimal: source.decimal })
    }

    case 'max':
    case 'min':
      return getFormattedLimits(source, tableRows)

    default:
      return null
  }
}

interface GetAccountsRange {
  source: Source
  allTableRows: AccountRowValue[]
  selectedAccountsRows: AccountRowValue[]
}

/**
 * Return account ranges based on the provided parameters. If there are not selections on that segment, will display the overall range (the range based on the table rows of that segment)
 *
 * @param {Object} options - The options object.
 * @param {Object} options.source - The source object containing segment information.
 * @param {Array<Object>} options.allTableRows - All rows in the table.
 * @param {Array<Object>} options.selectedAccountsRows - Selected accounts for the specified segment.
 * @returns {Array<Object>} - An array of account ranges based on the conditions.
 */
export const getAccountsRanges = ({ source, allTableRows, selectedAccountsRows }: GetAccountsRange) => {
  const selectedSegmentRows = selectedAccountsRows.filter((row) => row.segment === source.segment)
  const specificSegmentRows = allTableRows.filter((row) => row.segment === source.segment)

  if (!selectedSegmentRows.length) {
    // if nothing is selected from that segment return the overall value
    return getSourceValue(source, specificSegmentRows)
  }

  return getSourceValue(source, selectedSegmentRows)
}

/**
 * Formats and retrieves limits based on the specified source and table rows.
 *
 * @param {Source} source - The source object containing information about the segment, aggregator, column, format, and decimal.
 * @param {AccountRowValue[]} tableRows - An array of table rows containing account values.
 * @returns {string} The formatted limit as a string.
 */
export const getFormattedLimits = (source: Source, tableRows: AccountRowValue[]) => {
  const segmentRows = tableRows.filter((row) => row.segment === source.segment)
  let limit

  switch (source.aggregator) {
    case 'min':
      limit = Math.min(...segmentRows.map((row) => (row[source.column] as RowValue)?.value as number))
      break

    case 'max':
      limit = Math.max(...segmentRows.map((row) => (row[source.column] as RowValue)?.value as number))
      break

    default:
      limit = 0
      break
  }

  return formatNumber({ number: limit, format: source.format, decimal: source.decimal })
}

/**
 * Calculate the sum of a specified column within an array of table rows.
 *
 * @param {string} column - The name of the column to sum.
 * @param {AccountRowValue[]} tableRows - An array of table rows (selected ones).
 * @returns {number} The sum of the specified column values.
 */
export const calculateSumOf = (column: string, tableRows: AccountRowValue[]) => {
  return tableRows.reduce((sum, row) => {
    if (row && typeof row === 'object' && row.hasOwnProperty(column)) {
      const rowValue = row[column] as RowValue

      if (typeof rowValue.value === 'number') {
        return sum + rowValue.value
      } else if (typeof rowValue.value === 'string') {
        const numericValue = parseFloat(rowValue.value)
        if (!isNaN(numericValue)) {
          return sum + numericValue
        }
      }
    }
    return sum
  }, 0)
}

interface FormatNumberProps {
  number: string | number
  format: DataFormatEnum
  decimal: number
}

/**
 * Format a number based on the provided format.
 * @param {FormatNumberProps} options - The options for formatting the number.
 * @param {number} options.number - The number to be formatted.
 * @param {DataFormatEnum} options.format - The desired format for the number ('kmb', 'comma', 'percent').
 * @param {number} options.decimal - (Optional) The number of decimal places to round to.
 * @returns {string|number} The formatted number based on the selected format.
 */
export const formatNumber = ({ number, format, decimal }: FormatNumberProps) => {
  let input: number = number as number
  if (typeof number === 'string') {
    input = Number.parseFloat(number)
  }
  switch (format) {
    case DataFormatEnum.Kmb:
      return formatNumberWithAbbreviation(input, decimal)
    case DataFormatEnum.Comma:
      return NumberFormat.beautifyFloat(input, decimal || 0)
    case DataFormatEnum.Percent:
    case DataFormatEnum.Percentage:
      if (!isNaN(input)) {
        return `${NumberFormat.beautifyFloat(input * 100, decimal)}%`
      }
      return '0%'
    case DataFormatEnum.CurrencyKMB:
      return formatCurrencyWithAbbreviation(input, decimal)
    default:
      return NumberFormat.beautifyFloat(input, decimal || 0)
  }
}
interface GetDriverBoundaries {
  source: RangeSource
  tableRows: AccountRowValue[]
}

/**
 * Retrieves the source value based on specified boundaries from an array of account row values.
 *
 * @param {GetDriverBoundaries} options - The options object containing source information and account row values.
 * @param {number} [options.source] - The source information.
 * @param {number} [options.tableRows] - The account row values.
 *
 * @returns {string | null} The source value calculated based on the specified boundaries.
 */
export const getSourceValueBasedOnBoundaries = ({ source, tableRows }: GetDriverBoundaries) => {
  const accountsInThisRange = getAccountsInDriverRange({ source, tableRows })

  return getSourceValue(source, accountsInThisRange)
}

interface GetAccountsInDriverRange {
  source: RangeSource
  tableRows: AccountRowValue[]
}
/**
 * Retrieves accounts within a specified numeric range based on a driver column from an array of account row values.
 *
 * @param {GetAccountsInDriverRange} options - The options object containing source information and account row values.
 *
 * @returns {AccountRowValue[]} An array containing account row values within the specified numeric range.
 */
export const getAccountsInDriverRange = ({ source, tableRows }: GetAccountsInDriverRange) => {
  const values = getAccountsValues(source, tableRows)

  const rangeLimits = getRangeLimits({
    min: source.min !== undefined && isNaN(source.min) ? undefined : source.min,
    max: source.max !== undefined && isNaN(source.max) ? undefined : source.max,
    numbers: values,
  })

  const accountsInThisRange = tableRows.filter((row) => {
    const rowValue = (row[source.driverColumn] as RowValue)?.value as number

    return rowValue >= rangeLimits.min && rowValue <= rangeLimits.max
  })

  return accountsInThisRange
}

/**
 * Retrieves account values from a given array of account row values based on a specified source column.
 *
 * @param {RangeSource} source - The source object containing information about the driver.
 * @param {AccountRowValue[]} tableRows - The array of account row values.
 *
 * @returns {number[]} An array containing the numeric values from the specified driver column in each account row.
 */
const getAccountsValues = (source: RangeSource, tableRows: AccountRowValue[]) => {
  return tableRows.map((row) => (row[source.driverColumn] as RowValue)?.value as number)
}

/**
 * Retrieves the range limits based on given parameters.
 *
 * @param {Object} options - The options object.
 * @param {number} [options.min] - The optional minimum limit. If not provided, it defaults to the minimum value in the input numbers array.
 * @param {number} [options.max] - The optional maximum limit. If not provided, it defaults to the maximum value in the input numbers array.
 * @param {number[]} options.numbers - The array of numbers used to determine the range limits.
 *
 * @returns {Object} An object containing the calculated minimum and maximum limits.
 * @property {number} min - The minimum limit, either the provided value or the minimum value in the input numbers array.
 * @property {number} max - The maximum limit, either the provided value or the maximum value in the input numbers array.
 */
const getRangeLimits = ({ min, max, numbers }: { min?: number; max?: number; numbers: number[] }) => {
  const defaultMin = Math.min(...numbers)
  const defaultMax = Math.max(...numbers)

  const minLimit = min === undefined || min < defaultMin ? defaultMin : min
  const maxLimit = max === undefined || max > defaultMax ? defaultMax : max

  return {
    min: minLimit,
    max: maxLimit,
  }
}
