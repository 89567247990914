import { Skeleton } from 'antd'
import { useMemo } from 'react'

import { DynamicImage } from 'components/common/DynamicImage/DynamicImage'
import { Heading } from 'components/common/Heading/Heading'
import Sandbox from 'configs/sandbox'
import { ConnectionStatus } from 'pages/Integrations/ConnectionStatus/ConnectionStatus'
import { countStatusByCategory, groupIntegrationsByCategory, getIconUrl } from 'pages/Integrations/utils'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction } from 'react'

import type { IntegrationConnection, IntegrationConnectionStatus } from 'models/Integration.model'

interface AvailableConnectionsProps {
  setAddConnectionModalItems: Dispatch<
    SetStateAction<{ isVisible: boolean; selectedConnectionDisplayName: string; selectedConnectionId: string }>
  >
}

export const AvailableConnections = ({ setAddConnectionModalItems }: AvailableConnectionsProps) => {
  const { helpCenterStore, integrationsStore } = useStore()
  const { integrations, isLoading } = integrationsStore

  const { groupedIntegrationsByCategory, statusCountByCategory } = useMemo(() => {
    return {
      groupedIntegrationsByCategory: groupIntegrationsByCategory(integrations),
      statusCountByCategory: countStatusByCategory(integrations),
    }
  }, [integrations])

  const handlePlatformClick = (
    connections: IntegrationConnection[] | undefined,
    selectedConnectionDisplayName: string,
    selectedConnectionId: string,
  ) => {
    if (Sandbox.isEnabled() || (connections && connections.length > 0)) {
      helpCenterStore.setSupportModal({ isOpen: true })
    } else {
      setAddConnectionModalItems({
        isVisible: true,
        selectedConnectionDisplayName: selectedConnectionDisplayName,
        selectedConnectionId: selectedConnectionId,
      })
    }
  }

  if (isLoading) {
    return (
      <div className='available-connections'>
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className='available-connections__card'>
            <Skeleton />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className='available-connections'>
      {Object.keys(groupedIntegrationsByCategory).map((category) => {
        return (
          <div key={category} className='available-connections__card'>
            <div className='available-connections__card__heading'>
              <Heading variant='4' level='4' className='available-connections__card__heading__title'>
                {category}
              </Heading>
              <div className='available-connections__card__heading__status'>
                {Object.keys(statusCountByCategory[category]).map((status) => {
                  if (!statusCountByCategory[category][status]) return null
                  return (
                    <ConnectionStatus
                      key={`${category}-${status}`}
                      text={statusCountByCategory[category][status].toString()}
                      status={status as IntegrationConnectionStatus}
                    />
                  )
                })}
              </div>
            </div>

            <div className='available-connections__card__platforms'>
              {groupedIntegrationsByCategory[category].map((integration) => {
                return (
                  <div
                    key={integration.id}
                    className='integrations__platform integrations__platform--clickable'
                    onClick={() =>
                      handlePlatformClick(integration.connections, integration.displayName, integration.id)
                    }>
                    <DynamicImage
                      key={integration.id}
                      iconUrl={getIconUrl(integration.id)}
                      iconAlt={integration.displayName}
                    />
                    <span>{integration.displayName}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
AvailableConnections.displayName = 'AvailableConnections'
