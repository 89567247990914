import type { Integration } from 'models/Integration.model'

export const demoGetAllIntegrations: { platforms: Integration[] } = {
  platforms: [
    {
      id: 'gainsight',
      displayName: 'Gainsight',
      canAccess: true,
      isConnected: true,
      category: 'Customer Success Platform',
      connections: [
        {
          connectionId: 'gainsight-473648',
          initialConnectionDate: 1725174754000,
          lastIngestedDate: '2024-09-01T07:12:34.000Z',
          status: 'Live',
          errorMsg: '',
        },
        {
          connectionId: 'gainsight-473578',
          initialConnectionDate: 1725192094000,
          lastIngestedDate: '2024-09-01T12:01:34.000Z',
          status: 'Live',
          errorMsg: '',
        },
      ],
      actions: {
        total: 345700,
        actions: {
          'Create Timeline Event': {
            total: 345700,
          },
        },
      },
    },
    {
      id: 'intercom',
      displayName: 'Intercom',
      canAccess: true,
      isConnected: true,
      category: 'Support',
      connections: [
        {
          connectionId: 'intercom-473648',
          initialConnectionDate: 1725291754000,
          lastIngestedDate: '2024-09-02T15:42:34.000Z',
          status: 'Live',
          errorMsg: '',
        },
      ],
      actions: {
        total: 12400,
        actions: {
          'Create Ticket': {
            total: 12400,
          },
        },
      },
    },
    {
      id: 'zendesk-support',
      displayName: 'Zendesk',
      canAccess: true,
      isConnected: false,
      category: 'Support',
    },
    {
      id: 'hubspot',
      displayName: 'Hubspot',
      canAccess: true,
      isConnected: false,
      category: 'Sales CRM',
      connections: [
        {
          connectionId: 'hubspot-473648',
          initialConnectionDate: 1725469774000,
          lastIngestedDate: '2024-09-04T17:09:34.000Z',
          status: 'Processing',
        },
      ],
    },
    {
      id: 'pendo',
      displayName: 'Pendo',
      canAccess: true,
      isConnected: false,
      category: 'Telemetry / Data Warehouse',
      connections: [
        {
          connectionId: 'pendon-473648',
          initialConnectionDate: 1725532894000,
          lastIngestedDate: '2024-09-05T10:41:34.000Z',
          status: 'Error',
          errorMsg: 'Authentication failed',
        },
      ],
    },
    {
      id: 'salesforce',
      displayName: 'Salesforce',
      canAccess: true,
      isConnected: true,
      category: 'Sales CRM',
      connections: [
        {
          connectionId: 'salesforce-473648',
          initialConnectionDate: 1725719554000,
          lastIngestedDate: '2024-09-07T14:32:34.000Z',
          status: 'Live',
        },
      ],
      actions: {
        total: 12400,
        actions: {
          'Create Ticket': {
            total: 12400,
          },
        },
      },
    },
    {
      id: 'marketo',
      displayName: 'Marketo',
      canAccess: true,
      isConnected: true,
      category: 'Marketing',
      connections: [
        {
          connectionId: 'marketo-473648',
          initialConnectionDate: 1725008074000,
          lastIngestedDate: '2024-08-30T08:54:34.000Z',
          status: 'Live',
          errorMsg: '',
        },
      ],
      actions: {
        total: 12400,
        actions: {
          'Create Ticket': {
            total: 12400,
          },
        },
      },
    },
    {
      id: 'mongodb',
      displayName: 'MongoDB',
      canAccess: true,
      category: 'Telemetry / Data Warehouse',
      connections: [],
      isConnected: false,
      actions: {
        total: 0,
        actions: {},
      },
    },
    {
      id: 'netsuite',
      displayName: 'NetSuite',
      canAccess: true,
      category: 'Other',
      connections: [],
      isConnected: false,
      actions: {
        total: 0,
        actions: {},
      },
    },
    {
      id: 'redshift',
      displayName: 'Redshift',
      canAccess: true,
      category: 'Telemetry / Data Warehouse',
      connections: [],
      isConnected: false,
      actions: {
        total: 0,
        actions: {},
      },
    },
    {
      id: 'slack',
      displayName: 'Slack',
      canAccess: true,
      category: 'Other',
      connections: [],
      isConnected: true,
      actions: {
        total: 0,
        actions: {},
      },
    },
    {
      id: 'snowflakedb',
      displayName: 'Snowflake',
      canAccess: true,
      category: 'Telemetry / Data Warehouse',
      connections: [],
      isConnected: false,
      actions: {
        total: 0,
        actions: {},
      },
    },
    {
      id: 'chorusai',
      displayName: 'Chorus AI',
      canAccess: true,
      category: 'Other',
      connections: [],
      isConnected: false,
      actions: {
        total: 0,
        actions: {},
      },
    },
  ],
}
