import { observer } from 'mobx-react-lite'

import { IconCaretRight, IconCheckMark, IconGripper } from 'components/common/Icons/Icons'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import { LogoService } from 'services/Utils/logo'
import { getNormalize } from 'services/Utils/parseString.utils'
import useStore from 'store/useStore'

import type { DragEvent, Dispatch, SetStateAction } from 'react'

import type { MetadataDescription, MetadataSearchItem, MetadataTypes } from 'models/metadata.model'
import type { BreadcrumbInfo, SourceData } from 'models/motion/motionBuilder.model'

interface SearchItemProps {
  /** Whether to allow selection of items reguardless of the modal being open. */
  allowSelection?: boolean
  item: MetadataSearchItem
  breadCrumbItems: BreadcrumbInfo[]
  setSearchInput: Dispatch<SetStateAction<string>>
  handleDragEnd?: (e: DragEvent<HTMLDivElement>, item?: SourceData, position?: any) => void
  onDragStart?: (e: any, item: MetadataDescription) => void
  handleSelectField?: (item: MetadataDescription) => void
}

const SearchItem = observer(
  ({ allowSelection, item, handleDragEnd, onDragStart, setSearchInput, handleSelectField }: SearchItemProps) => {
    const { metadataStore, motionMetricsStore } = useStore()

    useMetadataDisplayErrorNotification(metadataStore)

    const isField = item.type === 'field'

    const displayName = item.order[item.order.length - 1]?.magnifyDisplayName

    const searchItem: MetadataDescription & { path: string } = {
      name: item.order[item.order.length - 1]?.name,
      key: item.order[item.order.length - 1]?.key,
      type: item.order[item.order.length - 1]?.type || 'string',
      platform: item.order[0]?.name.toLocaleLowerCase(),
      solutionInstanceId: item.order[0].solutionInstanceId,
      path: item.order
        .map((orderItem) => orderItem.magnifyDisplayName ?? orderItem.name)
        .splice(0, item.order.length - 1)
        .join('/'),
      object: isField ? item.order[item.order.length - 2]?.key : item.order[item.order.length - 1]?.key,
      ...(displayName && {
        magnifyDisplayName: displayName,
      }),
      connections: [],
      data: [],
    }

    const selectedItem: MetadataTypes = {
      platform: searchItem.platform,
      ...(searchItem.object && item.order.length > 1 && { object: searchItem.object }),
      ...(searchItem.solutionInstanceId && { solutionInstanceId: searchItem.solutionInstanceId }),
      ...(isField && { field: searchItem.name }),
      ...(item.order[1]?.magnifyDisplayName && {
        magnifyDisplayName: item.order[1]?.magnifyDisplayName,
      }),
    }

    const displayCaret = (): boolean => {
      return !isField
    }

    const isDraggable = (): boolean => {
      return isField
    }

    const handleSelectOption = async (item: MetadataSearchItem) => {
      metadataStore.setCurrentItem(selectedItem as MetadataDescription, true)
      metadataStore.setDisplaySearchResult(false)
      const options = metadataStore.currentItem

      const alreadyLoadedMetadata = metadataStore.getLoadedMetadata({
        metadata: metadataStore.metadata,
        options,
        iterationNumber: 0,
      })

      if (!alreadyLoadedMetadata?.length) {
        await metadataStore.get(options)
      } else {
        metadataStore.setViewListMetadata({ data: alreadyLoadedMetadata })
      }

      if (metadataStore.hasError) {
        return
      }
      setSearchInput('')
      metadataStore.addSearchBreadcrumbs(item)
    }

    return (
      <div
        draggable={isDraggable()}
        className='menu__item search__item'
        data-testid='search__item'
        {...(!allowSelection && {
          onDragStart: (e: DragEvent<HTMLDivElement>) => {
            onDragStart?.(e, {
              name: searchItem.name,
              type: searchItem.type,
              key: searchItem.key,
              ...(selectedItem.magnifyDisplayName && { magnifyDisplayName: selectedItem.magnifyDisplayName }),
              data: [],
              platform: selectedItem.platform,
              object: selectedItem.object,
              solutionInstanceId: selectedItem.solutionInstanceId,
              connections: [],
            })
          },
          onDragEnd: (e: DragEvent<HTMLDivElement>) => {
            handleDragEnd?.(e)
          },
        })}
        onClick={() => {
          if (!isDraggable()) {
            handleSelectOption(item).catch(console.error)
          } else if (isDraggable() && allowSelection) {
            handleSelectField?.({ ...searchItem, data: [] })
          }
        }}>
        <img
          key={searchItem.platform}
          src={LogoService.getIcon(getNormalize(searchItem.platform || ''))}
          alt={searchItem.platform}
        />

        <div className='search__info'>
          <div className='search__name' data-testid='search__name'>
            {searchItem.magnifyDisplayName ?? searchItem.name}
          </div>
          {item.type !== 'platform' && (
            <div className='search__path' data-testid='search__path'>
              <span title={searchItem.path}>{searchItem.path}</span>
            </div>
          )}
        </div>

        <div className='search__icon'>
          {displayCaret() && <IconCaretRight />}
          {isDraggable() && !allowSelection && <IconGripper />}
          {isDraggable() && allowSelection && motionMetricsStore.isSearchItemInUse(item) && (
            <IconCheckMark className='selected-data-field' data-testid='selected-data-field' />
          )}
        </div>
      </div>
    )
  },
)
SearchItem.displayName = 'SearchItem'

export default SearchItem
