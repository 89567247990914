import { sanitizeString } from 'services/Utils/parseString.utils'

import type { MetadataDescription, MetadataRoot, MetadataSearchItem } from 'models/metadata.model'

const fieldTypes: Record<string, string[]> = {
  number: ['int', 'integer', 'number', 'currency', 'double', 'decimal'],
  percent: ['percent'],
  string: ['string', 'text'],
}

export function getMenuListFromPath(paths: string[], data: MetadataDescription[]) {
  let selectedObject: MetadataRoot | undefined
  for (let i = 0; i < paths.length; i++) {
    const current = paths[i]
    if (!i) {
      selectedObject = getObjectFromList(data, current)
    } else {
      selectedObject = getObjectFromList(selectedObject?.data, current)
    }
  }
  return selectedObject
}

export function getObjectFromList(
  objectFields: MetadataDescription[] | undefined,
  searchedTerm: string,
): MetadataRoot | undefined {
  if (objectFields !== undefined) {
    const data = objectFields.find((item) => {
      return sanitizeString(item.name) === sanitizeString(searchedTerm)
    })
    return data
  }
}

export const searchFields = (fields: MetadataDescription[], inputValue: string) => {
  return fields.filter((field) => field.name.toLowerCase().includes(inputValue.toLowerCase()))
}

export const checkTypeEquality = (itemType: string = '', types: string[]) => {
  let typeEquality = false

  types.every((type) => {
    if (fieldTypes[type]?.includes(itemType)) {
      typeEquality = true

      return false
    }

    return true
  })

  return typeEquality
}

export const filterByType = (metadataList: MetadataDescription[], filterByType: boolean | string[]) => {
  if (Array.isArray(filterByType) && filterByType.length > 0) {
    return metadataList.filter((metadata) => {
      if (!metadata?.type && metadata.type !== '') {
        return true
      } else {
        return checkTypeEquality(metadata.type, filterByType)
      }
    })
  }

  return metadataList
}

export const filterByTypeOnSearch = (metadataList: MetadataSearchItem[], filterByType: boolean | string[]) => {
  if (Array.isArray(filterByType) && filterByType.length > 0) {
    return metadataList.filter(
      (metadata) =>
        metadata.type !== 'field' ||
        (metadata.type === 'field' && checkTypeEquality(metadata.order[metadata.order.length - 1].type, filterByType)),
    )
  }

  return metadataList
}
