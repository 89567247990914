import type { MotionMetricDates } from 'models/motionMetrics'

/**
 * Returns an array of evenly spaced items from the provided data array based on the selected time range.
 * Supports month ranges, year-to-date, and all-time views.
 *
 * @param data An array of objects containing date and value properties.
 * @param timeRange The time range to consider - can be a number (months), 'ytd' (year to date), or 'all' (all time).
 * @returns An array of objects containing date and value properties, with a maximum length of 8.
 * @example
 * const data = [
 *   { date: '2023-01-01', value: 10 },
 *   { date: '2023-01-02', value: 20 },
 *   // ... more data points
 * ];
 * const selectedMonthRange = 3;
 * const result = getEvenlySpacedItems(data, selectedMonthRange);
 * // result will contain 8 evenly spaced items from the last 3 months of data
 */
export const getEvenlySpacedItems = (data: MotionMetricDates[], timeRange: number | string) => {
  let filteredData = [...data]
  /** Maximum number of data points to display */
  const maxDataPoints = 180

  // Filter data based on time range
  if (typeof timeRange === 'number') {
    // Handle different month ranges
    if (timeRange === 1) {
      // 1 month - last 30 days, 1 point per day
      // Return all points, one per day
      const startIndex = Math.max(data.length - 30, 0)
      filteredData = data.slice(startIndex)
      return filteredData
    } else if (timeRange === 3) {
      // 3 months - last 90 days, 1 point per day
      // Return all points, one per day
      const startIndex = Math.max(data.length - 90, 0)
      filteredData = data.slice(startIndex)
      return filteredData
    } else if (timeRange === 6) {
      // 6 months - last 180 days, 1 point per day
      // Return all points, one per day
      const startIndex = Math.max(data.length - 180, 0)
      filteredData = data.slice(startIndex)
      return filteredData
    } else if (timeRange === 12) {
      // 1 year - last 360 days in 1 week increments
      const startIndex = Math.max(data.length - 360, 0)
      filteredData = data.slice(startIndex)
      // Group by weeks and calculate average
      return aggregateDataByWeeks(filteredData)
    }
  } else if (timeRange === 'ytd') {
    // Year to date - filter data from the beginning of the current year
    const currentYear = new Date().getFullYear()
    const startOfYear = new Date(currentYear, 0, 1) // January 1st of current year
    filteredData = data.filter((item) => {
      const itemDate = new Date(item.date)
      return itemDate >= startOfYear
    })
    // If more than 180 days, aggregate by weeks
    if (filteredData.length > maxDataPoints) {
      return aggregateDataByWeeks(filteredData)
    }
    // Less than 180 days, return all points, one per day
    return filteredData
  } else if (timeRange === 'all') {
    // All time - use all available data, but aggregate by weeks if too many points
    if (filteredData.length > maxDataPoints) {
      return aggregateDataByWeeks(filteredData)
    }
    // Less than 180 days, return all points, one per day
    return filteredData
  }

  return filteredData
}

/**
 * Helper function to aggregate data by weeks
 * @param {MotionMetricDates[]} data The data to aggregate
 * @returns {MotionMetricDates[]} An array of objects containing date and value properties.
 */
export const aggregateDataByWeeks = (data: MotionMetricDates[]): MotionMetricDates[] => {
  if (data.length === 0) return []

  // Group data by week
  const weekMap: Record<string, { sum: number; count: number; firstDate: string }> = {}

  data.forEach((item) => {
    const date = new Date(item.date)
    // Get the week start date (Sunday)
    date.setDate(date.getDate() - date.getDay())
    const weekKey = date.toISOString().split('T')[0]

    // If the week key doesn't exist, create it
    if (!weekMap[weekKey]) {
      weekMap[weekKey] = { sum: 0, count: 0, firstDate: item.date }
    }

    // If the value is null, set it to 0
    weekMap[weekKey].sum += item.value ?? 0
    weekMap[weekKey].count += 1
  })

  // Convert the map to an array of weekly averages
  const weeklyData = Object.keys(weekMap).map((weekKey) => ({
    date: weekMap[weekKey].firstDate,
    value: weekMap[weekKey].sum / weekMap[weekKey].count,
  }))

  // Sort by date
  weeklyData.sort((a, b) => a.date.localeCompare(b.date))

  return weeklyData
}

/**
 * Converts the selected month range to start and end dates
 * @param {number | 'ytd' | 'all'} selectedMonthRange The selected month range
 * @returns {formattedStartDate: string, formattedEndDate: string} An object containing formatted start and end dates
 */
export const convertRangeToDates = (selectedMonthRange: number | string) => {
  // Generate startDate and endDate based on selectedMonthRange
  const today = new Date()
  let startDate = new Date(today)

  // Handle different time range options
  if (typeof selectedMonthRange === 'number') {
    // Month range - go back N months
    startDate.setMonth(today.getMonth() - selectedMonthRange)
  } else if (selectedMonthRange === 'ytd') {
    // Year to date - start from beginning of current year
    startDate = new Date(today.getFullYear(), 0, 1) // January 1st of current year
  } else if (selectedMonthRange === 'all') {
    // All time - use 2025-01-01 as the earliest start date to ensure ytd makes sense in 2025
    startDate = new Date('2025-01-01')
  }

  // Format dates as YYYY-MM-DD
  const formatDate = (date: Date) => date.toISOString().split('T')[0]
  const formattedStartDate = formatDate(startDate)
  const formattedEndDate = formatDate(today)

  return { formattedStartDate, formattedEndDate }
}
