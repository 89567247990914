import axios, { isAxiosError } from 'axios'

import type { DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { demoGetMotionMetricDefinitions } from 'api/mockResponses/motionMetrics.mock'
import { getBaseUrl } from 'api/utils'
import { LoggerService } from 'services/LogService/LogService'

import type {
  MotionMetricsInputs,
  MotionMetricDefinition,
  NewMetricDefinition,
  UpdatedMotionMetricDefinition,
  UpdateMetricDefinition,
  TenantMetricDefinition,
} from 'models/motionMetrics'

/**
 * Get a Motion specific Metric.
 * @param {string} motionId The ID of the Motion.
 * @param {MotionMetricsInputs} metricInputs The inputs for the Metric.
 * @returns {Promise<MotionMetricDefinition>} The Motion specific Metric.
 * @see {@link https://github.com/Encore-Post-Sales/data-core/tree/main/apps/core-api/src/api/journeys/metric-definitions/index.ts | Metric Definitions Controller}
 */
export const getMotionMetric = async (motionId: string, metricInputs: MotionMetricsInputs) => {
  try {
    const { data } = await axios.get<MotionMetricDefinition>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/${motionId}/metrics/${metricInputs.metricDefinitionId}/operator/${metricInputs.operator}`,
      {
        params: metricInputs,
        demoData: demoGetMotionMetricDefinitions.find((metric) => metric.id === metricInputs.metricDefinitionId) ?? {},
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getMotionMetric Motion Metrics error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch Motion Metric')
    }
  }
}

/**
 * Get the Metric Definitions for a Motion.
 * @param {string} motionId The ID of the Motion.
 * @returns {Promise<MotionMetricDefinition[]>} The Metric Definitions for the Motion.
 * @see {@link https://github.com/Encore-Post-Sales/data-core/tree/main/apps/core-api/src/api/journeys/metric-definitions/index.ts | Metric Definitions Controller}
 */
export const getMotionMetricDefinitions = async (motionId: string) => {
  try {
    const { data } = await axios.get<MotionMetricDefinition[]>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/${motionId}/metric-definitions`,
      {
        demoData: demoGetMotionMetricDefinitions,
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getMotionMetricDefinitions Motion Metrics error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch Motions')
    }
  }
}

/**
 * Create a new Motion specific Metric.
 * @param {string} motionId The ID of the Motion.
 * @param {NewMetricDefinition} newMetricDefinition The new Metric Definition.
 * @returns {Promise<{ data: { id: string } }>} The new Metric Definition.
 * @see {@link https://github.com/Encore-Post-Sales/data-core/tree/main/apps/core-api/src/api/journeys/metric-definitions/index.ts | Metric Definitions Controller}
 */
export const createMotionMetricDefinition = async (motionId: string, newMetricDefinition: NewMetricDefinition) => {
  try {
    const payload: NewMetricDefinition = {
      columnName: newMetricDefinition.columnName,
      displayName: newMetricDefinition.displayName,
      goal: newMetricDefinition.goal,
      motionId: newMetricDefinition.motionId,
      objectName: newMetricDefinition.objectName,
      operator: newMetricDefinition.operator,
      platformConnectionId: newMetricDefinition.platformConnectionId,
    }
    const { data } = await axios.post<{ data: { id: string } }>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/${motionId}/metric-definitions`,
      payload,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'createMotionMetricDefinition Motion Metrics error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to create Motion Metric Definition')
    }
  }
}

/**
 * Update an existing Motion specific Metric Definition.
 * @param {string} motionId The ID of the Motion.
 * @param {string} operator The operator of the Metric Definition.
 * @param {UpdateMetricDefinition} newMetricDefinition - The new Metric Definition.
 * @returns {Promise<{ data: UpdatedMotionMetricDefinition }>} The updated Metric Definition.
 * @see {@link https://github.com/Encore-Post-Sales/data-core/tree/main/apps/core-api/src/api/journeys/metric-definitions/index.ts | Metric Definitions Controller}
 */
export const updateMotionMetricDefinition = async (
  motionId: string,
  operator: string,
  newMetricDefinition: UpdateMetricDefinition,
) => {
  try {
    const { data } = await axios.put<{ data: UpdatedMotionMetricDefinition }>(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/${motionId}/metric-definitions/${newMetricDefinition.metricDefinitionId}/operator/${operator}`,
      // These are the only fields that can be updated.
      {
        displayName: newMetricDefinition.displayName,
        goal: newMetricDefinition.goal,
        operator: newMetricDefinition.operator,
      },
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'updateMotionMetricDefinition Motion Metrics error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to update Motion Metrics')
    }
  }
}

/**
 * Delete a Motion specific Metric Definition.
 * @param {string} motionId The ID of the Motion.
 * @param {string} metricId The ID of the Metric Definition.
 * @param {string} operator The operator of the Metric Definition.
 * @returns {Promise<{void}>} The deleted Metric Definition.
 * @see {@link https://github.com/Encore-Post-Sales/data-core/tree/main/apps/core-api/src/api/journeys/metric-definitions/index.ts | Metric Definitions Controller}
 */
export const deleteMotionMetricDefinition = async (motionId: string, metricId: string, operator: string) => {
  try {
    // The response is an empty object.
    await axios.delete(
      `${getBaseUrl('CORE_API')}/v1/core/journeys/${motionId}/metric-definitions/${metricId}/operator/${operator}`,
    )
  } catch (error: unknown) {
    LoggerService.error({ message: 'deleteMotionMetricDefinition Motion Metrics error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to delete Motion Metric Definition')
    }
  }
}

/**
 * Get the metric definitions for the entire Tenant.
 * @returns {Promise<TenantMetricDefinition[]>} The Metric Definitions for the Tenant.
 * @see {@link https://github.com/Encore-Post-Sales/data-core/tree/main/apps/core-api/src/api/metric-definitions/controller.ts | Metric Definitions Controller}
 */
export const getMetricDefinitions = async () => {
  try {
    const { data } = await axios.get<TenantMetricDefinition[]>(`${getBaseUrl('CORE_API')}/v1/core/metric-definitions`)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getMetricDefinitions Motion Metrics error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch Metric Definitions')
    }
  }
}
