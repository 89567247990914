import { makeAutoObservable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import type { ChildStore } from 'store/StoreTypes'

import type { MetadataDescription, MetadataSearchItem } from 'models/metadata.model'
import type {
  MotionMetricDefinition,
  NewMetricDefinition,
  TenantMetricDefinition,
  UpdateMetricDefinition,
} from 'models/motionMetrics'

export class MotionMetricsStore implements ChildStore {
  isMotionMetricsLoading: boolean = false

  motionMetrics: MotionMetricDefinition[] = []
  tenantMetricDefinitions: TenantMetricDefinition[] = []

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.isMotionMetricsLoading = false
    this.motionMetrics = []
    this.tenantMetricDefinitions = []
    this.apiError = null
  }

  setMotionMetrics = (metrics: MotionMetricDefinition[]) => {
    this.motionMetrics = metrics
  }

  getMotionMetrics = async (motionId?: string) => {
    this.isMotionMetricsLoading = true
    try {
      const tenantMetricDefinitions = await API.motionMetrics.getMetricDefinitions()
      let motionMetricDefinitions: MotionMetricDefinition[] = []
      if (motionId) {
        const data = await API.motionMetrics.getMotionMetricDefinitions(motionId)
        motionMetricDefinitions = data
      }

      // Add the type and displayName to the tenantMetricDefinitions
      const tenantMetricDefinitionsTypeAndDisplayName = await Promise.all(
        tenantMetricDefinitions.map(async (tenantMetricDefinition) => {
          let metadata: MetadataDescription | null = null
          try {
            metadata = await this.getMetadataType({
              key: tenantMetricDefinition.columnName,
              platform: tenantMetricDefinition.platformKey,
              object: tenantMetricDefinition.objectName,
              solutionInstanceId: tenantMetricDefinition.platformConnectionId,
            })
          } catch (error: unknown) {
            console.error('Error fetching metadata for tenantMetricDefinition', tenantMetricDefinition.id, error)
          }
          return {
            ...tenantMetricDefinition,
            type: metadata?.type ?? 'unknown',
            displayName: metadata?.displayName ?? 'unknown',
          }
        }),
      )

      // Sort tenant metric definitions by object name then column name
      tenantMetricDefinitionsTypeAndDisplayName.sort((a, b) => {
        // First compare object names
        const objectComparison = a.objectName.localeCompare(b.objectName)
        // If objects are the same, compare column names
        if (objectComparison === 0) {
          return a.columnName.localeCompare(b.columnName)
        }
        return objectComparison
      })

      // Add the type and displayName to the motionMetricDefinitions
      let motionMetricDefinitionsWithType: MotionMetricDefinition[] = []
      if (motionMetricDefinitions.length > 0) {
        motionMetricDefinitionsWithType = await Promise.all(
          motionMetricDefinitions.map(async (motionMetricDefinition) => {
            let metadata: MetadataDescription | null = null
            try {
              metadata = await this.getMetadataType({
                key: motionMetricDefinition.columnName,
                platform: motionMetricDefinition.platformKey,
                object: motionMetricDefinition.objectName,
                solutionInstanceId: motionMetricDefinition.platformConnectionId,
              })
            } catch (error: unknown) {
              console.error('Error fetching metadata for motionMetricDefinition', motionMetricDefinition.id, error)
            }
            return {
              ...motionMetricDefinition,
              type: metadata?.type ?? 'unknown',
              displayName: metadata?.displayName ?? motionMetricDefinition.displayName ?? 'unknown',
            }
          }),
        )
      }

      runInAction(() => {
        this.tenantMetricDefinitions = tenantMetricDefinitionsTypeAndDisplayName
        this.motionMetrics = motionMetricDefinitionsWithType
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isMotionMetricsLoading = false
      })
    }
  }

  createMotionMetric = async (motionId: string, motionMetric: NewMetricDefinition) => {
    try {
      delete motionMetric.aggregationLevel
      await API.motionMetrics.createMotionMetricDefinition(motionId, motionMetric)
      await this.getMotionMetrics(motionId)
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    }
  }

  /**
   * Update a Motion specific Metric Definition then refresh the motion metrics.
   * @param {string} motionId The ID of the Motion.
   * @param {string} operator The operator of the Metric Definition.
   * @param {UpdateMetricDefinition} motionMetric - The new Metric Definition.
   */
  updateMotionMetric = async (motionId: string, operator: string, motionMetric: UpdateMetricDefinition) => {
    try {
      await API.motionMetrics.updateMotionMetricDefinition(motionId, operator, motionMetric)
      await this.getMotionMetrics(motionId)
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    }
  }

  /**
   * Remove a Motion specific Metric Definition then refresh the motion metrics.
   * @param {string} motionId The ID of the Motion.
   * @param {string} metricId The ID of the Metric Definition.
   * @param {string} operator The operator of the Metric Definition.
   */
  removeMotionMetric = async (motionId: string, metricId: string, operator: string) => {
    try {
      await API.motionMetrics.deleteMotionMetricDefinition(motionId, metricId, operator)
      await this.getMotionMetrics(motionId)
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    }
  }

  /**
   * Get the `type` and `displayName` for a specific metadata field.
   * @param {string} key The key of the metadata
   * @param {string} platform The platform of the metadata
   * @param {string} object The object of the metadata
   * @param {string} solutionInstanceId The solution instance id of the metadata
   * @returns {Promise<MetadataDescription | null>} The metadata for the platform
   */
  getMetadataType = async ({
    key,
    platform,
    object,
    solutionInstanceId,
  }: {
    key: string
    platform: string
    object: string
    solutionInstanceId?: string
  }): Promise<MetadataDescription | null> => {
    // { "data": { "type": "integer", "displayName": "Numevents" } }
    const { data } = (await API.metadata.get({
      platform,
      object,
      field: key,
      solutionInstanceId,
    })) as MetadataDescription
    return data as unknown as MetadataDescription
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }

  /**
   * Check if the search item is in use by any motion metric.
   * Used in `SearchItem` to determine if the search item should be marked as in use.
   * @param {MetadataSearchItem} item The search item to check
   * @returns {boolean} True if the search item is in use, false otherwise
   */
  isSearchItemInUse = (item: MetadataSearchItem): boolean => {
    // We need to extract the platform, object, field, and solutionInstanceId from the item
    const platform = item?.order?.[0]?.key
    const object = item?.order?.[1]?.key
    const field = item?.order?.[2]?.key
    const solutionInstanceId = item?.order?.[0]?.solutionInstanceId

    // If any of the required fields are missing, return false
    if (!platform || !object || !field || !solutionInstanceId) {
      return false
    }

    return this.motionMetrics.some((metric) => {
      const metricPlatform = metric.platformKey?.toLowerCase()
      const metricObject = metric.objectName?.toLowerCase()
      const metricField = metric.columnName?.toLowerCase()
      const metricSolutionId = metric.platformConnectionId

      return (
        metricPlatform === platform.toLowerCase() &&
        metricObject === object.toLowerCase() &&
        metricField === field.toLowerCase() &&
        (!solutionInstanceId || metricSolutionId === solutionInstanceId)
      )
    })
  }

  /**
   * Check if the field is in use by any motion metric.
   * Used in `SidebarListView` to determine if the field should be marked as in use.
   * @param {MetadataDescription} item The field to check
   * @returns {boolean} True if the field is in use, false otherwise
   */
  isFieldInUse = (item: MetadataDescription): boolean => {
    // We need to extract the field and solutionInstanceId from the item
    const field = item?.key
    const solutionInstanceId = item?.solutionInstanceId

    // If any of the required fields are missing, return false
    if (!field || !solutionInstanceId) {
      return false
    }

    return this.motionMetrics.some((metric) => {
      const metricField = metric.columnName?.toLowerCase()
      const metricSolutionId = metric.platformConnectionId

      return metricField === field.toLowerCase() && (!solutionInstanceId || metricSolutionId === solutionInstanceId)
    })
  }
}
