import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import SegmentSidebar from 'components/MotionBuilder/SegmentBuilder/SegmentSidebar/DataSource'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { MetadataDescription } from 'models/metadata.model'

interface DynamicDataFieldProps {
  allowSelection?: boolean
  /** The tooltip text to display when the field is disabled. */
  disabledFieldTooltip?: string
  /** The tooltip text to display when the object is disabled. */
  disabledObjectTooltip?: string
  /** The tooltip text to display when the platform is disabled. */
  disabledPlatformTooltip?: string
  /** Filter fields by a custom filter function. */
  filterFields?: (field: MetadataDescription) => boolean
  /** Filter objects by a custom filter function. */
  filterObjects?: (object: MetadataDescription) => boolean
  /** Filter platforms by a custom filter function. */
  filterPlatforms?: (platform: MetadataDescription) => boolean
  handleSelectDataField: (item: MetadataDescription) => void
}

const DynamicDataField = observer(
  ({
    allowSelection = false,
    disabledFieldTooltip = '',
    disabledObjectTooltip = '',
    disabledPlatformTooltip = '',
    filterFields,
    filterObjects,
    filterPlatforms,
    handleSelectDataField,
  }: DynamicDataFieldProps) => {
    const { metadataStore } = useStore()
    const { metadata, get, setCurrentItem, setBreadCrumbItems } = metadataStore

    useEffect(() => {
      if (!metadata.data.length) {
        get().catch(console.error)
      }
      setCurrentItem(null)
      setBreadCrumbItems([])
    }, [])

    useMetadataDisplayErrorNotification(metadataStore)

    return (
      <SegmentSidebar
        allowSelection={allowSelection}
        disabledFieldTooltip={disabledFieldTooltip}
        disabledObjectTooltip={disabledObjectTooltip}
        disabledPlatformTooltip={disabledPlatformTooltip}
        filterFields={filterFields}
        filterObjects={filterObjects}
        filterPlatforms={filterPlatforms}
        handleSelectDataField={handleSelectDataField}
      />
    )
  },
)
DynamicDataField.displayName = 'DynamicDataField'

export default DynamicDataField
