import { Dropdown } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Heading } from 'components/common'
import { DynamicImage } from 'components/common/DynamicImage/DynamicImage'
import Sandbox from 'configs/sandbox'
import { AddConnectionModal } from 'pages/Integrations/AddConnectionModal/AddConnectionModal'
import { AvailableConnections } from 'pages/Integrations/AvailableConnections/AvailableConnections'
import { ConnectionsTable } from 'pages/Integrations/ConnectionsTable/ConnectionsTable'
import { StatCard } from 'pages/Integrations/StatCard/StatCard'
import {
  getIconUrl,
  getActionsExecutedStatCardData,
  getLatestIngestStatCardData,
  getLiveConnectionsStatCardData,
} from 'pages/Integrations/utils'
import useStore from 'store/useStore'

import type { MenuProps } from 'antd'
import type { Params } from 'react-router-dom'

export const Integrations = observer(() => {
  const { integrationsStore } = useStore()
  const { integrations, getIntegrations, isLoading } = integrationsStore

  const defaultAddConnectionModalItems = {
    isVisible: false,
    selectedConnectionDisplayName: '',
    selectedConnectionId: '',
  }
  const [addConnectionModalItems, setAddConnectionModalItems] = useState(defaultAddConnectionModalItems)

  // Get the connectionId from the URL parameter
  const { connectionId }: Readonly<Params<string>> = useParams()
  // Get the display name for the connection, if a connectionId is provided
  let connectionDisplayName: string = ''
  switch (connectionId) {
    case 'slack':
      connectionDisplayName = 'Slack'
      break
    case 'salesforce':
      connectionDisplayName = 'Salesforce'
      break
    case 'totango':
      connectionDisplayName = 'Totango'
      break
    case 'outreach':
      connectionDisplayName = 'Outreach'
      break
    default:
      break
  }

  useEffect(() => {
    getIntegrations().catch((error) => {
      console.error('Error fetching integration connections', error)
    })
  }, [])

  useEffect(() => {
    if (connectionDisplayName && connectionId) {
      setAddConnectionModalItems({
        isVisible: true,
        selectedConnectionDisplayName: connectionDisplayName,
        selectedConnectionId: connectionId,
      })
    }
  }, [connectionDisplayName, connectionId])

  const liveConnectionsStatCardData = useMemo(() => getLiveConnectionsStatCardData(integrations), [integrations])
  const latestIngestStatCardData = useMemo(() => getLatestIngestStatCardData(integrations), [integrations])
  const actionsExecutedStatCardData = useMemo(() => getActionsExecutedStatCardData(integrations), [integrations])

  const getAddConnectonsDropdownItems = () => {
    if (!integrations) {
      return []
    }
    return integrations.map((integration, index) => {
      return {
        label: (
          <div
            key={integration.id}
            className='integrations__platform'
            data-testid={`add-connection-dropdown-${integration.id}`}>
            <DynamicImage key={integration.id} iconUrl={getIconUrl(integration.id)} iconAlt={integration.displayName} />
            <span>{integration.displayName}</span>
          </div>
        ),
        key: index.toString(),
        onClick: () => {
          setAddConnectionModalItems({
            isVisible: true,
            selectedConnectionDisplayName: integration.displayName,
            selectedConnectionId: integration.id,
          })
        },
      }
    })
  }

  const items: MenuProps['items'] = useMemo(() => getAddConnectonsDropdownItems(), [integrations])

  return (
    <main className='integrations'>
      <div className='integrations__header' data-testid='integrations-header'>
        <div className='container integrations__header__container'>
          <Heading variant='2' level='2' className='integrations__header__title'>
            Integrations
          </Heading>
          <Dropdown placement='bottomRight' trigger={['click']} menu={{ items }}>
            <div>
              <Button text='Add Connection' size='L' disabled={isLoading || !integrations || Sandbox.isEnabled()} />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className='container'>
        <div className='content content__spacing--lg'>
          <section className='integrations__stat-cards'>
            <Heading variant='1' level='1' className='integrations__stat-cards__title'>
              Your data working together
            </Heading>

            <div className='integrations__stat-cards__list'>
              <StatCard {...liveConnectionsStatCardData} />
              <StatCard {...latestIngestStatCardData} />
              <StatCard {...actionsExecutedStatCardData} />
            </div>
          </section>

          <section className='integrations__connections'>
            <div className='integrations__connections__heading'>
              <Heading variant='2' level='2' className='integrations__title'>
                Your Connections
              </Heading>
            </div>

            <ConnectionsTable />
          </section>

          <section className='integrations__available-connections'>
            <Heading variant='2' level='2' className='integrations__title integrations__available-connections__title'>
              Available Connections
            </Heading>

            <AvailableConnections setAddConnectionModalItems={setAddConnectionModalItems} />
          </section>
        </div>
      </div>
      <AddConnectionModal
        selectedConnectionDisplayName={addConnectionModalItems.selectedConnectionDisplayName}
        selectedConnectionId={addConnectionModalItems.selectedConnectionId}
        isVisable={addConnectionModalItems.isVisible}
        handleCancel={() => setAddConnectionModalItems(defaultAddConnectionModalItems)}
      />
    </main>
  )
})
Integrations.displayName = 'Integrations'
