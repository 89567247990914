export const demoGetMotionMetricDefinitions = [
  {
    columnName: 'numevents',
    displayName: 'Sum of events',
    goal: 10,
    id: 'met_01jja6t7fcfk6skak97ssp3p7q',
    motionId: 'b8d2cf43-7000-4729-957a-b45a26177b23',
    objectName: 'feature_events',
    operator: 'sum',
    platformConnectionId: 'pfcn_01j0xx4478fvz85sx620w0xzzc',
    platformKey: 'pendo',
  },
  {
    columnName: 'total_arr_2__c',
    displayName: 'Total ARR',
    goal: 1000,
    id: 'met_01jja5xtvwf6a83vfs7tamp206',
    motionId: 'b8d2cf43-7000-4729-957a-b45a26177b23',
    objectName: 'account',
    operator: 'sum',
    platformConnectionId: 'pfcn_01j0xhkyfsfakbx3k1ayjrvck2',
    platformKey: 'salesforce',
  },
  {
    columnName: 'wootric__current_month_nps__c',
    displayName: 'Average current month NPS',
    goal: 5,
    id: 'met_01jkrky3b5fk3t0rdx2ba55j88',
    motionId: 'b8d2cf43-7000-4729-957a-b45a26177b23',
    objectName: 'account',
    operator: 'average',
    platformConnectionId: 'pfcn_01j0xhkyfsfakbx3k1ayjrvck2',
    platformKey: 'salesforce',
  },
  {
    columnName: 'annualrevenue',
    displayName: 'Annual Revenue',
    goal: 89,
    id: 'met_01jhrkpn55fvhbc011exwxjph0',
    motionId: 'a0e7739e-1d28-44dc-91fc-9bac4d9a9283',
    objectName: 'account',
    operator: 'average',
    platformConnectionId: 'pfcn_01j5y55p1eercbzpczdcf7mksk',
    platformKey: 'salesforce',
  },
  {
    columnName: 'churnProbability',
    displayName: 'Churn Probability',
    goal: 85,
    id: 'met_01jhrkpn55fvhbc011exwxjph1',
    motionId: 'a0e7739e-1d28-44dc-91fc-9bac4d9a9283',
    objectName: 'account',
    operator: 'average',
    platformConnectionId: 'pfcn_01j5y55p1eercbzpczdcf7mksk',
    platformKey: 'magnify',
  },
  {
    columnName: 'expansionProbability',
    displayName: 'Expansion Probability',
    goal: 64,
    id: 'met_01jhrkpn55fvhbc011exwxjph2',
    motionId: 'a0e7739e-1d28-44dc-91fc-9bac4d9a9283',
    objectName: 'account',
    operator: 'average',
    platformConnectionId: 'pfcn_01j5y55p1eercbzpczdcf7mksk',
    platformKey: 'magnify',
  },
  // Tests for the empty chart
  {
    columnName: 'emptyChart',
    displayName: 'Empty Chart',
    goal: 64,
    id: 'missing',
    motionId: 'a0e7739e-1d28-44dc-91fc-9bac4d9a9283',
    objectName: 'account',
    operator: 'average',
    platformConnectionId: 'pfcn_01j5y55p1eercbzpczdcf7mksk',
    platformKey: 'magnify',
  },
  // Useful for testing the chart
  {
    columnName: 'randomProbability',
    displayName: 'Random Probability',
    goal: 64,
    id: 'met_01jhrkpn55fvhbc011exwxjph3',
    motionId: 'a0e7739e-1d28-44dc-91fc-9bac4d9a9283',
    objectName: 'account',
    operator: 'average',
    platformConnectionId: 'pfcn_01j5y55p1eercbzpczdcf7mksk',
    platformKey: 'magnify',
  },
]

/**
 * Adjust dates to match the provided endDate
 * @param {object[]} data The data to adjust
 * @param {string} endDate The end date to match
 * @param {string} [startDate] The start date to match, if provided and before the original start date, null values will be prepended.
 * @returns {object[]} The adjusted data
 */
export const adjustDates = (data: { date: string; value: number | null }[], endDate: string, startDate?: string) => {
  // If there is no data, return the original data
  if (!data.length) return data

  // Parse the end date
  const targetEndDate = new Date(endDate)

  // Get the original end date from the data
  const originalEndDate = new Date(data[data.length - 1].date)

  // Calculate the difference in days
  const daysDifference = Math.floor((targetEndDate.getTime() - originalEndDate.getTime()) / (1000 * 60 * 60 * 24))

  // Adjust all dates by the difference
  const adjustedData = data.map((item) => ({
    date: item.date
      ? new Date(new Date(item.date).getTime() + daysDifference * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
      : null,
    value: item.value,
  }))

  // If startDate is provided, check if we need to prepend null values
  if (startDate) {
    const targetStartDate = new Date(startDate)
    const adjustedStartDate = new Date(adjustedData[0].date as string)

    // If the requested start date is before our data starts, prepend null values
    if (targetStartDate < adjustedStartDate) {
      const daysToAdd = Math.floor((adjustedStartDate.getTime() - targetStartDate.getTime()) / (1000 * 60 * 60 * 24))

      const nullEntries = Array.from({ length: daysToAdd }, (_, index) => ({
        date: new Date(targetStartDate.getTime() + index * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        value: null,
      }))

      return [...nullEntries, ...adjustedData]
    }
  }

  return adjustedData
}

/**
 * Get the motion metric stats for a given metric ID
 * @param {string} interval The interval to get the stats for
 * @param {string} metricId The metric ID to get the stats for
 * @param {string} startDate The start date to get the stats for
 * @param {string} endDate The end date to get the stats for
 * @returns {object[]} The motion metric stats
 */
export const demoGetMotionMetricStats = (interval: string, metricId: string, startDate: string, endDate: string) => {
  // Production Mock Data
  if (metricId === 'met_01jja6t7fcfk6skak97ssp3p7q') {
    // Original mock data
    const originalData = [
      { date: '2025-01-25', value: 5270772 },
      { date: '2025-01-26', value: 5378335 },
      { date: '2025-01-27', value: 6739722 },
      { date: '2025-01-28', value: 6616302 },
      { date: '2025-01-29', value: 6613221 },
      { date: '2025-01-30', value: 3155830 },
      { date: '2025-01-31', value: 9563380 },
      { date: '2025-02-01', value: 5469952 },
      { date: '2025-02-02', value: 2730516 },
      { date: '2025-02-03', value: 6798170 },
      { date: '2025-02-04', value: 6571793 },
      { date: '2025-02-05', value: 6566643 },
      { date: '2025-02-06', value: 6629671 },
      { date: '2025-02-07', value: 6329947 },
      { date: '2025-02-08', value: 5576493 },
      { date: '2025-02-09', value: 5171689 },
      { date: '2025-02-10', value: 6516225 },
      { date: '2025-02-11', value: 6337083 },
      { date: '2025-02-12', value: 6331284 },
      { date: '2025-02-13', value: 6411767 },
      { date: '2025-02-14', value: 3108522 },
      { date: '2025-02-15', value: 5429876 },
      { date: '2025-02-16', value: 5429817 },
      { date: '2025-02-17', value: 6139723 },
      { date: '2025-02-18', value: 6274427 },
      { date: '2025-02-19', value: 6024600 },
      { date: '2025-02-20', value: 6160536 },
      { date: '2025-02-21', value: 6166448 },
      { date: '2025-02-22', value: 5357902 },
      { date: '2025-02-23', value: 5349013 },
      { date: '2025-02-24', value: 6706924 },
      { date: '2025-02-25', value: 3306510 },
      { date: '2025-02-26', value: null },
    ]
    // Return adjusted data based on the provided endDate
    return adjustDates(originalData, endDate)
  }
  if (metricId === 'met_01jja5xtvwf6a83vfs7tamp206') {
    const originalData = [
      { date: '2025-01-25', value: 130041551.17000066 },
      { date: '2025-01-26', value: 130041551.17000076 },
      { date: '2025-01-27', value: 130041551.17000066 },
      { date: '2025-01-28', value: 130041551.17000066 },
      { date: '2025-01-29', value: 130041551.17000076 },
      { date: '2025-01-30', value: 129834578.70000066 },
      { date: '2025-01-31', value: null },
      { date: '2025-02-01', value: null },
      { date: '2025-02-02', value: 126890659.84000073 },
      { date: '2025-02-03', value: 126890659.84000075 },
      { date: '2025-02-04', value: 126718799.50000064 },
      { date: '2025-02-05', value: 126779360.88000056 },
      { date: '2025-02-06', value: 126867974.25000072 },
      { date: '2025-02-07', value: 126801285.19000055 },
      { date: '2025-02-08', value: 127005600.81000058 },
      { date: '2025-02-09', value: null },
      { date: '2025-02-10', value: 127057630.63250086 },
      { date: '2025-02-11', value: 127128643.58250065 },
      { date: '2025-02-12', value: 126662008.29250076 },
      { date: '2025-02-13', value: 7422.26 },
      { date: '2025-02-14', value: 127184546.66250058 },
      { date: '2025-02-15', value: null },
      { date: '2025-02-16', value: 125768197.79250064 },
      { date: '2025-02-17', value: 127136972.77250063 },
      { date: '2025-02-18', value: 127158169.16250071 },
      { date: '2025-02-19', value: 127516900.26250075 },
      { date: '2025-02-20', value: 127324241.51250069 },
      { date: '2025-02-21', value: 127516900.26250057 },
      { date: '2025-02-22', value: 127452203.37250069 },
      { date: '2025-02-23', value: 127402840.0325008 },
      { date: '2025-02-24', value: 127041920.83250046 },
      { date: '2025-02-25', value: 127062304.52250072 },
      { date: '2025-02-26', value: 127160112.22250037 },
    ]
    // Return adjusted data based on the provided endDate
    return adjustDates(originalData, endDate)
  }
  if (metricId === 'met_01jkrky3b5fk3t0rdx2ba55j88') {
    const originalData = [
      { date: '2025-01-25', value: null },
      { date: '2025-01-26', value: null },
      { date: '2025-01-27', value: null },
      { date: '2025-01-28', value: null },
      { date: '2025-01-29', value: null },
      { date: '2025-01-30', value: null },
      { date: '2025-01-31', value: null },
      { date: '2025-02-01', value: null },
      { date: '2025-02-02', value: null },
      { date: '2025-02-03', value: null },
      { date: '2025-02-04', value: null },
      { date: '2025-02-05', value: null },
      { date: '2025-02-06', value: null },
      { date: '2025-02-07', value: null },
      { date: '2025-02-08', value: null },
      { date: '2025-02-09', value: null },
      { date: '2025-02-10', value: null },
      { date: '2025-02-11', value: 4.080176211453744 },
      { date: '2025-02-12', value: 4.028537805236835 },
      { date: '2025-02-13', value: 0 },
      { date: '2025-02-14', value: 4.077781039037276 },
      { date: '2025-02-15', value: null },
      { date: '2025-02-16', value: 4.051564828614009 },
      { date: '2025-02-17', value: 4.080176211453744 },
      { date: '2025-02-18', value: 4.078978273634762 },
      { date: '2025-02-19', value: 4.077781039037276 },
      { date: '2025-02-20', value: 4.092194403534609 },
      { date: '2025-02-21', value: 4.077781039037276 },
      { date: '2025-02-22', value: 4.078978273634762 },
      { date: '2025-02-23', value: 4.080176211453744 },
      { date: '2025-02-24', value: 4.063936358279316 },
      { date: '2025-02-25', value: 4.089785104503974 },
      { date: '2025-02-26', value: 4.082574199235968 },
    ]
    // Return adjusted data based on the provided endDate
    return adjustDates(originalData, endDate)
  }
  // Hand Made Mock Data
  if (metricId === 'met_01jhrkpn55fvhbc011exwxjph0') {
    const originalData = [
      { date: '2024-08-25', value: 79 },
      { date: '2024-08-26', value: 63 },
      { date: '2024-08-27', value: 57 },
      { date: '2024-08-28', value: 64 },
      { date: '2024-08-29', value: 79 },
      { date: '2024-08-30', value: 74 },
    ]
    // Return adjusted data based on the provided endDate
    return adjustDates(originalData, endDate)
  }
  if (metricId === 'met_01jhrkpn55fvhbc011exwxjph1') {
    const originalData = [
      { date: '2024-08-25', value: 79 },
      { date: '2024-08-26', value: 63 },
      { date: '2024-08-27', value: 57 },
      { date: '2024-08-28', value: 64 },
      { date: '2024-08-29', value: 79 },
      { date: '2024-08-30', value: 74 },
      { date: '2024-08-31', value: 27 },
      { date: '2024-09-01', value: 85 },
      { date: '2024-09-02', value: 30 },
      { date: '2024-09-03', value: 76 },
      { date: '2024-09-04', value: 55 },
      { date: '2024-09-05', value: 42 },
      { date: '2024-09-06', value: 72 },
      { date: '2024-09-07', value: 39 },
      { date: '2024-09-08', value: 47 },
      { date: '2024-09-09', value: 82 },
      { date: '2024-09-10', value: 46 },
      { date: '2024-09-11', value: 51 },
      { date: '2024-09-12', value: 90 },
      { date: '2024-09-13', value: 32 },
      { date: '2024-09-14', value: 29 },
      { date: '2024-09-15', value: 58 },
      { date: '2024-09-16', value: 42 },
      { date: '2024-09-17', value: 66 },
      { date: '2024-09-18', value: 73 },
      { date: '2024-09-19', value: 25 },
      { date: '2024-09-20', value: 45 },
      { date: '2024-09-21', value: 82 },
      { date: '2024-09-22', value: 35 },
      { date: '2024-09-23', value: 63 },
      { date: '2024-09-24', value: 75 },
      { date: '2024-09-25', value: 76 },
      { date: '2024-09-26', value: 54 },
      { date: '2024-09-27', value: 45 },
      { date: '2024-09-28', value: 71 },
      { date: '2024-09-29', value: 74 },
      { date: '2024-09-30', value: 76 },
      { date: '2024-10-01', value: 71 },
      { date: '2024-10-02', value: 72 },
      { date: '2024-10-03', value: 25 },
      { date: '2024-10-04', value: 82 },
      { date: '2024-10-05', value: 66 },
      { date: '2024-10-06', value: 35 },
      { date: '2024-10-07', value: 43 },
      { date: '2024-10-08', value: 60 },
      { date: '2024-10-09', value: 57 },
      { date: '2024-10-10', value: 31 },
      { date: '2024-10-11', value: 74 },
      { date: '2024-10-12', value: 41 },
      { date: '2024-10-13', value: 40 },
      { date: '2024-10-14', value: 62 },
      { date: '2024-10-15', value: 77 },
      { date: '2024-10-16', value: 78 },
      { date: '2024-10-17', value: 48 },
      { date: '2024-10-18', value: 39 },
      { date: '2024-10-19', value: 82 },
      { date: '2024-10-20', value: 79 },
      { date: '2024-10-21', value: 76 },
      { date: '2024-10-22', value: 38 },
      { date: '2024-10-23', value: 32 },
      { date: '2024-10-24', value: 77 },
      { date: '2024-10-25', value: 75 },
      { date: '2024-10-26', value: 79 },
      { date: '2024-10-27', value: 86 },
      { date: '2024-10-28', value: 86 },
      { date: '2024-10-29', value: 79 },
      { date: '2024-10-30', value: 46 },
      { date: '2024-10-31', value: 88 },
      { date: '2024-11-01', value: 45 },
      { date: '2024-11-02', value: 68 },
      { date: '2024-11-03', value: 60 },
      { date: '2024-11-04', value: 51 },
      { date: '2024-11-05', value: 40 },
      { date: '2024-11-06', value: 53 },
      { date: '2024-11-07', value: 44 },
      { date: '2024-11-08', value: 38 },
      { date: '2024-11-09', value: 80 },
      { date: '2024-11-10', value: 76 },
      { date: '2024-11-11', value: 53 },
      { date: '2024-11-12', value: 90 },
      { date: '2024-11-13', value: 72 },
      { date: '2024-11-14', value: 75 },
      { date: '2024-11-15', value: 89 },
      { date: '2024-11-16', value: 77 },
      { date: '2024-11-17', value: 38 },
      { date: '2024-11-18', value: 89 },
      { date: '2024-11-19', value: 73 },
      { date: '2024-11-20', value: 81 },
      { date: '2024-11-21', value: 68 },
      { date: '2024-11-22', value: 56 },
      { date: '2024-11-23', value: 35 },
      { date: '2024-11-24', value: 82 },
      { date: '2024-11-25', value: 83 },
      { date: '2024-11-26', value: 62 },
      { date: '2024-11-27', value: 57 },
      { date: '2024-11-28', value: 79 },
      { date: '2024-11-29', value: 43 },
      { date: '2024-11-30', value: 56 },
      { date: '2024-12-01', value: 35 },
      { date: '2024-12-02', value: 38 },
      { date: '2024-12-03', value: 58 },
      { date: '2024-12-04', value: 35 },
      { date: '2024-12-05', value: 65 },
      { date: '2024-12-06', value: 52 },
      { date: '2024-12-07', value: 40 },
      { date: '2024-12-08', value: 45 },
      { date: '2024-12-09', value: 50 },
      { date: '2024-12-10', value: 39 },
      { date: '2024-12-11', value: 73 },
      { date: '2024-12-12', value: 34 },
      { date: '2024-12-13', value: 47 },
      { date: '2024-12-14', value: 44 },
      { date: '2024-12-15', value: 88 },
      { date: '2024-12-16', value: 82 },
      { date: '2024-12-17', value: 38 },
      { date: '2024-12-18', value: 54 },
      { date: '2024-12-19', value: 28 },
      { date: '2024-12-20', value: 57 },
      { date: '2024-12-21', value: 57 },
      { date: '2024-12-22', value: 74 },
      { date: '2024-12-23', value: 60 },
      { date: '2024-12-24', value: 44 },
      { date: '2024-12-25', value: 68 },
      { date: '2024-12-26', value: 32 },
      { date: '2024-12-27', value: 58 },
      { date: '2024-12-28', value: 30 },
      { date: '2024-12-29', value: 33 },
      { date: '2024-12-30', value: 67 },
      { date: '2024-12-31', value: 68 },
      { date: '2025-01-01', value: 33 },
      { date: '2025-01-02', value: 74 },
      { date: '2025-01-03', value: 87 },
      { date: '2025-01-04', value: 77 },
      { date: '2025-01-05', value: 75 },
      { date: '2025-01-06', value: 83 },
      { date: '2025-01-07', value: 36 },
      { date: '2025-01-08', value: 42 },
      { date: '2025-01-09', value: 42 },
      { date: '2025-01-10', value: 43 },
      { date: '2025-01-11', value: 65 },
      { date: '2025-01-12', value: 50 },
      { date: '2025-01-13', value: 51 },
      { date: '2025-01-14', value: 84 },
      { date: '2025-01-15', value: 43 },
      { date: '2025-01-16', value: 29 },
      { date: '2025-01-17', value: 50 },
      { date: '2025-01-18', value: 47 },
      { date: '2025-01-19', value: 73 },
      { date: '2025-01-20', value: 34 },
      { date: '2025-01-21', value: 74 },
      { date: '2025-01-22', value: 41 },
      { date: '2025-01-23', value: 79 },
      { date: '2025-01-24', value: 90 },
      { date: '2025-01-25', value: 60 },
      { date: '2025-01-26', value: 51 },
      { date: '2025-01-27', value: 33 },
      { date: '2025-01-28', value: 37 },
      { date: '2025-01-29', value: 42 },
      { date: '2025-01-30', value: 73 },
      { date: '2025-01-31', value: 49 },
      { date: '2025-02-01', value: 58 },
      { date: '2025-02-02', value: 40 },
      { date: '2025-02-03', value: 82 },
      { date: '2025-02-04', value: 78 },
      { date: '2025-02-05', value: 75 },
      { date: '2025-02-06', value: 60 },
      { date: '2025-02-07', value: 25 },
      { date: '2025-02-08', value: 63 },
      { date: '2025-02-09', value: 38 },
      { date: '2025-02-10', value: 31 },
      { date: '2025-02-11', value: 58 },
      { date: '2025-02-12', value: 85 },
      { date: '2025-02-13', value: 49 },
      { date: '2025-02-14', value: 25 },
      { date: '2025-02-15', value: 81 },
      { date: '2025-02-16', value: 44 },
      { date: '2025-02-17', value: 63 },
      { date: '2025-02-18', value: 43 },
      { date: '2025-02-19', value: 43 },
      { date: '2025-02-20', value: 82 },
      { date: '2025-02-21', value: 25 },
    ]
    // Return adjusted data based on the provided endDate
    return adjustDates(originalData, endDate)
  }
  if (metricId === 'met_01jhrkpn55fvhbc011exwxjph2') {
    const originalData = [
      { date: '2024-08-25', value: 25 },
      { date: '2024-08-26', value: 62 },
      { date: '2024-08-27', value: 52 },
      { date: '2024-08-28', value: 56 },
      { date: '2024-08-29', value: 33 },
      { date: '2024-08-30', value: 37 },
      { date: '2024-08-31', value: 77 },
      { date: '2024-09-01', value: 73 },
      { date: '2024-09-02', value: 41 },
      { date: '2024-09-03', value: 89 },
      { date: '2024-09-04', value: 69 },
      { date: '2024-09-05', value: 40 },
      { date: '2024-09-06', value: 33 },
      { date: '2024-09-07', value: 72 },
      { date: '2024-09-08', value: 49 },
      { date: '2024-09-09', value: 66 },
      { date: '2024-09-10', value: 40 },
      { date: '2024-09-11', value: 41 },
      { date: '2024-09-12', value: 86 },
      { date: '2024-09-13', value: 70 },
      { date: '2024-09-14', value: 71 },
      { date: '2024-09-15', value: 38 },
      { date: '2024-09-16', value: 25 },
      { date: '2024-09-17', value: 53 },
      { date: '2024-09-18', value: 47 },
      { date: '2024-09-19', value: 62 },
      { date: '2024-09-20', value: 60 },
      { date: '2024-09-21', value: 59 },
      { date: '2024-09-22', value: 68 },
      { date: '2024-09-23', value: 47 },
      { date: '2024-09-24', value: 65 },
      { date: '2024-09-25', value: 36 },
      { date: '2024-09-26', value: 35 },
      { date: '2024-09-27', value: 36 },
      { date: '2024-09-28', value: 83 },
      { date: '2024-09-29', value: 68 },
      { date: '2024-09-30', value: 78 },
      { date: '2024-10-01', value: 27 },
      { date: '2024-10-02', value: 52 },
      { date: '2024-10-03', value: 84 },
      { date: '2024-10-04', value: 76 },
      { date: '2024-10-05', value: 34 },
      { date: '2024-10-06', value: 28 },
      { date: '2024-10-07', value: 31 },
      { date: '2024-10-08', value: 72 },
      { date: '2024-10-09', value: 90 },
      { date: '2024-10-10', value: 64 },
      { date: '2024-10-11', value: 80 },
      { date: '2024-10-12', value: 87 },
      { date: '2024-10-13', value: 44 },
      { date: '2024-10-14', value: 66 },
      { date: '2024-10-15', value: 58 },
      { date: '2024-10-16', value: 81 },
      { date: '2024-10-17', value: 69 },
      { date: '2024-10-18', value: 66 },
      { date: '2024-10-19', value: 32 },
      { date: '2024-10-20', value: 90 },
      { date: '2024-10-21', value: 51 },
      { date: '2024-10-22', value: 62 },
      { date: '2024-10-23', value: 65 },
      { date: '2024-10-24', value: 58 },
      { date: '2024-10-25', value: 32 },
      { date: '2024-10-26', value: 74 },
      { date: '2024-10-27', value: 73 },
      { date: '2024-10-28', value: 89 },
      { date: '2024-10-29', value: 33 },
      { date: '2024-10-30', value: 40 },
      { date: '2024-10-31', value: 60 },
      { date: '2024-11-01', value: 43 },
      { date: '2024-11-02', value: 48 },
      { date: '2024-11-03', value: 31 },
      { date: '2024-11-04', value: 68 },
      { date: '2024-11-05', value: 29 },
      { date: '2024-11-06', value: 73 },
      { date: '2024-11-07', value: 37 },
      { date: '2024-11-08', value: 75 },
      { date: '2024-11-09', value: 41 },
      { date: '2024-11-10', value: 28 },
      { date: '2024-11-11', value: 50 },
      { date: '2024-11-12', value: 37 },
      { date: '2024-11-13', value: 81 },
      { date: '2024-11-14', value: 31 },
      { date: '2024-11-15', value: 83 },
      { date: '2024-11-16', value: 61 },
      { date: '2024-11-17', value: 46 },
      { date: '2024-11-18', value: 70 },
      { date: '2024-11-19', value: 57 },
      { date: '2024-11-20', value: 57 },
      { date: '2024-11-21', value: 27 },
      { date: '2024-11-22', value: 79 },
      { date: '2024-11-23', value: 34 },
      { date: '2024-11-24', value: 67 },
      { date: '2024-11-25', value: 27 },
      { date: '2024-11-26', value: 79 },
      { date: '2024-11-27', value: 57 },
      { date: '2024-11-28', value: 67 },
      { date: '2024-11-29', value: 38 },
      { date: '2024-11-30', value: 87 },
      { date: '2024-12-01', value: 48 },
      { date: '2024-12-02', value: 75 },
      { date: '2024-12-03', value: 70 },
      { date: '2024-12-04', value: 33 },
      { date: '2024-12-05', value: 42 },
      { date: '2024-12-06', value: 61 },
      { date: '2024-12-07', value: 36 },
      { date: '2024-12-08', value: 70 },
      { date: '2024-12-09', value: 43 },
      { date: '2024-12-10', value: 76 },
      { date: '2024-12-11', value: 78 },
      { date: '2024-12-12', value: 60 },
      { date: '2024-12-13', value: 41 },
      { date: '2024-12-14', value: 26 },
      { date: '2024-12-15', value: 90 },
      { date: '2024-12-16', value: 86 },
      { date: '2024-12-17', value: 31 },
      { date: '2024-12-18', value: 90 },
      { date: '2024-12-19', value: 30 },
      { date: '2024-12-20', value: 38 },
      { date: '2024-12-21', value: 79 },
      { date: '2024-12-22', value: 76 },
      { date: '2024-12-23', value: 82 },
      { date: '2024-12-24', value: 37 },
      { date: '2024-12-25', value: 75 },
      { date: '2024-12-26', value: 59 },
      { date: '2024-12-27', value: 79 },
      { date: '2024-12-28', value: 48 },
      { date: '2024-12-29', value: 44 },
      { date: '2024-12-30', value: 49 },
      { date: '2024-12-31', value: 66 },
      { date: '2025-01-01', value: 63 },
      { date: '2025-01-02', value: 42 },
      { date: '2025-01-03', value: 33 },
      { date: '2025-01-04', value: 79 },
      { date: '2025-01-05', value: 57 },
      { date: '2025-01-06', value: 54 },
      { date: '2025-01-07', value: 26 },
      { date: '2025-01-08', value: 66 },
      { date: '2025-01-09', value: 80 },
      { date: '2025-01-10', value: 53 },
      { date: '2025-01-11', value: 46 },
      { date: '2025-01-12', value: 59 },
      { date: '2025-01-13', value: 43 },
      { date: '2025-01-14', value: 87 },
      { date: '2025-01-15', value: 71 },
      { date: '2025-01-16', value: 50 },
      { date: '2025-01-17', value: 55 },
      { date: '2025-01-18', value: 40 },
      { date: '2025-01-19', value: 64 },
      { date: '2025-01-20', value: 78 },
      { date: '2025-01-21', value: 46 },
      { date: '2025-01-22', value: 33 },
      { date: '2025-01-23', value: 33 },
      { date: '2025-01-24', value: 47 },
      { date: '2025-01-25', value: 88 },
      { date: '2025-01-26', value: 38 },
      { date: '2025-01-27', value: 50 },
      { date: '2025-01-28', value: 63 },
      { date: '2025-01-29', value: 84 },
      { date: '2025-01-30', value: 77 },
      { date: '2025-01-31', value: 69 },
      { date: '2025-02-01', value: 61 },
      { date: '2025-02-02', value: 72 },
      { date: '2025-02-03', value: 52 },
      { date: '2025-02-04', value: 27 },
      { date: '2025-02-05', value: 33 },
      { date: '2025-02-06', value: 43 },
      { date: '2025-02-07', value: 42 },
      { date: '2025-02-08', value: 31 },
      { date: '2025-02-09', value: 86 },
      { date: '2025-02-10', value: 87 },
      { date: '2025-02-11', value: 85 },
      { date: '2025-02-12', value: 57 },
      { date: '2025-02-13', value: 38 },
      { date: '2025-02-14', value: 53 },
      { date: '2025-02-15', value: 37 },
      { date: '2025-02-16', value: 43 },
      { date: '2025-02-17', value: 46 },
      { date: '2025-02-18', value: 76 },
      { date: '2025-02-19', value: 72 },
      { date: '2025-02-20', value: 51 },
      { date: '2025-02-21', value: 40 },
    ]
    // Return adjusted data based on the provided endDate
    return adjustDates(originalData, endDate)
  }
  if (metricId === 'missing') {
    return []
  }

  // For the default case, generate data for the requested date range
  const start = new Date(startDate)
  const end = new Date(endDate)
  const result = []

  const currentDate = new Date(start)
  while (currentDate <= end) {
    result.push({
      date: currentDate.toISOString().split('T')[0],
      value: Math.floor(Math.random() * 100) + 1, // Random value between 1 and 100
    })
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return result
}
