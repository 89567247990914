import { EllipsisOutlined } from '@ant-design/icons'
import { Dropdown, Form, Popover, Button as CloseButton, Tooltip, Select, Checkbox, InputNumber } from 'antd'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useMemo, useState } from 'react'

import { DataSourceContainer, DropdownArrowDown, STRING_TYPE } from 'components/common'
import { checkTypeEquality, isDyanmicField } from 'components/common/DataSourceContainer/index.utils'
import EditWarningModal from 'components/EmailTemplates/modals/EditWarningModal'
import FieldInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput'
import type { AcceptedValues } from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/utils'
import { allowDynamicInput } from 'components/MotionBuilder/Utils/serviceUtils'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import EmailTemplateModal from 'pages/Motions/EmailTemplateModal'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { MenuProps } from 'antd'

import { FieldTypeEnum } from 'models/metadata.model'
import type { MetadataField } from 'models/motion/dynamicInput.model'
import type { CreateActionFields } from 'models/motion/motionBuilder.model'
import { ActionTypeEnum, RelativeDateInterval, RelativeDateOperation } from 'models/motion/motionBuilder.model'
import { MotionStateEnum, NodeTypeEnum } from 'models/motion.model'

interface SingleInputFieldProps {
  index: number
  item: CreateActionFields
  actionType: ActionTypeEnum
  removeTag: (fieldKey: string | undefined) => void
  addDynamicValue: (item: MetadataField, fieldKey: string | undefined) => void
  changeFieldValue: (value: AcceptedValues, fieldKey?: string, index?: number) => void
  disabled?: boolean
  payloadFields?: CreateActionFields[]
}

const SingleInputField = observer(
  ({
    index,
    item,
    actionType,
    removeTag,
    addDynamicValue,
    changeFieldValue,
    disabled = false,
    payloadFields,
  }: SingleInputFieldProps) => {
    const { segmentBuilderData } = useContext(SegmentBuilderContext)
    const { dynamicInputStore, motionStore } = useStore()
    const { fetchDynamicInputsMetadata, resetSearch } = dynamicInputStore
    const { currentMotion } = motionStore
    const [displayDynamicFieldPopover, setDisplayDynamicFieldPopover] = useState<boolean>(false)
    const [showEmailModal, setShowEmailModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isEditEmailModalOpen, setIsEditEmailModalOpen] = useState(false)
    const [isRelativeDate, setIsRelativeDate] = useState(false)
    const isAction = useMemo(() => segmentBuilderData.type === NodeTypeEnum.Action, [segmentBuilderData.type])
    const { relativeDates } = useFlags<LaunchDarklyFeatureFlags>()

    useDisplayErrorNotification(dynamicInputStore)

    // If the field has a calculation, set the relative date checkbox to true
    useEffect(() => {
      if (item.calculation) {
        setIsRelativeDate(true)
      }
    }, [item.calculation])

    // Return null early if the action is a slack action and the input is a hidden input
    if (item.platform === 'slack' && item.type === 'hidden') {
      return null
    }

    const enableDynamicInput = allowDynamicInput(item) && !motionStore.isSegmentBuilderEditDisabled

    const openDynamicFieldPopup = (value: boolean) => {
      if (!displayDynamicFieldPopover) {
        fetchDynamicInputsMetadata(item, isAction).catch(console.error)
      }
      setDisplayDynamicFieldPopover(value)
    }

    const onOpenChange = (value: boolean) => {
      if (!displayDynamicFieldPopover && item.onlyDynamicInput) {
        fetchDynamicInputsMetadata(item, isAction).catch(console.error)
      }
      setTimeout(() => {
        if (displayDynamicFieldPopover || item.onlyDynamicInput) {
          setDisplayDynamicFieldPopover(value)
        }
      }, 1)

      resetSearch()
    }

    const handleEditTemplate = () => {
      setIsEditEmailModalOpen(false)
      setIsEdit(true)
      setShowEmailModal(true)
    }

    const handleCloseEmailTemplateModal = () => {
      setIsEdit(false)
      setShowEmailModal(false)
    }

    const items: MenuProps['items'] = [
      {
        label: 'Remove Template',
        key: 'remove-template',
        className: 'dropdown__item',
        onClick: () => {
          changeFieldValue('', 'templateId')
          changeFieldValue('', 'templateVersion')
          changeFieldValue('', 'template')
        },
      },
    ]

    const isTemplateName = item.name === 'Email' && item.platform === 'magnify'

    return (
      <Popover
        key={item.key}
        trigger='click'
        open={!disabled && displayDynamicFieldPopover}
        placement='bottomRight'
        overlayClassName='data-field__popover dynamic-field__popover'
        showArrow={false}
        onOpenChange={onOpenChange}
        content={
          <DataSourceContainer
            currentItem={item}
            handleSelectDataField={(field) => {
              addDynamicValue(field, item.key)
              openDynamicFieldPopup(false)
            }}
          />
        }>
        <Form.Item
          colon={false}
          name={['user-', `${item.key}-${index}`]}
          label={!isTemplateName && item.name}
          initialValue={item.name}
          className={classNames({
            'dynamic-field-popover--active': displayDynamicFieldPopover,
            'dynamic-field': enableDynamicInput,
            'only-dynamic-field': item.onlyDynamicInput,
            [`type-${item.type}`]: true,
          })}
          validateStatus={item.error ? 'error' : ''}
          hasFeedback={item.error}
          rules={[
            {
              required: item.required && actionType !== ActionTypeEnum.Update,
              message: 'This field is required',
              whitespace: true,
            },
          ]}>
          <Tooltip placement='top' title={item.tooltip} data-testid='tooltip'>
            <>
              {isTemplateName && (
                <>
                  <div className='template-label'>
                    <p className='template-label__text'>
                      {item.name} <span className='template-label__text--required'>*</span>{' '}
                      {item.value && (
                        <span className='template-label__text--link' onClick={() => setIsEditEmailModalOpen(true)}>
                          (Edit)
                        </span>
                      )}
                    </p>
                    {item.value && currentMotion?.currState !== MotionStateEnum.Executing && (
                      <Dropdown
                        overlayClassName='motions-table-row-actions'
                        menu={{ items }}
                        trigger={['click']}
                        placement='bottomRight'
                        disabled={motionStore.isSegmentBuilderEditDisabled}>
                        <CloseButton
                          className='motions-table-row-actions-button'
                          disabled={motionStore.isSegmentBuilderEditDisabled}>
                          <EllipsisOutlined className='dropdown__icon' style={{ fontSize: '18px' }} />
                        </CloseButton>
                      </Dropdown>
                    )}
                  </div>
                  <EmailTemplateModal
                    open={showEmailModal}
                    onCancel={() => handleCloseEmailTemplateModal()}
                    onConfirm={() => handleCloseEmailTemplateModal()}
                    selectedTemplate={item.value as string}
                    changeFieldValue={changeFieldValue}
                    isInActionFlow
                    initializeEdit={{
                      isEdit,
                      templateId: (payloadFields?.find((field) => field.key === 'templateId')?.value as string) || null,
                      templateVersion:
                        (payloadFields?.find((field) => field.key === 'templateVersion')?.value as number) || null,
                    }}
                  />
                  <EditWarningModal
                    isModalOpen={isEditEmailModalOpen}
                    actionConfirm={handleEditTemplate}
                    setIsModalOpen={setIsEditEmailModalOpen}
                  />
                </>
              )}

              {/* This exists here due to the Dynamic Input button being relatively placed, and if part of Field it breaks in a way that requires a large refactor. */}
              {relativeDates &&
                [FieldTypeEnum.Date, FieldTypeEnum.Datetime, FieldTypeEnum.Datepicker].includes(
                  item.type as FieldTypeEnum,
                ) && (
                  <div className='date-calculation-container' data-testid='date-calculation-container'>
                    <div
                      className='date-calculation-container-checkbox'
                      data-testid='date-calculation-container-checkbox'>
                      <Checkbox
                        data-testid='criteria-input-field-relative-date-checkbox'
                        checked={isRelativeDate}
                        onChange={(e) => {
                          setIsRelativeDate(e.target.checked)
                          if (!e.target.checked) {
                            changeFieldValue({ remove_calculation: true } as CreateActionFields, item?.key)
                          } else {
                            changeFieldValue(
                              {
                                calculation: {
                                  amount: 1,
                                  interval: RelativeDateInterval.DAY,
                                  operation: RelativeDateOperation.ADD,
                                },
                              } as CreateActionFields,
                              item?.key,
                            )
                          }
                        }}>
                        Relative date?
                      </Checkbox>
                    </div>
                    <div className='date-calculation-container-inputs' data-testid='date-calculation-container-inputs'>
                      <InputNumber
                        min={1}
                        placeholder='1'
                        data-testid='criteria-input-field-relative-number'
                        size='small'
                        onChange={(value: number | null) => {
                          // Set the relative time amount
                          changeFieldValue({ calculation: { amount: value } } as CreateActionFields, item?.key)
                        }}
                        controls={{ upIcon: <DropdownArrowDown />, downIcon: <DropdownArrowDown /> }}
                        disabled={!isRelativeDate}
                        value={item?.calculation?.amount}
                      />
                      <Select
                        data-testid='criteria-input-field-relative-unit'
                        size='small'
                        placeholder='Days'
                        options={[
                          { label: 'Days', value: RelativeDateInterval.DAY },
                          { label: 'Weeks', value: RelativeDateInterval.WEEK },
                          { label: 'Years', value: RelativeDateInterval.YEAR },
                        ]}
                        onChange={(value: string) => {
                          // Set the relative time interval
                          changeFieldValue({ calculation: { interval: value } } as CreateActionFields, item?.key)
                        }}
                        disabled={!isRelativeDate}
                        value={item?.calculation?.interval}
                      />
                      <Select
                        data-testid='criteria-input-field-relative-direction'
                        size='small'
                        placeholder='After'
                        options={[
                          { label: 'Before', value: RelativeDateOperation.SUB },
                          { label: 'After', value: RelativeDateOperation.ADD },
                        ]}
                        onChange={(value: string) => {
                          // Set the relative time direction
                          changeFieldValue({ calculation: { operation: value } } as CreateActionFields, item?.key)
                        }}
                        disabled={!isRelativeDate}
                        value={item?.calculation?.operation}
                      />
                    </div>
                  </div>
                )}

              <FieldInput
                borderless={false}
                changeFieldValue={changeFieldValue}
                disabled={disabled || motionStore.isSegmentBuilderEditDisabled}
                displayDynamicFieldPopover={displayDynamicFieldPopover}
                enableDynamicInput={enableDynamicInput}
                isAction={true}
                isDynamicField={isDyanmicField(item) || checkTypeEquality(item.type, STRING_TYPE)}
                item={item}
                openDynamicFieldPopup={openDynamicFieldPopup}
                payloadFields={payloadFields}
                range={false}
                removeTag={() => removeTag(item.key)}
              />
            </>
          </Tooltip>
        </Form.Item>
      </Popover>
    )
  },
)
SingleInputField.displayName = 'SingleInputField'

export default SingleInputField
