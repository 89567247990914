import axios, { isAxiosError } from 'axios'

import { type DemoAxiosRequestConfig } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import {
  demoGetAccountDetailsOverall,
  demoGetDimensions,
  demoGetParticipatedMotionsInsights,
  demoGetRevenueRetentionForecastOverall,
  demoTenantAccountInsights,
  getAllDemoInsights,
  getDriverSegment,
  getDriverSegmentAccounts,
  getFilteredAndSortedForecast,
} from 'api/mockResponses/demo/insights.mock'
import { getBaseUrl } from 'api/utils'
import Demo from 'configs/demo'
import Sandbox from 'configs/sandbox'
import {
  participated_motions_payload_demo,
  participated_motions_payload_sandbox,
} from 'pages/AccountDetails/mockData/participated_motions_payload'
import { LoggerService } from 'services/LogService/LogService'

import type { AccountDetails, ParticipatedMotion } from 'models/account.model'
import type {
  AccountForecast,
  DriverSegmentAccounts,
  InsightDimensions,
  InsightsV2,
  InsightV2,
  RevenueRetentionForecast,
  SetTargetPost,
  TenantInsightAccounts,
} from 'models/insights'

/**
 * Builds a URL with the given base and parameters.
 * @param {string} base - The base URL.
 * @param {Record<string, unknown>} params - The parameters to include in the URL.
 * @returns {string} The built URL.
 */
export const buildUrl = (base: string, params: Record<string, unknown>) => {
  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== null && value !== '')
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
    .join('&')
  return `${base}?${query}`
}

/**
 * Fetches the collection of dimensions for the current tenant to pivot the insights on.
 */
export const getDimensions = async () => {
  try {
    const { data } = await axios.get<InsightDimensions>(`${getBaseUrl('CORE_API')}/v2/core/insights/dimensions`, {
      demoData: demoGetDimensions,
      sandboxUseDemoData: true,
      headers: {
        'x-magnify-sandbox': Sandbox.isEnabled(),
      },
    } as DemoAxiosRequestConfig)
    return data
  } catch (error: unknown) {
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      // Handle edge case here where we don't want to display the error notification - context: MAGPROD-3599
      if (error.response?.status === 404) {
        return null
      }
      LoggerService.error({ message: 'insights getDimensions error', error })
      throw new CoreApiError(error.response.data)
    } else {
      LoggerService.error({ message: 'insights getDimensions error', error })
      throw new Error('Failed to fetch dimensions')
    }
  }
}

/**
 * Fetches all insights associated with a particular tenant - dashboard view
 */
export const getAllInsights = async ({ dimension }: { dimension: string }): Promise<InsightsV2 | null> => {
  try {
    const { data } = await axios.get<InsightsV2>(`${getBaseUrl('CORE_API')}/v2/core/insights/${dimension}`, {
      demoData: getAllDemoInsights({ dimension }),
      sandboxUseDemoData: true,
      headers: {
        'x-magnify-sandbox': Sandbox.isEnabled(),
      },
    } as DemoAxiosRequestConfig)

    // We need to backfill the dev environment tenant with data that cannot be regenerated on the ML side.
    for (const event of Object.values(data.events)) {
      for (const driver of Object.values(event.drivers)) {
        for (const segment of Object.values(driver.segments)) {
          for (const metric of Object.values(segment.metrics)) {
            // We need to check for "nan" string values and convert them to 0
            if (typeof metric.improve_from === 'string' && metric.improve_from === 'nan') {
              metric.improve_from = 0
            }
            // We need to check for "nan" string values and convert them to 0
            if (typeof metric.improve_to === 'string' && metric.improve_to === 'nan') {
              metric.improve_to = 0
            }
          }

          // Segment Revenue
          if (typeof segment.revenue.past === 'string' && segment.revenue.past === 'nan') {
            segment.revenue.past = 0
          }
          if (typeof segment.revenue.elift_improve_from === 'string' && segment.revenue.elift_improve_from === 'nan') {
            segment.revenue.elift_improve_from = 0
          }
          if (typeof segment.revenue.elift_improve_to === 'string' && segment.revenue.elift_improve_to === 'nan') {
            segment.revenue.elift_improve_to = 0
          }
          if (typeof segment.revenue.elift_improve_by === 'string' && segment.revenue.elift_improve_by === 'nan') {
            segment.revenue.elift_improve_by = 0
          }

          // Segment Propensity
          // We need to check for "nan" string values and convert them to 0
          if (typeof segment.propensity.improve_from === 'string' && segment.propensity.improve_from === 'nan') {
            segment.propensity.improve_from = 0
          }
          if (!segment.propensity.improve_from_text || segment.propensity.improve_from_text === 'nan') {
            segment.propensity.improve_from_text = `${(segment.propensity.improve_from * 100).toFixed(1)}%`
          }
          // We need to check for "nan" string values and convert them to 0
          if (typeof segment.propensity.improve_to === 'string' && segment.propensity.improve_to === 'nan') {
            segment.propensity.improve_to = 0
          }
          if (!segment.propensity.improve_to_text || segment.propensity.improve_to_text === 'nan') {
            segment.propensity.improve_to_text = `${(segment.propensity.improve_to * 100).toFixed(1)}%`
          }
        }
      }
    }

    return data
  } catch (error: unknown) {
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      // Handle edge case here where we don't want to display the error notification
      if (error.response?.status === 404) {
        return null
      }
      LoggerService.error({ message: 'insights getAllInsights error', error: error })
      throw new CoreApiError(error.response.data)
    } else {
      LoggerService.error({ message: 'insights getAllInsights error', error: error })
      throw new Error('Failed to fetch insights')
    }
  }
}

/**
 * Fetches an individual insight associated with a particular driver - insight details view
 */
export const getInsightSegmentInfo = async ({
  dimension,
  target,
  id,
}: {
  dimension: string
  target: string
  id: string
}): Promise<InsightV2> => {
  try {
    const { data } = await axios.get<InsightV2>(
      `${getBaseUrl('CORE_API')}/v2/core/insights/segment/${dimension}/target/${target}/id/${id}`,
      {
        demoData: getDriverSegment({ dimension, target }),
        sandboxUseDemoData: true,
        headers: {
          'x-magnify-sandbox': Sandbox.isEnabled(),
        },
      } as DemoAxiosRequestConfig,
    )

    // We need to backfill the dev environment tenant with data that cannot be regenerated on the ML side.
    if (typeof data.metric_value === 'string' && data.metric_value === 'nan') {
      data.metric_value = 0
    }

    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'insight getInsightSegmentInfo error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch insight')
    }
  }
}

/**
 * Fetches all accounts that are associated with a particular driver - insight details view
 */
export const getInsightSegmentAccounts = async ({
  dimension,
  id,
  target,
}: {
  dimension: string
  id: string
  target: string
}): Promise<DriverSegmentAccounts> => {
  try {
    const { data } = await axios.get<DriverSegmentAccounts>(
      `${getBaseUrl('CORE_API')}/v2/core/insights/accounts/${dimension}/target/${target}/id/${id}`,
      {
        demoData: getDriverSegmentAccounts({ dimension, target }),
        sandboxUseDemoData: true,
        headers: {
          'x-magnify-sandbox': Sandbox.isEnabled(),
        },
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'insights getInsightSegmentAccounts error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch insights for accounts')
    }
  }
}

/**
 * Fetches all accounts that insights have been generated for at a tenant level - dashboard view
 */
export const getTenantAccountsInsights = async ({ dimension }: { dimension: string }) => {
  try {
    const { data } = await axios.get<TenantInsightAccounts>(
      `${getBaseUrl('CORE_API')}/v2/core/insights/accounts/details/${dimension}`,
      {
        demoData: demoTenantAccountInsights,
        sandboxUseDemoData: true,
        headers: {
          'x-magnify-sandbox': Sandbox.isEnabled(),
        },
      } as DemoAxiosRequestConfig,
    )

    // We need to backfill the dev environment tenant with data that cannot be regenerated on the ML side.
    for (const account of Object.values(data.accounts)) {
      for (const event of Object.values(account.events)) {
        if (!event.propensity_text) {
          event.propensity_text = `${(event.propensity * 100).toFixed(0)}%`
        }
      }
    }

    return data
  } catch (error: unknown) {
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      // Handle edge case here where we don't want to display the error notification
      if (error.response?.status === 404) {
        return null
      }
      LoggerService.error({ message: 'insights getTenantAccountsInsights error', error: error })
      throw new CoreApiError(error.response.data)
    } else {
      LoggerService.error({ message: 'insights getTenantAccountsInsights error', error: error })
      throw new Error('Failed to fetch insights for tenant-wide accounts')
    }
  }
}

/**
 * Fetches ML metrics for a given account id - account details page
 */
export const getAccountDetails = async ({ dimension, id }: { dimension: string; id: string }) => {
  try {
    const { data } = await axios.get<AccountDetails>(
      `${getBaseUrl('CORE_API')}/v2/core/insights/accounts/details/${dimension}/${id}`,
      {
        demoData: demoGetAccountDetailsOverall[
          id as keyof typeof demoGetAccountDetailsOverall
        ] as unknown as AccountDetails,
        sandboxUseDemoData: true,
        headers: {
          'x-magnify-sandbox': Sandbox.isEnabled(),
        },
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'insights getAccountDetails error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch account details')
    }
  }
}

/**
 * Fetches participated Motions for a given account id - account details page
 */
export const getParticipatedMotions = async ({ id }: { id: string }) => {
  try {
    if (Demo.mockApiIsEnabled()) {
      return participated_motions_payload_demo
    } else if (Sandbox.isEnabled()) {
      return participated_motions_payload_sandbox
    } else {
      const { data } = await axios.get<ParticipatedMotion[]>(
        `${getBaseUrl('CORE_API')}/v2/core/insights/accounts/details/participated-journeys/${id}`,
        {
          demoData: demoGetParticipatedMotionsInsights,
          sandboxUseDemoData: true,
        } as DemoAxiosRequestConfig,
      )
      return data
    }
  } catch (error: unknown) {
    LoggerService.error({ message: 'insights getParticipatedMotions error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch account details participated Motions')
    }
  }
}

/**
 * Fetches participated Motions for a given Motion id - Motion reporting page
 */
export const getParticipatedMotionsByMotionId = async ({ id, version }: { id: string; version: string }) => {
  try {
    if (Demo.mockApiIsEnabled()) {
      return []
    } else {
      const { data } = await axios.get<ParticipatedMotion[]>(
        `${getBaseUrl('CORE_API')}/v2/core/insights/accounts/details/participated-journeys/journey/${id}/${version}`,
        {
          demoData: [],
          sandboxUseDemoData: true,
        } as DemoAxiosRequestConfig,
      )
      return data
    }
  } catch (error: unknown) {
    LoggerService.error({ message: 'insights getParticipatedMotionsByMotionId error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch account details participated Motions by Motion id')
    }
  }
}

/**
 * Sets the target for the very specific set of requirements in the payload.
 */
export const setTarget = async (payload: SetTargetPost): Promise<DriverSegmentAccounts> => {
  try {
    const { data } = await axios.post<DriverSegmentAccounts>(
      `${getBaseUrl('CORE_API')}/v2/core/insights/set-target`,
      payload,
      {
        demoData: getDriverSegmentAccounts({ dimension: payload.dimension, target: payload.event }),
        sandboxUseDemoData: true,
        headers: {
          'x-magnify-sandbox': Sandbox.isEnabled(),
        },
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'insights setTarget error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to set target')
    }
  }
}

/**
 * Fetches Revenue Retention Forecast
 */
export const getRevenueRetentionForecast = async (
  { dimension }: { dimension: string } = { dimension: 'overall' },
): Promise<RevenueRetentionForecast | null> => {
  try {
    const { data } = await axios.get<RevenueRetentionForecast>(
      `${getBaseUrl('CORE_API')}/v2/core/insights/revenue-retention-forecast${dimension ? `?dimension=${dimension}` : ''}`,
      {
        demoData: demoGetRevenueRetentionForecastOverall,
        sandboxUseDemoData: true,
        headers: {
          'x-magnify-sandbox': Sandbox.isEnabled(),
        },
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      // Handle edge case here where we don't want to display the error notification - context: MAGPROD-3599
      if (error.response?.status === 404) {
        return null
      }
      LoggerService.error({ message: 'insights getRevenueRetentionForecast error', error: error })
      throw new CoreApiError(error.response.data)
    } else {
      LoggerService.error({ message: 'insights getRevenueRetentionForecast error', error: error })
      throw new Error('Failed to fetch Revenue Retention Forecast')
    }
  }
}

/**
 * Fetches Account Forecast for Table
 */
export const getAccountForecast = async ({
  page,
  pageSize,
  sortKey,
  sortDirection,
  accountLike,
  contractEndPeriod,
  revenuePeriod,
}: {
  page: number
  pageSize: number
  sortKey: string
  sortDirection: string
  accountLike?: string
  contractEndPeriod?: string
  revenuePeriod?: string
}): Promise<AccountForecast | null> => {
  try {
    const { data } = await axios.get<AccountForecast>(
      buildUrl(`${getBaseUrl('CORE_API')}/v2/core/insights/account-forecast`, {
        page,
        pageSize,
        sortKey,
        sortDirection,
        accountLike,
        contractEndPeriod,
        revenuePeriod,
      }),
      {
        demoData: getFilteredAndSortedForecast({ sortKey, sortDirection, revenuePeriod, page, pageSize }),
        sandboxUseDemoData: true,
        headers: {
          'x-magnify-sandbox': Sandbox.isEnabled(),
        },
      } as DemoAxiosRequestConfig,
    )
    return data
  } catch (error: unknown) {
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      // Handle edge case here where we don't want to display the error notification - context: MAGPROD-3599
      if (error.response?.status === 404) {
        return null
      }
      LoggerService.error({ message: 'insights getAccountForecast error', error: error })
      throw new CoreApiError(error.response.data)
    } else {
      LoggerService.error({ message: 'insights getAccountForecast error', error: error })
      throw new Error('Failed to fetch Account Forecast')
    }
  }
}
