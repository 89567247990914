import { Tooltip } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { IconCaretRight, IconCheckMark, IconGripper } from 'components/common/Icons/Icons'
import { criteriaItemKey } from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/SingleSelect/utils'
import SearchItem from 'components/MotionBuilder/SegmentBuilder/SegmentSidebar/DataSource/SearchListView'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction, DragEvent } from 'react'

import type { MetadataDescription } from 'models/metadata.model'
import type { BreadcrumbInfo, Item, SourceData } from 'models/motion/motionBuilder.model'

interface SidebarListViewProps {
  /** Whether to allow selection of items reguardless of the modal being open. */
  allowSelection?: boolean
  breadCrumbItems: BreadcrumbInfo[]
  /** The tooltip text to display when the field is disabled. */
  disabledFieldTooltip?: string
  /** The tooltip text to display when the object is disabled. */
  disabledObjectTooltip?: string
  /** The tooltip text to display when the platform is disabled. */
  disabledPlatformTooltip?: string
  /** Filter fields by a custom filter function. */
  filterFields?: (field: MetadataDescription) => boolean
  /** Filter objects by a custom filter function. */
  filterObjects?: (object: MetadataDescription) => boolean
  /** Filter platforms by a custom filter function. */
  filterPlatforms?: (platform: MetadataDescription) => boolean
  handleDragEnd?: (e: DragEvent<HTMLDivElement>, item?: SourceData, position?: any) => void
  handleDragStart?: (e: DragEvent<HTMLDivElement>, item: SourceData, breadCrumbItem: any[], position: any) => void
  handleSelectField?: (item: MetadataDescription) => void
  handleSelectOption: (item: MetadataDescription) => void
  setSearchInput: Dispatch<SetStateAction<string>>
}

const SidebarListView = observer(
  ({
    allowSelection = false,
    breadCrumbItems,
    disabledFieldTooltip = '',
    disabledObjectTooltip = '',
    disabledPlatformTooltip = '',
    filterFields,
    filterObjects,
    filterPlatforms,
    handleDragEnd,
    handleDragStart,
    handleSelectField,
    handleSelectOption,
    setSearchInput,
  }: SidebarListViewProps) => {
    const { metadataStore, motionStore, motionMetricsStore } = useStore()
    const selectTypes: (string | undefined)[] = ['picklist', 'collection']
    const isSelectable = allowSelection
    const isEmptyViewList = !metadataStore.displaySearchResult && metadataStore.filteredMetadataList.length === 0
    const isEmptySearchList = metadataStore.displaySearchResult && metadataStore.searchOutput.length === 0
    const isEmptyCriteriaLocatorList =
      metadataStore.criteriaLocatorMetadata.isOpen && metadataStore.criteriaLocatorMetadata.hasError
    const displayEmptyListMessage = isEmptyViewList || isEmptySearchList || isEmptyCriteriaLocatorList

    useMetadataDisplayErrorNotification(metadataStore)

    const displayCaret = (item: MetadataDescription): boolean => {
      return item.type === undefined
    }

    const isDraggable = (item: MetadataDescription): boolean => {
      return item.type !== undefined
    }

    const onDragStart = async (e: DragEvent<HTMLDivElement>, item: MetadataDescription) => {
      const { key, type } = item
      const { platform, object, solutionInstanceId, magnifyDisplayName } = metadataStore.currentItem

      const options = {
        ...((platform ?? item.platform) && { platform: (platform ?? item.platform) as string }),
        ...((object ?? item.object) && { object: (object ?? item.object) as string }),
        field: key,
        solutionInstanceId: item.solutionInstanceId ?? solutionInstanceId,
        ...(magnifyDisplayName && { magnifyDisplayName }),
      }

      const itemKey = criteriaItemKey({ item: options as Item })

      const isSelect = selectTypes.includes(type)
      if (isSelect && !metadataStore.criteriaSelect.options.get(itemKey)) {
        // set null an get new metadata only when the story is empty
        metadataStore.setCriteriaSelectOptions(itemKey, [])
        await metadataStore.loadCriteriaInputOptions(options as Item, itemKey)
      }

      const dataSource = {
        ...options,
        ...item,
      }

      handleDragStart?.(e, dataSource, breadCrumbItems, key)
    }

    return (
      <div className='menu' data-testid='menu'>
        {!metadataStore.displaySearchResult &&
          !displayEmptyListMessage &&
          metadataStore.filteredMetadataList
            .slice()
            .sort((a, b) => {
              // sort magnify data source to the top
              if (a.name === 'magnify-insights') return -1
              if (b.name === 'magnify-insights') return 1
              return 0
            })
            .map((item: MetadataDescription, index: number) => {
              /** We need to check for and disable any fields. */
              let isDisabled = false
              let disabledTooltipText = ''
              if (item.entityType === 'object' && filterObjects && !filterObjects(item)) {
                isDisabled = true
                disabledTooltipText = disabledObjectTooltip
              }
              if (item.entityType === 'platform' && filterPlatforms && !filterPlatforms(item)) {
                isDisabled = true
                disabledTooltipText = disabledPlatformTooltip
              }
              if (item.entityType === 'field' && filterFields && !filterFields(item)) {
                isDisabled = true
                disabledTooltipText = disabledFieldTooltip
              }
              const key = `${item.key ?? ''}-${item.name ?? ''}-${item.entityType ?? ''}-${index}`
              return (
                <Tooltip title={disabledTooltipText} placement='top' key={`${key}-tooltip`}>
                  <div
                    key={key}
                    id={key}
                    {...(!isSelectable && {
                      onDragStart: async (e: DragEvent<HTMLDivElement>) => {
                        await onDragStart(e, item)
                      },
                      onDragEnd: (e: DragEvent<HTMLDivElement>) => {
                        handleDragEnd?.(e)
                      },
                    })}
                    draggable={isDraggable(item)}
                    onClick={() => {
                      // If the item is disabled, do not allow selection
                      if (isDisabled) {
                        return
                      }
                      if (!isDraggable(item)) {
                        handleSelectOption({
                          ...item,
                          solutionInstanceId:
                            (item.connections?.length && item.connections?.[0].solutionInstanceId) ||
                            item.solutionInstanceId,
                          ...(breadCrumbItems[1]?.magnifyDisplayName && {
                            magnifyDisplayName: breadCrumbItems[1].magnifyDisplayName,
                          }),
                        })
                      } else if (isDraggable(item) && isSelectable) {
                        handleSelectField?.({
                          ...item,
                          platform: breadCrumbItems[0].name,
                          object: breadCrumbItems[1].name,
                          ...(breadCrumbItems[1]?.magnifyDisplayName && {
                            magnifyDisplayName: breadCrumbItems[1].magnifyDisplayName,
                          }),
                        })
                      }
                    }}
                    className={classNames('menu__item', {
                      'menu__item--disabled': motionStore.isSegmentBuilderEditDisabled || isDisabled,
                    })}
                    data-testid='menu__item'>
                    {getMenuItemIcon(item)}
                    <span className='menu__name'>
                      {/* TODO: Remove the replace of snowflakedb once isn't needed anymore (MAGPROD-3416) - or we entirely migrate to Airbyte */}
                      {item.magnifyDisplayName ??
                        item.name.replace('snowflakedb', 'snowflake').replace('chorusai', 'Chorus AI')}
                    </span>
                    {displayCaret(item) && <IconCaretRight />}
                    {isDraggable(item) && !isSelectable && <IconGripper />}
                    {isDraggable(item) && isSelectable && motionMetricsStore.isFieldInUse(item) && (
                      <IconCheckMark className='selected-data-field' data-testid='selected-data-field' />
                    )}
                  </div>
                </Tooltip>
              )
            })}

        {displayEmptyListMessage && (
          <div className='menu__empty' data-testid='menu__empty'>
            <span>No data fields available</span>
          </div>
        )}

        {/* search output */}
        {metadataStore.displaySearchResult &&
          !displayEmptyListMessage &&
          metadataStore.searchOutput.map((item) => {
            return (
              <SearchItem
                allowSelection={allowSelection}
                item={item}
                {...(!isSelectable && {
                  onDragStart,
                  handleDragEnd,
                })}
                breadCrumbItems={breadCrumbItems}
                key={`${item.order[item.order.length - 1].key}-${item.order[0].name}-${item.order
                  .map((orderItem) => orderItem.name)
                  .splice(0, item.order.length - 1)
                  .join('/')}`}
                setSearchInput={setSearchInput}
                {...(isSelectable && {
                  handleSelectField,
                })}
              />
            )
          })}
      </div>
    )
  },
)
SidebarListView.displayName = 'SidebarListView'

export default SidebarListView
