import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

export interface TabProps {
  isPerformanceMetricsEmpty: boolean
  isOperationalStatisticsEmpty: boolean
  isGoalMetricsEmpty: boolean
  isMachineLearningMetricsEmpty: boolean
  isEmailStatsEmpty: boolean
}

export enum TabListEnum {
  PerformanceMetrics = 'performanceMetrics',
  OperationalStats = 'operationalStats',
  EmailStats = 'emailStats',
  GoalMetrics = 'goalMetrics',
  MachineLearningMetrics = 'machineLearningMetrics',
  AccountList = 'accountList',
}

const tabsText = {
  [TabListEnum.PerformanceMetrics]: 'Performance Metrics',
  [TabListEnum.OperationalStats]: 'Operational Stats',
  [TabListEnum.EmailStats]: 'Email Stats',
  [TabListEnum.GoalMetrics]: 'Goal Metrics',
  [TabListEnum.MachineLearningMetrics]: 'Predictive Insights',
  [TabListEnum.AccountList]: 'Account List',
}

const resetLocationHash = (hash: string) => {
  if (hash) {
    window.history.replaceState('', document.title, window.location.pathname)
  }
}

const Tabs = ({
  isPerformanceMetricsEmpty,
  isOperationalStatisticsEmpty,
  isGoalMetricsEmpty,
  isMachineLearningMetricsEmpty,
  isEmailStatsEmpty,
}: TabProps) => {
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState<TabListEnum>(TabListEnum.PerformanceMetrics)

  // Update the default tab based on the empty states.
  useEffect(() => {
    let defaultTab = TabListEnum.PerformanceMetrics
    if (isPerformanceMetricsEmpty) {
      defaultTab = TabListEnum.OperationalStats
    }
    if (isPerformanceMetricsEmpty && isOperationalStatisticsEmpty) {
      defaultTab = TabListEnum.EmailStats
    }
    if (isPerformanceMetricsEmpty && isOperationalStatisticsEmpty && isEmailStatsEmpty) {
      defaultTab = TabListEnum.GoalMetrics
    }
    if (isPerformanceMetricsEmpty && isOperationalStatisticsEmpty && isEmailStatsEmpty && isGoalMetricsEmpty) {
      defaultTab = TabListEnum.MachineLearningMetrics
    }
    if (
      isPerformanceMetricsEmpty &&
      isOperationalStatisticsEmpty &&
      isEmailStatsEmpty &&
      isGoalMetricsEmpty &&
      isMachineLearningMetricsEmpty
    ) {
      defaultTab = TabListEnum.AccountList
    }
    setSelectedTab(defaultTab)
  }, [isPerformanceMetricsEmpty, isOperationalStatisticsEmpty, isGoalMetricsEmpty, isMachineLearningMetricsEmpty])

  // If user refreshes the page then we want to reset location hash
  resetLocationHash(location.hash)

  // Generate the tabs based on the empty states.
  const generateTabs = useMemo(() => {
    const tabsNew = [TabListEnum.AccountList]
    if (!isMachineLearningMetricsEmpty) {
      tabsNew.unshift(TabListEnum.MachineLearningMetrics)
    }
    if (!isGoalMetricsEmpty) {
      tabsNew.unshift(TabListEnum.GoalMetrics)
    }
    if (!isEmailStatsEmpty) {
      tabsNew.unshift(TabListEnum.EmailStats)
    }
    if (!isOperationalStatisticsEmpty) {
      tabsNew.unshift(TabListEnum.OperationalStats)
    }
    if (!isPerformanceMetricsEmpty) {
      tabsNew.unshift(TabListEnum.PerformanceMetrics)
    }

    // If there is only 1 item, we want to show nothing.
    if (tabsNew.length === 1) {
      return null
    }

    return (
      <section className='tabs-container content__header'>
        <ul>
          {tabsNew.map((tab: TabListEnum) => {
            return (
              <li key={tab} data-testid='tab'>
                <a
                  href={`#${tab}`}
                  onClick={() => setSelectedTab(tab)}
                  className={selectedTab === tab ? 'selected' : ''}>
                  {tabsText[tab]}
                </a>
              </li>
            )
          })}
        </ul>
      </section>
    )
  }, [
    isPerformanceMetricsEmpty,
    isOperationalStatisticsEmpty,
    isGoalMetricsEmpty,
    isMachineLearningMetricsEmpty,
    isEmailStatsEmpty,
    selectedTab,
  ])

  return generateTabs
}
Tabs.displayName = 'Tabs'

export default Tabs
