import { Form, Input, Spin, Alert } from 'antd'
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Card, Heading, IconMagnifyLogoBlue } from 'components/common'

export function ResetPass() {
  const [codeWasSent, setCodeWasSent] = useState<boolean>(false)
  const [newPassordWasSet, setNewPassordWasSet] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const navigate = useNavigate()

  async function magnifyResetPassword(values: { email: string; code: string; newPassword: string }) {
    const { email, code, newPassword } = values

    try {
      setLoading(true)

      if (codeWasSent) {
        await confirmResetPassword({
          username: email,
          confirmationCode: code,
          newPassword,
        })
        setNewPassordWasSet(true)
        setCodeWasSent(false)
        navigate('/signin')
      } else {
        await resetPassword({ username: email })
        setCodeWasSent(true)
      }

      setError(null)
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='login c-reset-pass' data-testid='reset-pass'>
      <IconMagnifyLogoBlue height={100} />
      <Card className='c-reset-pass__container' variant='2'>
        <Spin size='large' spinning={loading} data-testid='loading-spinner'>
          <Form layout='vertical' onFinish={magnifyResetPassword}>
            <Heading level='3' variant='3'>
              Reset Password
            </Heading>

            {codeWasSent && (
              <Alert
                className='c-reset-pass__code-was-sent-message'
                message='We have emailed your password reset code'
                type='success'
                data-testid='code-was-sent-message'
              />
            )}

            <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please input your email' }]}>
              <Input placeholder='Your email address' type='email' autoComplete='username' data-testid='email' />
            </Form.Item>

            {codeWasSent && (
              <Form.Item
                label='Password reset code'
                name='code'
                rules={[{ required: true, message: 'Please input the reset code' }]}>
                <Input placeholder='Enter the code we sent you by email' data-testid='code' />
              </Form.Item>
            )}

            {codeWasSent && (
              <Form.Item
                label='New password'
                name='newPassword'
                rules={[
                  { required: true, message: 'Please input your new password' },
                  { min: 12, message: 'Password must be at least 12 characters long' },
                ]}>
                <Input.Password placeholder='Your new password' data-testid='newPassword' />
              </Form.Item>
            )}
            {error && <Alert className='c-reset-pass__error-message' message={error.message} type='error' />}
            {newPassordWasSet && (
              <Alert message='Password Reset successful' type='success' data-testid='new-password-set-message' />
            )}
            {!newPassordWasSet && (
              <Button
                className='c-reset-pass__reset-password-button'
                text='Reset Password'
                htmlType='submit'
                testId='submit'
              />
            )}

            <p className='c-reset-pass__sign-in'>
              <Link to='/signin'>Sign in</Link>
            </p>
          </Form>
        </Spin>
      </Card>
    </div>
  )
}
