import axios, { isAxiosError } from 'axios'

import type { CoreAPIErrorResponse } from 'api/errors'
import { CoreApiError } from 'api/errors'
import { getBaseUrl } from 'api/utils'
import { LoggerService } from 'services/LogService/LogService'
import { UtilService } from 'services/Utils'

import type {
  FileAttachmentDeleteResponse,
  FileAttachmentListResponse,
  FileAttachmentUploadResponse,
} from 'models/file-attachments.model'

/**
 * Upload a new file attachment.
 * @param {FormData} params
 * @returns {Promise<FileAttachmentUploadResponse>}
 */
export const upload = async (formData: FormData) => {
  try {
    // This is not intercepted by the axios interceptor as we need a different content-type.
    const token = await UtilService.getAuthTokenId()
    const uninterceptedAxiosInstance = axios.create()
    const { data } = await uninterceptedAxiosInstance<FileAttachmentUploadResponse>({
      method: 'post',
      url: `${getBaseUrl('CORE_API')}/v1/core/email-attachments/upload`,
      data: formData,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'upload email-attachments error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to upload file attachment')
    }
  }
}

/**
 * Get all file attachments.
 * @returns {Promise<FileAttachmentListResponse>}
 */
export const getAll = async () => {
  try {
    const { data } = await axios.get<FileAttachmentListResponse>(`${getBaseUrl('CORE_API')}/v1/core/email-attachments`)
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'getAll email-attachments error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to fetch all file attachments')
    }
  }
}

/**
 * Delete a file attachment for the provide ID.
 * @param {string} id The id of the file to delete.
 * @returns {Promise<FileAttachmentDeleteResponse>}
 */
export const deleteFile = async (id: string) => {
  try {
    const { data } = await axios.delete<FileAttachmentDeleteResponse>(
      `${getBaseUrl('CORE_API')}/v1/core/email-attachments/${id}`,
    )
    return data
  } catch (error: unknown) {
    LoggerService.error({ message: 'delete email-attachments error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error(`Failed to delete file attachment with ID of '${id}'`)
    }
  }
}

/**
 * Upload a new file asset that can be linked to.
 * Used by TinyMCE to upload email assets, and should resolve with the URL of the uploaded asset.
 * @param {FormData} params
 * @returns {Promise<any>}
 * @see {@link https://www.tiny.cloud/docs/tinymce/6/upload-images/}
 * @see {@link https://www.tiny.cloud/docs/tinymce/6/migration-from-5x/#images_upload_handler}
 */
export const uploadAsset = async (formData: FormData) => {
  try {
    // This is not intercepted by the axios interceptor as we need a different content-type.
    const token = await UtilService.getAuthTokenId()
    const uninterceptedAxiosInstance = axios.create()
    const { data } = await uninterceptedAxiosInstance<Record<string, string>>({
      method: 'post',
      url: `${getBaseUrl('CORE_API')}/v1/core/email-attachments/upload-asset`,
      data: formData,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    })
    return data.location
  } catch (error: unknown) {
    LoggerService.error({ message: 'uploadAsset email-attachments error', error })
    if (isAxiosError<CoreAPIErrorResponse>(error) && error.response?.data) {
      throw new CoreApiError(error.response.data)
    } else {
      throw new Error('Failed to upload file asset')
    }
  }
}
