const UNAUTHORIZED_ERROR_KEY = 'unauthorizedError'

/**
 * Retrieves the unauthorized error flag from localStorage
 * @returns {string | null} The unauthorized error value if it exists, null otherwise
 */
export function getUnauthorizedError(): boolean {
  return localStorage.getItem(UNAUTHORIZED_ERROR_KEY) === 'true'
}

/**
 * Sets the unauthorized error flag in localStorage to indicate an unauthorized state
 */
export function putUnauthorizedError() {
  localStorage.setItem(UNAUTHORIZED_ERROR_KEY, 'true')
}

/**
 * Clears the unauthorized error flag from localStorage
 */
export function clearUnauthorizedError() {
  localStorage.removeItem(UNAUTHORIZED_ERROR_KEY)
}
