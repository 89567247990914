import { Row } from 'antd'
import { memo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Button, IconChevronRight } from 'components/common'
import { sortSegmentRiskLevel } from 'components/common/Utils/insights'
import InsightContent from 'components/Insights/components/InsightRow/components/InsightContent'
import InsightsSpeedometer from 'components/Insights/components/InsightRow/components/InsightSpedometer'
import { getInsightHeading } from 'components/Insights/components/InsightRow/utils'
import { formatNumber } from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import { getDefaultLevel } from 'components/Insights/components/InsightsDetails/index.utils'
import useStore from 'store/useStore'

import type { MlMetricCategoryEnum } from 'models/account.model'
import type { DriverSegmentEnum, InsightDriverSegmentV2, InsightDriverV2 } from 'models/insights'
import { DataFormatEnum, ImpactEnum } from 'models/insights'

interface InsightRowProps {
  selectedTarget: MlMetricCategoryEnum
  insight: InsightDriverV2
  insightImpactLevel: ImpactEnum
  isRevenueQuantified?: boolean
}

interface GetMotionNameText {
  accountsCount: number
  usersCount: number
  revenue: number
  insightImpactLevel: ImpactEnum
  selectedTarget: MlMetricCategoryEnum
}

const getDashboardMotionNameText = ({
  accountsCount,
  insightImpactLevel,
  usersCount,
  revenue,
  selectedTarget,
}: GetMotionNameText) => {
  const formattedRevenue = formatNumber({ number: revenue, format: DataFormatEnum.CurrencyKMB, decimal: 1 })
  const usersCountText = usersCount > 0 ? `and ${usersCount.toLocaleString()} users` : ''
  const prefix = insightImpactLevel === ImpactEnum.Negative ? 'Save' : 'Grow'

  const finalText = `${prefix} ${formattedRevenue} in ARR in potential ${selectedTarget} from ${accountsCount.toLocaleString()} accounts ${usersCountText}`

  return finalText
}

const InsightRow = memo(({ selectedTarget, insight, insightImpactLevel, isRevenueQuantified }: InsightRowProps) => {
  const navigate = useNavigate()
  const { motionStore } = useStore()

  const sortedInsightSegments = Object.keys(insight.segments)
    .sort(sortSegmentRiskLevel)
    .reduce((accumulator: Record<string, InsightDriverSegmentV2>, key) => {
      accumulator[key] = insight.segments[key]
      return accumulator
    }, {})

  /**
   * Default to the first key in the segments array (high/medium/low) in the case of `negative` segment impact
   * In contrast, we want to default to the last key in the segments array in the case of `positive` segment impact
   *
   * Note: Not every insight will contain one of each ranking
   */
  const [selectedSegmentLevel, setSelectedSegmentLevel] = useState(getDefaultLevel(Object.keys(sortedInsightSegments)))

  const handleSetDisplayedContentIndex = (index: number) => {
    return setSelectedSegmentLevel(Object.keys(sortedInsightSegments)[index] as DriverSegmentEnum)
  }
  const insightSegment = insight.segments[selectedSegmentLevel]

  const handleCreateMotion = () => {
    const improveFrom = insightSegment.propensity.improve_from_text
    const improveTo = insightSegment.propensity.improve_to_text
    const accountCount = insightSegment.account_count

    const isNewMessage = typeof insightSegment?.revenue?.elift_improve_by === 'number'

    const motionTitle = !isNewMessage
      ? `${getInsightHeading(
          selectedTarget,
        )} from ${improveFrom} to ${improveTo} of ${accountCount.toLocaleString()} accounts`
      : getDashboardMotionNameText({
          accountsCount: insightSegment.account_count,
          usersCount: insightSegment.user_count,
          revenue: insightSegment.revenue.elift_improve_by,
          insightImpactLevel,
          selectedTarget,
        })

    motionStore.setMotionTitle(motionTitle)
    motionStore.setMotionDescription(motionTitle)
    navigate('/motions/motion/new')
  }

  return (
    <Row className={'insights-row'} data-testid={`insights-row-${insight.driver_id}`}>
      <div className='insights-row__left'>
        <InsightsSpeedometer
          insightDriverSegment={sortedInsightSegments}
          setDisplayedContentIndex={handleSetDisplayedContentIndex}
        />
        <InsightContent
          insight={insight}
          selectedTarget={selectedTarget}
          insightSegment={insightSegment}
          insightLevel={selectedSegmentLevel}
          insightImpactLevel={insightImpactLevel}
          isRevenueQuantified={isRevenueQuantified}
        />
      </div>
      <div className='insights-row__right'>
        <Button
          className='insights-row__primary-btn'
          testId='insights-row__create-motion-btn'
          text={'Create Motion'}
          onClickHandler={handleCreateMotion}
        />
        <Link
          to={`/insights/${selectedTarget}/${insight.driver_id}`}
          data-testid='insights-row__secondary-btn'
          className='insights-row__secondary-btn'>
          <span>Insight Detail</span>
          <IconChevronRight />
        </Link>
      </div>
    </Row>
  )
})
InsightRow.displayName = 'InsightRow'

export default InsightRow
