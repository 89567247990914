import { makeAutoObservable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import { RoleEnum } from 'services/Utils/Role'
import type { ChildStore } from 'store/StoreTypes'

import type { Tenant } from 'models/tenant'
import type { User, UserPost } from 'models/users.model'

export class UserStore implements ChildStore {
  username: string | null = null
  email: string | null = null
  role: RoleEnum = RoleEnum.None
  name: string | null = null
  mfaEnabled: boolean = false
  groupIds: string[] = []
  isLoading = true
  users: User[] = []
  tenant: Tenant = {
    id: null,
    tenantName: '',
    sandbox: false,
    insightsPublishedAt: 0,
    primarySegmentSource: '',
  }
  isSsoUser: boolean = false

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.username = null
    this.email = null
    this.role = RoleEnum.None
    this.name = null
    this.mfaEnabled = false
    this.groupIds = []
    this.isLoading = true
    this.users = []
    this.tenant = {
      id: null,
      tenantName: '',
      sandbox: false,
      insightsPublishedAt: 0,
      primarySegmentSource: '',
    }
    this.apiError = null
    this.isSsoUser = false
  }

  setUser(user: User, tenant?: Tenant) {
    this.username = user.username
    this.email = user.email
    this.role = user.roleId || RoleEnum.None
    this.name = user.name || null
    this.mfaEnabled = user.mfaEnabled || false
    this.tenant = tenant || {
      id: null,
      tenantName: '',
      sandbox: false,
      insightsPublishedAt: 0,
      primarySegmentSource: '',
    }
    this.isLoading = false
    this.isSsoUser = user.isSsoUser || false
  }

  get = async (id: string): Promise<void> => {
    this.isLoading = true
    try {
      const user = await API.users.get(id)
      runInAction(() => {
        this.setUser(user)
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  getAll = async (): Promise<void> => {
    try {
      const users = await API.users.getAll()
      runInAction(() => {
        this.users = users
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  remove = async (username: string): Promise<void> => {
    try {
      const removeduser = await API.users.remove(username)
      const sourceIndex = this.users.findIndex((user) => user.username === username)
      if (removeduser) {
        this.users.splice(sourceIndex, 1)
      }
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    }
  }

  post = async (user: UserPost): Promise<void> => {
    try {
      const newUser = await API.users.post(user)
      runInAction(() => {
        this.users.push(newUser)
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    }
  }

  update = async (user: Partial<User>): Promise<User> => {
    // TODO return from the backend the new user object
    // this.isLoading = true
    const updatedUser = await API.users.update(user)
    // if (updatedUser) {
    // const indexOfupdate = this.users.indexOf(user)
    // this.users.splice(indexOfupdate, 1, updatedUser)
    // }
    // this.isLoading = !this.isLoading
    return updatedUser
  }

  findBy = (key: string, value: string | number | boolean) => {
    return this.users.find((user) => user[key] === value)
  }

  getIdentity = async (email: string) => {
    const identity = await API.users.getIdentity(email)
    return identity
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }
}
