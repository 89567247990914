import { Row } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/common'
import { getRiskSegment } from 'components/Insights/components/InsightRow/utils'
import {
  calculateSumOf,
  formatNumber,
} from 'components/Insights/components/InsightsDetails/components/InsightImpact/index.utils'
import useStore from 'store/useStore'

import type { InsightV2 } from 'models/insights'
import { DataFormatEnum, ImpactEnum } from 'models/insights'

interface InsightsDetailsFooterProps {
  noSelectedAccounts: number
  noSelectedUsers: number
  totalAccounts: number
  totalUsers: number
  selectedSegments?: string[]
}

interface GetMotionNameText {
  selectedAccountsCount: number
  insight: InsightV2
  selectedUsersCount: number
  revenue: number
}

const getMotionNameText = ({ selectedAccountsCount, insight, selectedUsersCount, revenue }: GetMotionNameText) => {
  const formattedRevenue = formatNumber({ number: revenue, format: DataFormatEnum.CurrencyKMB, decimal: 1 })
  const selectedUsersCountText =
    !isNaN(selectedUsersCount) && selectedUsersCount > 0 ? ` and ${selectedUsersCount.toLocaleString()} users` : ''
  const prefix = insight.event.impact === ImpactEnum.Negative ? 'Save' : 'Grow'

  const finalText = `${prefix} ${formattedRevenue} in ARR in potential ${
    insight.event.short_name
  } from ${selectedAccountsCount.toLocaleString()} accounts${selectedUsersCountText}`

  return finalText
}

const InsightsDetailsFooter = observer(
  ({
    noSelectedAccounts,
    noSelectedUsers,
    totalAccounts,
    totalUsers,
    selectedSegments,
  }: InsightsDetailsFooterProps) => {
    const navigate = useNavigate()
    const { motionStore, insightsStore } = useStore()
    const { isLoadingAccount, currentInsight, isLoading, selectedAccounts } = insightsStore
    const insight = currentInsight as InsightV2
    const selectedAccountsCount = selectedAccounts.length || noSelectedAccounts

    const revenue = calculateSumOf('revenue_elift_improve_by', selectedAccounts)
    const selectedUsersCount = calculateSumOf('user_count', selectedAccounts) || noSelectedUsers

    const handleCreateMotion = () => {
      const riskSegment = getRiskSegment(selectedSegments)
      const motionTitle = getMotionNameText({
        selectedAccountsCount,
        insight,
        selectedUsersCount,
        revenue,
      })
      motionStore.setMotionTitle(motionTitle)
      motionStore.setMotionDescription(`${motionTitle} and ${riskSegment}`)
      navigate('/motions/motion/new')
    }

    return (
      <Row justify='space-between' className='insights-footer' data-testid='insights-footer'>
        <div className='selection-total'>
          <div className='selection-accounts' data-testid='selection-accounts'>
            <span className='selected' data-testid='selected-accounts'>
              {selectedAccountsCount.toLocaleString()}
            </span>
            <span> of </span>
            <span className='total' data-testid='total-accounts'>
              {totalAccounts.toLocaleString()}
            </span>
            <span className='label'> Accounts selected</span>
          </div>

          {!isNaN(selectedUsersCount) && !isNaN(totalUsers) && (
            <div className='selection-users' data-testid='selection-users'>
              <span className='selected' data-testid='selected-users'>
                {selectedUsersCount.toLocaleString()}
              </span>
              <span> of </span>
              <span className='total' data-testid='total-users'>
                {totalUsers.toLocaleString()}
              </span>
              <span className='label'> Users selected</span>
            </div>
          )}
        </div>

        <Button
          text='Create Motion'
          onClickHandler={handleCreateMotion}
          disabled={(isLoadingAccount && isLoading) || selectedAccountsCount === 0}></Button>
      </Row>
    )
  },
)
InsightsDetailsFooter.displayName = 'InsightsDetailsFooter'

export default InsightsDetailsFooter
