import { Input, Modal, Spin } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import EmptyStateImage from 'assets/images/motion-metrics-empty.svg?react'
import { Button, IconArrowLeft, IconPlusCircle, IconSearch } from 'components/common'
import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import MetricRow from 'pages/Motions/ConfigureReport/MetricRow'
import { LogoService } from 'services/Utils/logo'
import { getNormalize } from 'services/Utils/parseString.utils'
import useStore from 'store/useStore'

import type { MetadataDescription } from 'models/metadata.model'
import { MotionStateEnum } from 'models/motion.model'
import type { MotionMetricDefinition, NewMetricDefinition, TenantMetricDefinition } from 'models/motionMetrics'

const ConfigureReport = observer(() => {
  const navigate = useNavigate()
  const [allErrors, setAllErrors] = useState<string[]>([])
  const { id: motionId, version } = useParams()

  const { metadataStore, motionStore, motionMetricsStore } = useStore()

  const redirectToMotion = useCallback(() => navigate(`/motions/motion/${motionId}/${version}`), [navigate, motionId])

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [metricToDelete, setMetricToDelete] = useState<MotionMetricDefinition | null>(null)
  const [isEditing, setIsEditing] = useState(-1)
  const [searchValue, setSearchValue] = useState('')
  const [dataField, setDataField] = useState<MetadataDescription>({
    name: '',
    platform: '',
    object: '',
    field: '',
    type: '',
    magnifyDisplayName: '',
    connections: [],
    data: [],
    entityType: '',
    solutionInstanceId: '',
    aggregationLevels: {
      user: false,
      account: false,
    },
  })
  const [currentEditingMetric, setCurrentEditingMetric] = useState<NewMetricDefinition>({
    columnName: '',
    displayName: '',
    goal: 0,
    motionId: '',
    objectName: '',
    operator: '',
    platformConnectionId: '',
    aggregationLevel: 'account',
  })

  useEffect(() => {
    if (motionId && version && motionId !== 'new' && motionId !== 'undefined') {
      motionStore.get({ playbookId: motionId ?? '', version: Number(version ?? 1) }).catch(console.error)
      metadataStore.get().catch(console.error)
      motionMetricsStore.getMotionMetrics(motionId).catch(console.error)
    }
  }, [])

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(hasUnsavedChanges)

  const isEditingEnabled = useMemo(
    () =>
      motionStore.currentMotion?.currState === MotionStateEnum.Draft ||
      motionStore.currentMotion?.currState === MotionStateEnum.Scheduled ||
      motionStore.currentMotion?.currState === MotionStateEnum.Executing,
    [motionStore.currentMotion?.currState],
  )

  /**
   * If the metric is already in the motion, update it, otherwise create it.
   * @param {number} index The index of the metric to save.
   */
  const saveMetric = async (index: number) => {
    if (motionId) {
      if (motionMetricsStore.motionMetrics[index]?.id) {
        await motionMetricsStore.updateMotionMetric(motionId, motionMetricsStore.motionMetrics[index].operator, {
          displayName: currentEditingMetric.displayName,
          goal: currentEditingMetric.goal,
          motionId,
          metricDefinitionId: motionMetricsStore.motionMetrics[index].id,
          operator: currentEditingMetric.operator,
        })
      } else {
        await motionMetricsStore.createMotionMetric(motionId, currentEditingMetric)
      }
      setIsEditing(-1)
      setHasUnsavedChanges(false)
    }
  }

  const resetMetric = (index: number) => {
    setIsEditing(-1)
    setDataField({
      name: '',
      platform: '',
      object: '',
      field: '',
      type: '',
      connections: [],
      aggregationLevels: {
        user: false,
        account: false,
      },
      data: [],
    })
    setCurrentEditingMetric({
      columnName: '',
      displayName: '',
      goal: 0,
      motionId: '',
      objectName: '',
      operator: '',
      platformConnectionId: '',
      aggregationLevel: 'account',
    })
  }

  const removeMetric = (index: number) => {
    setMetricToDelete(motionMetricsStore.motionMetrics[index])
    setIsDeleteModalOpen(true)
  }

  const isEditingMetric = (index: number) => {
    return isEditing === index
  }

  const addExistingMetric = (metric: TenantMetricDefinition) => {
    try {
      setDataField({
        ...dataField,
        name: metric.columnName,
        platform: metric.platformKey,
        object: metric.objectName,
        field: metric.columnName,
        solutionInstanceId: metric.platformConnectionId,
        magnifyDisplayName: metric.displayName,
        ...metric,
      })
      setCurrentEditingMetric({
        columnName: metric.columnName,
        displayName: metric.displayName ?? '',
        goal: 0,
        motionId: motionId ?? '',
        objectName: metric.objectName,
        operator: '',
        platformConnectionId: metric.platformConnectionId,
        aggregationLevel: metric.aggregationLevel ?? 'account',
        platformKey: metric.platformKey,
      })
      setIsEditing(motionMetricsStore.motionMetrics.length)
      setHasUnsavedChanges(false)
    } catch (error) {
      console.error('Error adding existing metric', error)
    }
  }

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value)
    },
    [setSearchValue],
  )
  const filteredMetrics = useMemo(() => {
    if (!searchValue) {
      return motionMetricsStore.tenantMetricDefinitions
    }
    return motionMetricsStore.tenantMetricDefinitions.filter((metric) => {
      return (
        metric.columnName.toLowerCase().includes(searchValue.toLowerCase()) ||
        metric.objectName.toLowerCase().includes(searchValue.toLowerCase())
      )
    })
  }, [searchValue, motionMetricsStore.tenantMetricDefinitions])

  const handleDeleteMetric = async (metric: MotionMetricDefinition) => {
    if (motionId) {
      await motionMetricsStore.removeMotionMetric(motionId, metric.id, metric.operator)
    }
    setMetricToDelete(null)
    setIsDeleteModalOpen(false)
  }

  /** The add a new metric index, which should always be the last item in the list. */
  const newMetricIndex = motionMetricsStore.motionMetrics.length
  const hasTenantMetricDefinitions = motionMetricsStore.tenantMetricDefinitions.length !== 0

  return (
    <Spin size='large' spinning={motionStore.isLoading || motionMetricsStore.isMotionMetricsLoading}>
      <div className='configure-report' data-testid='configure-report'>
        <div className='container-header'>
          <div className='container-header-title'>
            <Link to={`/motions/motion/${motionId}/${version}`}>
              <span className='container-header-title-icon'>
                <IconArrowLeft />
              </span>
              Motion Builder: {motionStore.currentMotion?.title}
            </Link>
            <div className='container-header-title-seperator'>/</div>
            <h1>Configure Report</h1>
          </div>
          <div className='container-header-actions'>
            <Button text={'Done'} testId='done-button' onClickHandler={redirectToMotion} />
          </div>
        </div>
        <div className='container-body'>
          <div className='existing-metrics' data-testid='existing-metrics'>
            <h2>Saved Data Sources</h2>
            {hasTenantMetricDefinitions && (
              <div className='existing-metrics-search'>
                <Input placeholder='Search...' type='search' prefix={<IconSearch />} onChange={handleSearch} />
              </div>
            )}
            <div className='existing-metrics-list'>
              {filteredMetrics.map((metric) => (
                <div key={metric.id} className='list-item'>
                  <div className='item-icon'>
                    <img
                      src={LogoService.getIcon(getNormalize(metric.platformKey))}
                      alt={metric.platformKey}
                      width={20}
                    />
                  </div>
                  <div className='item-name-date'>
                    {metric.objectName} / {metric.displayName ?? metric.columnName}
                    {metric.lastPivotedAt && <span>Since {`${dayjs(metric.lastPivotedAt).format('MMM YYYY')}`}</span>}
                  </div>
                  <div
                    className='item-action'
                    onClick={() => {
                      addExistingMetric(metric)
                    }}>
                    <IconPlusCircle />
                  </div>
                </div>
              ))}
              {!hasTenantMetricDefinitions && (
                <div className='existing-metrics-empty-state'>
                  <EmptyStateImage />
                  <div>
                    <h3>No Existing Metrics</h3>
                    <p>Previously created metrics will appear here.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='report-metrics' data-testid='report-metrics'>
            <div className='report-metrics-header'>
              <h2>Report Metrics</h2>
              <p>
                Track this Motion's performance by selecting or creating new metrics. New metrics start tracking once
                the Motion runs.
              </p>
            </div>
            <div className='report-metrics-body'>
              <table className='metrics-table' data-testid='metrics-table'>
                <thead>
                  <tr>
                    <th
                      className={classNames({
                        error: allErrors.includes('This Data Source and Aggregation combo already exists.'),
                      })}>
                      Data Source <span className='asterisk'>*</span>
                    </th>
                    <th
                      className={classNames({
                        error:
                          allErrors.includes('This Data Source and Aggregation combo already exists.') ||
                          allErrors.includes('Aggregation is required.'),
                      })}>
                      Aggregation <span className='asterisk'>*</span>
                    </th>
                    <th>Aggregate Over</th>
                    <th className={classNames({ error: allErrors.includes('Chart label is required.') })}>
                      Chart Label <span className='asterisk'>*</span>
                    </th>
                    <th className={classNames({ error: allErrors.includes('Target Goal must be numeric value.') })}>
                      Target Goal
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {motionMetricsStore.motionMetrics.map((metric, index) => (
                    <MetricRow
                      key={`${metric.id}-${metric.operator}`}
                      currentEditingMetric={currentEditingMetric as MotionMetricDefinition}
                      dataField={dataField}
                      index={index}
                      isEditingMetric={isEditingMetric}
                      isEditingEnabled={isEditingEnabled}
                      metric={metric}
                      motionMetrics={motionMetricsStore.motionMetrics}
                      removeMetric={removeMetric}
                      resetMetric={resetMetric}
                      saveMetric={saveMetric}
                      setCurrentEditingMetric={setCurrentEditingMetric}
                      setDataField={setDataField}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      setAllErrors={setAllErrors}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                    />
                  ))}
                  {newMetricIndex < 10 && (
                    <MetricRow
                      key='new-metric'
                      currentEditingMetric={currentEditingMetric as MotionMetricDefinition}
                      dataField={dataField}
                      index={newMetricIndex}
                      isEditing={isEditing}
                      isEditingMetric={isEditingMetric}
                      isEditingEnabled={isEditingEnabled}
                      metric={{} as MotionMetricDefinition}
                      motionMetrics={motionMetricsStore.motionMetrics}
                      removeMetric={removeMetric}
                      resetMetric={resetMetric}
                      saveMetric={saveMetric}
                      setCurrentEditingMetric={setCurrentEditingMetric}
                      setDataField={setDataField}
                      setIsEditing={setIsEditing}
                      setAllErrors={setAllErrors}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                    />
                  )}
                </tbody>
              </table>
              {newMetricIndex >= 10 && (
                <div className='metrics-limit-reached'>
                  <p>Max limit of metrics reached.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title='Delete Metric?'
        open={isDeleteModalOpen}
        data-testid='delete-metric-modal'
        onCancel={() => {
          setIsDeleteModalOpen(false)
        }}
        centered
        footer={[
          <Button
            key='canel'
            text='Cancel'
            htmlType='button'
            testId='cancel-delete-metric'
            type='secondary'
            onClickHandler={() => {
              setIsDeleteModalOpen(false)
              setMetricToDelete(null)
            }}
          />,
          <Button
            key='submit'
            text='Delete'
            htmlType='submit'
            testId='delete-metric-yes'
            type='danger'
            onClickHandler={() => {
              if (metricToDelete) {
                handleDeleteMetric(metricToDelete).catch(console.error)
              } else {
                console.error('No metric to delete')
                setIsDeleteModalOpen(false)
              }
            }}
          />,
        ]}>
        <p>Deleting this metric will remove it from this Motion report. Do you want to continue?</p>
      </Modal>
      <Modal
        title='Discard Unsaved Changes?'
        data-testid='discard-unsaved-changes-modal'
        open={showPrompt}
        centered
        footer={[
          <Button
            key='canel'
            text='Cancel'
            htmlType='button'
            testId='cancel-discard-unsaved-changes'
            type='secondary'
            onClickHandler={cancelNavigation}
          />,
          <Button
            key='submit'
            text='Discard'
            htmlType='submit'
            testId='discard-unsaved-changes'
            type='danger'
            onClickHandler={confirmNavigation}
          />,
        ]}>
        <p>Deleting this metric will remove it from this Motion report. Do you want to continue?</p>
      </Modal>
    </Spin>
  )
})
ConfigureReport.displayName = 'ConfigureReport'

export default ConfigureReport
